import { useAutumnMutation } from '../../../WebApp/features/GraphQL/hooks/useAutumnMutation';
import { graphql } from '../../../WebApp/__generatedtypes__';
import { FlowContextStateType } from '../types/types';
import { useDebounce } from '@uidotdev/usehooks';
import { useEffect } from 'react';

export const useFlowServerProgress = (state: FlowContextStateType | undefined) => {
  const [doProgressMutation] = useAutumnMutation(
    graphql(`
      mutation flowProgress($brand: String!, $tenant: String!, $args: FlowProgressArgs!) {
        flowProgress(agrs: $args, tenant: $tenant, brand: $brand)
      }
    `),
  );

  const updateProgress = async () => {
    if (!state || state.loading || state.completed) return;

    const step = state.flow.steps[state.currentStep].name;

    await doProgressMutation({
      variables: {
        tenant: state.tenant,
        brand: state.brand,
        args: {
          id: state.id,
          flow: state.flow.id,
          completingAs: state.completingAs,
          fields: JSON.stringify(state.formFields),
          step: step ?? '',
          timezone: state.timezoneUser,
          metadata: JSON.stringify(state.metadata),
          customerId: state.customerId,
        },
      },
    });
  };

  const debounceKey = `${state?.currentStep}${JSON.stringify(state?.formFields)}`;

  const debouncedValue = useDebounce(debounceKey, 800);

  useEffect(() => {
    updateProgress().catch(() => null);
  }, [debouncedValue]);
};
