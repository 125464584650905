import React, { ReactNode, useRef, forwardRef } from 'react';
import { enLocalKeys } from '../../../features/Localisations/locales/en';
import { TextInput, TextInputProps } from 'react-native';
import {
  backgroundColor,
  BackgroundColorProps,
  border,
  BorderProps,
  BoxProps,
  color,
  ColorProps,
  createRestyleComponent,
  layout,
  LayoutProps,
  spacing,
  SpacingProps,
  TextProps,
  typography,
  TypographyProps,
} from '@shopify/restyle';
import { Theme } from './theme';
import { AutumnHStack } from './AutumnHStack';
import {
  TranslationProps,
  useTranslation,
} from '../../../features/Localisations/hooks/useTranslation';
import { AutumnNumberInputElement } from './AutumnMoneyInput';
import { AutumnFieldWrapper, AutumnFieldWrapperProps } from './AutumnFieldWrapper';

export type AutumnInputElementProps = SpacingProps<Theme> &
  BackgroundColorProps<Theme> &
  BoxProps<Theme> &
  LayoutProps<Theme> &
  ColorProps<Theme> &
  BorderProps<Theme> &
  TypographyProps<Theme> &
  TextInputProps;

export const AutumnInputElement = createRestyleComponent<AutumnInputElementProps, Theme>(
  [spacing, backgroundColor, border, color, typography, layout],
  TextInput,
);

export type AutumnInputRestyleProps = SpacingProps<Theme> &
  BackgroundColorProps<Theme> &
  TextProps<Theme>;

export type AutumnInputProps = {
  id?: string;
  value: string | number | undefined;
  disabled?: boolean;
  onChange: (value: string | number) => void;
  onBlur?: (e: FocusEvent) => void;
  onFocus?: () => void;
  placeholderLocale?: keyof typeof enLocalKeys | TranslationProps;
  leftElement?: ReactNode;
  rightElement?: ReactNode;
  numberEntry?: boolean;
  minNumber?: number;
  children?: ReactNode;
} & AutumnInputRestyleProps &
  Pick<
    TextInputProps,
    'placeholder' | 'autoFocus' | 'keyboardType' | 'secureTextEntry' | 'onBlur' | 'multiline'
  >;

export const AutumnInput = forwardRef<HTMLInputElement, AutumnInputProps & AutumnFieldWrapperProps>(
  function (
    {
      value,
      onChange,
      disabled = false,
      rightElement: providedRightElement,
      keyboardType,
      secureTextEntry,
      autoFocus,
      placeholder,
      id,
      placeholderLocale,
      leftElement = null,
      numberEntry,
      minNumber = 0,
      children,
      multiline,
      ...rest
    },
    ref,
  ) {
    const inputRef = useRef<HTMLInputElement>();

    const { t } = useTranslation();

    const onChangeText = (e: string) => {
      onChange(e);
    };

    const onChangeNumber = (e: number) => {
      if (e !== null && e < minNumber) {
        e = minNumber;
      }

      onChange(e);
    };

    const setFocus = () => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    };

    const rightElement = () => {
      if (providedRightElement) return providedRightElement;

      return undefined;
    };

    return (
      <AutumnFieldWrapper
        onLabelPressed={setFocus}
        hasFocus={document.activeElement === inputRef.current}
        {...rest}
      >
        <AutumnHStack space={2} alignItems={'center'} paddingHorizontal={1} width={'100%'}>
          {leftElement}

          {numberEntry ? (
            <AutumnNumberInputElement
              id={id}
              ref={ref ?? inputRef}
              value={value as number}
              placeholder={placeholderLocale ? t(placeholderLocale) : placeholder}
              secureTextEntry={secureTextEntry}
              placeholderTextColor={'gray'}
              keyboardType={keyboardType}
              flex={1}
              height={30}
              precision={0}
              delimiter={','}
              autoFocus={autoFocus}
              onChangeValue={onChangeNumber}
              editable={!disabled}
            />
          ) : (
            <AutumnInputElement
              id={id}
              ref={ref ?? inputRef}
              value={value as string}
              placeholder={placeholderLocale ? t(placeholderLocale) : placeholder}
              secureTextEntry={secureTextEntry}
              placeholderTextColor={'gray'}
              keyboardType={keyboardType}
              flex={1}
              height={multiline ? 100 : 30}
              padding={multiline ? 5 : undefined}
              autoFocus={autoFocus}
              onChangeText={onChangeText}
              multiline={multiline}
              editable={!disabled}
              backgroundColor={
                !disabled &&
                (value == undefined || (value as string).length === 0) &&
                !placeholder &&
                !placeholderLocale
                  ? 'gray.10'
                  : undefined
              }
            />
          )}

          {rightElement()}

          {children}
        </AutumnHStack>
      </AutumnFieldWrapper>
    );
  },
);
