import { useSetRecoilState } from 'recoil';
import { tabState } from '../state/tabstate';
import { TTab } from '../types/types';
import dayjs from 'dayjs';
import { routeTopLevel } from '../utils/utils';
import { useLocation, useParams, useSearchParams } from '../../../Common/components/Navigation';

/*
  Used to get the params from the current route. Must be used within a tab
 */
export const useRoutingParams = () => {
  const { pathname } = useLocation();
  const params = useParams();

  const [searchParams, setSearchParams] = useSearchParams();

  return { pathname, params, searchParams, setSearchParams };
};

/*
  This is used to open new tabs. Can be used outside a tab
 */
export const useRouting = () => {
  const setTabs = useSetRecoilState(tabState);

  const navigate = (route: string) => {
    setTabs((tabs) => {
      let openTabs: TTab[] = [...tabs.tabs];

      const isTopLevelOpen = openTabs.findIndex(
        (tab) =>
          routeTopLevel(tab.route).grouper === routeTopLevel(route).grouper &&
          routeTopLevel(tab.route).secondaryGrouper === routeTopLevel(route).secondaryGrouper,
      );

      if (isTopLevelOpen === -1) {
        openTabs = [...openTabs, { route, opened: dayjs() }];
      } else {
        openTabs[isTopLevelOpen] = { ...openTabs[isTopLevelOpen], route };
      }

      return {
        ...tabs,
        tabs: openTabs,
        openTabRoute: route,
      };
    });

    if (window?.history) {
      window.history.pushState({}, '', route);
    }
  };

  return {
    navigate,
  };
};
