import { AutumnText } from './AutumnText';
import React from 'react';
import { AutumnHStack } from './AutumnHStack';
import { AutumnImageSigned } from './AutumnImage';
import { TextProps } from '@shopify/restyle';
import { Theme } from './theme';
import { ImageProps } from 'expo-image';
import { AutumnBox } from './AutumnBox';
import { AutumnVStack } from './AutumnVStack';
import { LocalPhoneFromInternational } from './AutumnPhoneNumberInput';
import { useUserSettings } from '../../../features/UserSettings/hooks/useUserSettings';
import { stringToColour } from '../../utils/color';
import {
  SimpleCustomerFragment,
  SimpleCustomerFragmentType,
} from '../../../features/GraphQL/fragments/customer';
import { useFragment } from '../../../__generatedtypes__';
import { useTimezones } from '../../../features/Time/hooks/useTimezones';

export function AutumnCustomerName({
  fragment: customerFragment,
  iconSize = 20,
  includeName,
  includeEmail,
  includeMobile,
  includeDOB,
  ...rest
}: {
  fragment?: SimpleCustomerFragmentType;
  iconSize?: number;
  includeName?: boolean;
  includeEmail?: boolean;
  includeMobile?: boolean;
  includeDOB?: boolean;
} & TextProps<Theme> &
  ImageProps) {
  const { formatDateToUser } = useTimezones();

  const customer = useFragment(SimpleCustomerFragment, customerFragment);

  const { userLocation } = useUserSettings();

  const hasImage = customer && customer.imageKey && customer.imageKey.length > 5;

  const initials = customer ? customer.given.slice(0, 1) + customer.surname.slice(0, 1) : '?';

  const initialsColour = 'white';
  const bgColour = stringToColour(initials);

  let mobile = '-';

  const email = customer?.email && customer.email.length > 0 ? customer.email : '-';

  const dob = customer?.dob ? formatDateToUser(customer.dob) : '-';

  if (includeMobile && customer?.mobile && customer.mobile.length > 0) {
    //mobiles should be stored in an international format. Try to convert back to local
    mobile = LocalPhoneFromInternational(customer.mobile, userLocation.country);
  }

  return (
    <AutumnHStack space={iconSize > 24 ? 2 : 1} alignItems={'center'}>
      {hasImage ? (
        <AutumnImageSigned
          width={iconSize}
          height={iconSize}
          borderRadius={iconSize}
          {...rest}
          source={customer.imageKey ?? ''}
        />
      ) : (
        <AutumnBox
          width={iconSize}
          height={iconSize}
          borderRadius={iconSize}
          style={{ backgroundColor: bgColour }}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <AutumnText fontSize={iconSize * 0.5} fontWeight={'bold'} color={initialsColour}>
            {initials}
          </AutumnText>
        </AutumnBox>
      )}
      <AutumnVStack space={1}>
        {includeName && (
          <AutumnText fontWeight={'bold'} {...rest}>
            {customer?.name}
          </AutumnText>
        )}
        <AutumnHStack space={'s'} alignItems={'center'}>
          {includeDOB && dob && (
            <AutumnHStack space={1} alignItems={'center'}>
              <AutumnText variant={'small'}>DOB: {dob}</AutumnText>
            </AutumnHStack>
          )}

          {includeMobile && (
            <AutumnHStack space={1} alignItems={'center'}>
              <AutumnText variant={'small'}>Mobile: {mobile}</AutumnText>
            </AutumnHStack>
          )}

          {includeEmail && (
            <AutumnHStack space={1} alignItems={'center'}>
              <AutumnText variant={'small'}>{email}</AutumnText>
            </AutumnHStack>
          )}
        </AutumnHStack>
      </AutumnVStack>
    </AutumnHStack>
  );
}
