import { Platform } from 'react-native';

export const isDev = () => {
  if (Platform.OS !== 'web') {
    return __DEV__;
  }

  if (window.location.hostname === 'localhost' || window.location.hostname.includes('.internal')) {
    return true;
  }

  return false;
};

export const useIsDev = () => {
  return isDev();
};
