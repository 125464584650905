import React, { useState } from 'react';
import { AutumnLocaleText } from './AutumnText';
import { AutumnVStack } from './AutumnVStack';
import { AutumnHStack } from './AutumnHStack';
import { enLocalKeys } from '../../../../WebApp/features/Localisations/locales/en';
import { AutumnPopover } from './AutumnPopover';
import { AutumnBox } from './AutumnBox';
import { AutumnFieldWrapper } from './AutumnFieldWrapper';
import { AutumnButton } from './AutumnButton';
import { AutumnScrollView } from './AutumnScrollView';
import { AutumnPressable } from './AutumnPressable';
import { TranslationProps } from 'WebApp/features/Localisations/hooks/useTranslation';
import { AutumnModal } from './AutumnModal';

export type AutumnColorOption = {
  id: string;
  label?: keyof typeof enLocalKeys | TranslationProps;
  localeKey?: keyof typeof enLocalKeys | TranslationProps;
};

const ColorBox = ({
  children,
  backgroundColor,
  borderColor = 'transparent',
  borderWidth = 0,
  padding = 0,
  borderRadius = 8,
  color = 'black',
}: {
  children?: React.ReactNode;
  backgroundColor: string;
  borderColor?: string;
  borderWidth?: number;
  padding?: number;
  borderRadius?: number;
  color?: string;
}) => {
  return (
    <div
      style={{
        backgroundColor,
        borderColor,
        borderWidth,
        borderStyle: 'solid',
        padding,
        borderRadius,
        width: 40,
        height: 40,
        color: color,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {children}
    </div>
  );
};

const ColorPicker = React.memo(
  ({
    selectedColor,
    onChange,
    options,
  }: {
    selectedColor: string;
    onChange: (value: string) => void;
    options: AutumnColorOption[];
  }) => {
    return (
      <AutumnScrollView horizontal showsHorizontalScrollIndicator={false}>
        {options.map((color) => {
          const isSelected = selectedColor === color.id;
          return (
            <AutumnPressable
              key={color.id}
              onPress={() => onChange(color.id)}
              style={{ margin: 2 }}
            >
              <ColorBox
                backgroundColor={color.id}
                borderRadius={8}
                borderColor={isSelected ? 'blue' : 'transparent'}
                borderWidth={isSelected ? 2 : 0}
              />
            </AutumnPressable>
          );
        })}
      </AutumnScrollView>
    );
  },
);

export function AutumnColorPicker({
  selectedColor,
  previewText = 'Aa',
  previewTextColor = 'black',
  onChange,
  label,
  disabled = false,
  options = [],
}: {
  selectedColor: string;
  previewText?: string;
  previewTextColor?: string;
  disabled?: boolean;
  label?: keyof typeof enLocalKeys;
  onChange: (value: string) => void;
  options: AutumnColorOption[];
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [colorOptions, setColorOptions] = useState<AutumnColorOption[]>(options);
  const [currentlyAddedColor, setCurrentlyAddedColor] = useState<string>('');
  const [colorModal, setColorModal] = useState<boolean>(false);

  const open = () => {
    setIsOpen(true);
  };

  const createNewColor = (color: string) => {
    if (!colorOptions.some((option) => option.id === color)) {
      setColorOptions([...colorOptions, { id: color, label: { key: color } }]);
      setColorModal(false);
      setCurrentlyAddedColor('');
      onChange(color);
    } else {
      alert('Color already exists!');
    }
  };

  return (
    <>
      <AutumnPopover
        onOutsidePress={() => setIsOpen(false)}
        popoverComponent={
          <AutumnBox bg="white" p={'m'} borderRadius={5}>
            <AutumnVStack space={2}>
              <AutumnBox style={{ padding: 10 }}>
                <ColorPicker
                  selectedColor={selectedColor}
                  onChange={(data) => {
                    onChange(data);
                    setIsOpen(false);
                  }}
                  options={colorOptions}
                />
              </AutumnBox>
            </AutumnVStack>
          </AutumnBox>
        }
        show={isOpen}
      >
        <AutumnFieldWrapper onLabelPressed={open} hasFocus={isOpen}>
          <AutumnVStack maxHeight={300} style={{ marginTop: -2 }}>
            {label && <AutumnLocaleText localeKey={label} fontWeight={'bold'} />}
            <AutumnScrollView>
              <AutumnHStack mt={2} space={2} alignItems={'center'} flexWrap={'wrap'} width={'100%'}>
                {selectedColor && (
                  <ColorBox backgroundColor={selectedColor} color={previewTextColor}>
                    {previewText}
                  </ColorBox>
                )}
                <AutumnButton
                  onPress={open}
                  variant={'link'}
                  disabled={disabled}
                  localeKey={'Change'}
                />
                <AutumnButton
                  variant={'link'}
                  localeKey={'Create'}
                  onPress={() => setColorModal(true)}
                  disabled={disabled}
                  isLoading={false}
                />
              </AutumnHStack>
            </AutumnScrollView>
          </AutumnVStack>
          <AutumnModal
            show={colorModal}
            type={'centered'}
            title={'Create'}
            onClose={() => setColorModal(false)}
          >
            <AutumnVStack space={2}>
              <input
                type="color"
                value={currentlyAddedColor}
                onChange={(e) => setCurrentlyAddedColor(e.target.value)}
                aria-label="Select a color" // Added aria-label for accessibility
              />
              <AutumnButton
                onPress={() => createNewColor(currentlyAddedColor as string)}
                localeKey="Save"
                disabled={!currentlyAddedColor}
              />
            </AutumnVStack>
          </AutumnModal>
        </AutumnFieldWrapper>
      </AutumnPopover>
    </>
  );
}
