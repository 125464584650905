import { AutumnBox } from './AutumnBox';
import React from 'react';
import { enLocalKeys } from '../../../features/Localisations/locales/en';
import { AutumnText } from './AutumnText';

export function AutumnEmptyData({ name }: { name: keyof typeof enLocalKeys }) {
  return (
    <AutumnBox alignItems={'center'} padding={'l'}>
      <AutumnText color={'grayText'}>No {name} exist yet</AutumnText>
    </AutumnBox>
  );
}
