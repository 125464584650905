import React from 'react';

import { AutumnForm, AutumnModal, AutumnVStack } from '../../../Common/components/ui';
import { useMessageEdit } from '../hooks/useMessageEdit';
import { yupTypedSchema } from '../../../Common/utils/yup';
import { string } from 'yup';

type EditMessage = {
  message: string;
};

const editYupSchema = yupTypedSchema<EditMessage>({
  message: string().trim().required().min(1),
});

export function LinkThreadToCustomerModal({
  messageId,
  initialMessageContent,
  onClose,
}: {
  messageId: string;
  initialMessageContent: string;
  onClose: () => void;
}) {
  const { editing, editPortalMessage } = useMessageEdit();

  return (
    <AutumnModal
      show={true}
      title={'EditPortalMessage'}
      type={'bottomSheet'}
      onClose={onClose}
      onRequestClose={onClose}
    >
      <AutumnVStack space={'m'}>
        <AutumnForm<EditMessage>
          loading={false}
          elements={[
            {
              type: 'multilineInput',
              key: 'message',
              label: 'NewMessage',
            },
          ]}
          disabled={editing}
          validation={editYupSchema}
          initialValues={{ message: initialMessageContent }}
          buttonTitle={'EditMessage'}
          onSubmit={(values) => editPortalMessage(messageId, values.message, onClose)}
        />
      </AutumnVStack>
    </AutumnModal>
  );
}
