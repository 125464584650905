import { createContext } from 'react';
import { AutumnFormInternal } from './types';

export const InternalFormContext = createContext<AutumnFormInternal>({
  validationMap: {},
  validationStatus: false,
  validationSetUp: false,
  loading: false,
  state: {},
  clickedSubmit: false,
  onChange: () => null,
});
