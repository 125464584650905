import { useRecoilState, useSetRecoilState } from 'recoil';
import { authStateAtom, currentUserStateAtom } from '../state/authState';
import { fetchDiscoveryAsync, RefreshTokenRequestConfig, TokenResponse } from 'expo-auth-session';
import { issuer, useAuthInfo } from './useAuthInfo';
import { Mutex } from 'async-mutex';
import { Auth_State, Current_User_State } from '../types/types';
import { removeUserToken, saveUserToken } from '../utils/tokens';
import { useCallback } from 'react';
import { showErrorToast } from '../../../Common/utils/toast';

const tokenRenewalMutex = new Mutex();

export const useAuthState = () => {
  const [user, setUser] = useRecoilState(currentUserStateAtom);
  const setAuthState = useSetRecoilState(authStateAtom);

  const { clientId } = useAuthInfo();

  const isAuthenticated = !!user?.token?.accessToken;

  const getAccessToken = useCallback(async () => {
    if (!user?.token?.accessToken) {
      return;
    }

    if (tokenRenewalMutex.isLocked()) {
      await tokenRenewalMutex.waitForUnlock();
    }

    const tokenResponse = new TokenResponse(user?.token);

    const needsRefresh = tokenResponse.shouldRefresh();

    if (needsRefresh) {
      try {
        await tokenRenewalMutex.acquire();

        const discovery = await fetchDiscoveryAsync(issuer);

        const refreshConfig: RefreshTokenRequestConfig = {
          clientId,
          refreshToken: tokenResponse.refreshToken,
        };

        const refreshedTokenResponse = await tokenResponse.refreshAsync(refreshConfig, discovery);

        await saveUserToken({ ...user, token: refreshedTokenResponse }, user.pin);

        setUser((existingUser: Current_User_State) => {
          return { ...existingUser, token: refreshedTokenResponse };
        });

        return refreshedTokenResponse.accessToken;
      } catch (e) {
        console.error(e);

        showErrorToast('Your login expired. Please login again');

        // Remove form user list
        setAuthState((existingAuthState: Auth_State) => {
          const users = { ...existingAuthState.users };
          delete users[`${user.user.org_id}:${user.user.sub}`];

          return { ...existingAuthState, users } as Auth_State;
        });

        // Clear the token
        removeUserToken(user?.user.org_id, user?.user.sub);

        // Log out the user
        setUser(null);

        return null;
      } finally {
        tokenRenewalMutex.release();
      }
    }

    return tokenResponse.accessToken;
  }, [user]);

  const setUserSessionExpired = () => {
    setUser((existingUser: Current_User_State) => {
      return { ...existingUser, expired: true };
    });
  };

  return {
    isAuthenticated,
    getAccessToken,
    pin: user?.pin,
    user: user?.user,
    setUserSessionExpired,
  };
};
