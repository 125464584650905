import { ConfigTheme } from '../../../WebApp/__generatedtypes__/graphql';

export class ComponentUtils {
  public static convertToStyleObject(
    values?: ConfigTheme[] | undefined | null,
  ): Record<string, string> {
    return (values ?? []).reduce((accumulator, current) => {
      const value = () => {
        if (current.type === 'Number') return parseFloat(current.value);

        return current.value;
      };

      // @ts-expect-error Expected
      accumulator[current?.key] = value();

      return accumulator;
    }, {});
  }
}
