import { useContext } from 'react';
import { FlowContext } from '../context/FlowContext';

/* This is used within the render button to go next
 * Keep this minimal as this hook is brought into the Page render stack and may be brought in outside the flow context! */
export const useFlowNext = () => {
  const context = useContext(FlowContext);

  const requestNext = (submit: boolean) => {
    if (context) {
      // Handle if this is null
      context.nextStep(submit);
    }
  };

  const requestBack = () => {
    if (context) {
      // Handle if this is null
      context.backStep();
    }
  };

  return {
    requestNext,
    requestBack,
  };
};
