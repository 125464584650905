import React from 'react';
import {
  Config_Portal_Pages_Component,
  Config_Portal_Pages_Component_PhoneProps,
} from '../../../WebApp/__generatedtypes__/graphql';
import { useFlowFormField } from '../../Flows/hooks/useFlowFormField';
import { AutumnFieldWrapper } from '../../../WebApp/Common/components/ui/AutumnFieldWrapper';
import { AutumnPhoneNumberInput } from '../../../WebApp/Common/components/ui/AutumnPhoneNumberInput';

export function ComponentFormFieldPhone({
  component,
}: {
  component: Config_Portal_Pages_Component;
}) {
  const props = component.props as Config_Portal_Pages_Component_PhoneProps;

  const { onChange, value } = useFlowFormField<string>(props.id);

  const onChangeProxied = (value: string) => {
    onChange(value);
  };

  // TODO need to constrain the country input to whats specified

  return (
    <AutumnFieldWrapper
      label={{ key: props.title }}
      {...(props?.explainer && { explainer: { key: props?.explainer } })}
    >
      <AutumnPhoneNumberInput value={value} onChange={onChangeProxied} />
    </AutumnFieldWrapper>
  );
}
