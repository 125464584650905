import { atom } from 'recoil';
import { PatientAppAuthState } from '../types/auth';
import { recoilLocalStorageEffect } from '../../../../WebApp/features/Storage/utils/recoilStorageEffect';

export const patientAppAuthState = atom<PatientAppAuthState>({
  key: 'authState',
  default: {
    signedIn: false,
  },
  effects: [recoilLocalStorageEffect('patientApp')],
});
