import React from 'react';
import { ActivityIndicator } from 'react-native';
import { FlowContext } from '../context/FlowContext';
import { FlowStep } from './FlowStep';
import { FlowProps } from '../types/types';
import { ComponentProvider } from '../../Components/provider/ComponentProvider';
import { useFlow } from '../hooks/useFlow';
import { AutumnTurnstile } from '../../../WebApp/Common/components/ui/AutumnTurnstile';
import { FlowLogin } from './FlowLogin';

export function Flow(props: FlowProps) {
  const {
    isLoading,
    context,
    nextStep,
    backStep,
    setFormValue,
    state,
    registerStepRef,
    deregisterStepRef,
    captchaShow,
    captchaToken,
    setLoggedIn,
  } = useFlow(props);

  if (isLoading) return <ActivityIndicator />;

  return (
    <ComponentProvider context={context}>
      <FlowContext.Provider
        value={{
          state,
          registerStepRef,
          backStep,
          nextStep,
          setFormValue,
          setLoggedIn,
          deregisterStepRef,
        }}
      >
        <FlowStep stepNumber={state.currentStep} />

        <FlowLogin />

        {captchaShow && <AutumnTurnstile callback={(e: string) => (captchaToken.current = e)} />}
      </FlowContext.Provider>
    </ComponentProvider>
  );
}
