import 'react-native-gesture-handler';
import React from 'react';
import App from './WebApp/App';
import PatientApp from './PatientApp/PatientApp';

export default function () {
  if (window.location.hostname === 'patient.internal') return <PatientApp />;

  return <App />;
}
