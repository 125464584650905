import React from 'react';
import { PortalProvider } from '@gorhom/portal';
import { RecoilRoot } from 'recoil';
import { PatientAppConfigProviders } from '../components/AppConfig/providers/PatientAppConfigProviders';
import { PatientAppGraphQLProvider } from '../components/GraphQL/providers/PatientAppGraphQLProvider';
import { PatientAppAuthProvider } from '../components/Auth/providers/PatientAppAuthProvider';
import { PatientAppTheme } from '../components/Themeing/providers/PatientAppTheme';
import { PatientAppPagesProvider } from '../components/Pages/providers/PatientAppPages';
import { ToastProvider } from '../../WebApp/Common/providers/ToastProvider';
import { PatientAppShellErrorBoundary } from '../components/Errors/components/PatientAppErrorBoundary';
import { DevSwitcher } from '../../WebApp/features/DevSwitcher/components/DevSwitcher';

export function PatientAppProviders({ children }: { children: React.ReactNode }) {
  return (
    <RecoilRoot>
      <PatientAppShellErrorBoundary>
        <ToastProvider>
          <PatientAppAuthProvider>
            <PatientAppGraphQLProvider>
              <PatientAppConfigProviders>
                <PatientAppPagesProvider>
                  <PatientAppTheme>
                    {/* Important anything above this doesn`t require restyle! */}
                    <PortalProvider>{children}</PortalProvider>
                  </PatientAppTheme>
                </PatientAppPagesProvider>
              </PatientAppConfigProviders>
            </PatientAppGraphQLProvider>
          </PatientAppAuthProvider>
        </ToastProvider>
      </PatientAppShellErrorBoundary>

      <DevSwitcher />
    </RecoilRoot>
  );
}
