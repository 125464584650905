import { AutumnBox, AutumnText } from '../../../WebApp/Common/components/ui';
import React, { useContext } from 'react';
import { FlowContext } from '../../Flows/context/FlowContext';
import { Config_Portal_Pages_Component } from '../../../WebApp/__generatedtypes__/graphql';
import { ComponentUtils } from '../utils/utils';

export function ComponentProgressBar({ component }: { component: Config_Portal_Pages_Component }) {
  const flowContext = useContext(FlowContext);

  if (!flowContext) return null;

  const styles = ComponentUtils.convertToStyleObject(component.style);

  const percentageDone = flowContext.state.currentStep * 100;

  return (
    <AutumnBox
      width={'100%'}
      alignItems={'center'}
      bg={'gray'}
      borderRadius={5}
      style={styles}
      overflow={'hidden'}
    >
      <AutumnBox bg={'lightGreen'} width={`${percentageDone}%`} height={22} />

      <AutumnBox position={'absolute'}>
        <AutumnText>
          {`${flowContext.state.currentStep} / ${flowContext.state.flow.steps.length}`}
        </AutumnText>
      </AutumnBox>
    </AutumnBox>
  );
}
