import {
  backgroundColor,
  BackgroundColorProps,
  BorderProps,
  BoxProps,
  createRestyleComponent,
  layout,
  LayoutProps,
  spacing,
  SpacingProps,
} from '@shopify/restyle';
import { Theme } from './theme';
import { Pressable, PressableProps } from 'react-native';

type Props = SpacingProps<Theme> &
  BackgroundColorProps<Theme> &
  BoxProps<Theme> &
  BorderProps<Theme> &
  LayoutProps<Theme> &
  PressableProps;

export const AutumnPressable = createRestyleComponent<Props, Theme>(
  [spacing, layout, backgroundColor],
  Pressable,
);
