import { useContext } from 'react';
import { FlowContext } from '../context/FlowContext';

export const useFlowFormField = <T>(id: string) => {
  const context = useContext(FlowContext);

  const value = context.state.formFields?.[id] as T;

  const onChange = (value: string | number | boolean) => {
    context.setFormValue(id, value);
  };

  return {
    value,
    onChange,
  };
};
