import { useAutumnMutation } from '../../../WebApp/features/GraphQL/hooks/useAutumnMutation';
import { graphql } from '../../../WebApp/__generatedtypes__';
import { useFlowContext } from './useFlowContext';
import { usePatientAppAuth } from '../../../PatientApp/components/Auth/hooks/usePatientAppAuth';

export type CompleteLoginForm = {
  code: string;
  captcha: string;
};

export const useFlowLogin = () => {
  const { state, setLoggedIn } = useFlowContext();

  const { setSignedIn } = usePatientAppAuth();

  const [completeLoginMutation, { loading: completingLogin }] = useAutumnMutation(
    graphql(`
      mutation completeFlowLogin($args: PatientAuthSigninCompleteRequest!) {
        patientAuthComplete(args: $args) {
          token
          ok
          id
        }
      }
    `),
  );

  const completeLogin = async (form: CompleteLoginForm) => {
    if (!state.login?.session) return;

    const { data } = await completeLoginMutation({
      variables: {
        args: {
          captcha: form.captcha,
          session: state.login?.session,
          code: form.code,
        },
      },
    });

    if (
      data?.patientAuthComplete.ok &&
      data?.patientAuthComplete?.token &&
      data?.patientAuthComplete?.id
    ) {
      if (state.completingAs === 'customer') {
        setSignedIn(data?.patientAuthComplete?.token);
      }

      setLoggedIn(data?.patientAuthComplete?.id);
    }
  };

  return { completeLogin, completingLogin };
};
