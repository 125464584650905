import type { OperationVariables, TypedDocumentNode } from '@apollo/client/core';
import type { NoInfer, QueryHookOptions } from '@apollo/client/react/types/types';
import { useQuery } from '@apollo/client';
import { useOfflineOnline } from '../../OnlineOffline/hooks/useOfflineOnline';
import { getStorage, setStorage } from '../../Storage/utils/storage';
import dayjs from 'dayjs';
import { ErrorBoundaryContext } from 'react-error-boundary';
import { useContext, useEffect, useState } from 'react';

async function hash(message: string) {
  const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8); // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
  return hashHex;
}

export const useAutumnQuery = <
  TData = never,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: TypedDocumentNode<TData, TVariables>,
  options?: Omit<
    QueryHookOptions<NoInfer<TData>, NoInfer<TVariables>>,
    'notifyOnNetworkStatusChange'
  >,
) => {
  const { online } = useOfflineOnline();

  const needsRefetch = (key: string) => {
    const storedLastFetch = getStorage<number>(key);

    if (storedLastFetch !== null) {
      const lastFetched = dayjs.unix(storedLastFetch);
      const difference = dayjs().diff(lastFetched, 'minute');
      return difference > 60 * 60; // 1 hour revalidate
    }

    return true;
  };

  const fetchPolicy = online ? options?.fetchPolicy ?? 'cache-and-network' : 'cache-only';

  const context = useContext(ErrorBoundaryContext);

  const inErrorBoundary = !!context;

  const [error, setError] = useState<Error | undefined>(undefined);

  useEffect(() => {
    if (error && inErrorBoundary) throw error;
  }, [error]);

  const result = useQuery(query, {
    ...options,
    fetchPolicy,
    notifyOnNetworkStatusChange: true,
    onError: (gqlError) => {
      if (inErrorBoundary) {
        setError(gqlError);
      }
    },
    onCompleted: async (data) => {
      if (online && fetchPolicy === 'cache-first') {
        const key = `ttl_${await hash(JSON.stringify(query))}`;

        const invalidate = needsRefetch(key);

        if (invalidate) {
          await result.refetch();

          setStorage<number>(key, dayjs().unix());
        }
      }

      if (options?.onCompleted) {
        options?.onCompleted(data);
      }
    },
  });

  return result;
};
