import { useIsDev } from './useIsDev';
import { useConfig } from '../../features/Config/hooks/useConfig';
import { Rbac_Permissions, UserPermissionsLocation } from '../../__generatedtypes__/graphql';

import { useRecoilState } from 'recoil';
import { devState } from '../../features/DevSwitcher/state/devState';

/**
 *
 * @param requiredPermissions Either a single Rbac Permission or any array
 * @param requireAny If TRUE, the user needs at least one of the required permissions, if FALSE the user needs ALL the required permissions
 */
export const useHasPermission = (
  requiredPermissions: keyof typeof Rbac_Permissions | (keyof typeof Rbac_Permissions)[],
  requireAny: boolean = false,
) => {
  const isDev = useIsDev();
  const [localDevState] = useRecoilState(devState);

  let { userPermissions } = useConfig();

  //these exist because we take in the keyof an enum
  const allPermissionKeys = Object.keys(Rbac_Permissions);
  const allPermissionValues = Object.values(Rbac_Permissions);

  //TODO this should be determined some other way
  const currentLocationType = UserPermissionsLocation.Trusted;

  if (isDev) {
    const devRemovedPermissions = localDevState.deniedPermissions;

    if (devRemovedPermissions) {
      const removedPermissions = devRemovedPermissions;
      userPermissions = userPermissions.filter((permission) => {
        if (permission.permission == undefined) {
          return false;
        }
        const permissionKey = allPermissionKeys[allPermissionValues.indexOf(permission.permission)];
        return !removedPermissions.includes(permissionKey);
      });
    }
  }

  if (!userPermissions || userPermissions.length === 0) return false;

  if (typeof requiredPermissions === 'string') {
    requiredPermissions = [requiredPermissions];
  }

  for (const neededPermission of requiredPermissions) {
    if (
      requireAny &&
      userPermissions
        .filter((permission) => permission.locationType === currentLocationType)
        .findIndex((permission) => {
          if (permission.permission == undefined) {
            return false;
          }
          const permissionKey =
            allPermissionKeys[allPermissionValues.indexOf(permission.permission)];
          return neededPermission === permissionKey;
        }) > -1
    ) {
      //the user has this permission, and require any is enabled. So return true without checking anymore.
      return true;
    }

    if (
      !requireAny &&
      userPermissions
        .filter((permission) => permission.locationType === currentLocationType)
        .findIndex((permission) => {
          if (permission.permission == undefined) {
            return false;
          }
          const permissionKey =
            allPermissionKeys[allPermissionValues.indexOf(permission.permission)];

          return neededPermission === permissionKey;
        }) === -1
    ) {
      //the current user doesn't have the required permission at the current location type
      return false;
    }
  }
  return !requireAny;
};
