import { useWindowDimensions, Platform } from 'react-native';

export const usePlatformInformation = () => {
  const { width, height } = useWindowDimensions();
  return {
    isWeb: Platform.OS === 'web',
    isIOS: Platform.OS === 'ios',
    screenWidth: width,
    screenHeight: height,
    isSmallScreen: width < 650,
  };
};
