import React, { forwardRef } from 'react';
import { Elements, PaymentElement } from '@stripe/react-stripe-js';
import { ActivityIndicator } from 'react-native';
import { usePaymentGatewayStripeSetup } from '../hooks/usePaymentGatewayStripeSetup';
import { usePaymentGatewayStripeComplete } from '../hooks/usePaymentGatewayStripeComplete';
import { PaymentGatewayRef } from './PaymentGateway';

const StripeInner = forwardRef<PaymentGatewayRef>((_, ref) => {
  usePaymentGatewayStripeComplete(ref);

  return <PaymentElement />;
});

export const Stripe = forwardRef<PaymentGatewayRef>((_, ref) => {
  const { options, stripePromise } = usePaymentGatewayStripeSetup();

  if (!options) return <ActivityIndicator />;

  return (
    <Elements stripe={stripePromise} options={options}>
      <StripeInner ref={ref} />
    </Elements>
  );
});
