import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

export function Icon({ icon, color, size }: { icon: IconDefinition; color: string; size: number }) {
  const appliedStyle = {
    width: size,
    height: size,
  };

  const props = {
    icon,
    color,
    style: appliedStyle,
  };

  return <FontAwesomeIcon {...props} />;
}
