import React, { ReactNode, useEffect } from 'react';
import TagManager from 'react-gtm-module';

export function GoogleTagManagerProvider({
  children,
  gtmID,
}: {
  children: ReactNode;
  gtmID: string;
}) {
  useEffect(() => {
    TagManager.initialize({
      gtmId: gtmID,
    });
  }, []);

  return <>{children}</>;
}
