import { useAutumnMutation } from '../../../WebApp/features/GraphQL/hooks/useAutumnMutation';
import { graphql } from '../../../WebApp/__generatedtypes__';
import { FlowContextStateType } from '../types/types';

export const useFlowSubmit = (state: FlowContextStateType | undefined) => {
  const [doProgressMutation] = useAutumnMutation(
    graphql(`
      mutation flowSubmit($brand: String!, $tenant: String!, $args: FlowProgressArgs!) {
        flowComplete(agrs: $args, tenant: $tenant, brand: $brand) {
          ok
          error
        }
      }
    `),
  );

  const submitFlow = async () => {
    if (!state) return { ok: false };

    const step = state.flow.steps[state.currentStep].name as string;

    const response = await doProgressMutation({
      variables: {
        tenant: state?.tenant,
        brand: state?.brand,
        args: {
          id: state.id,
          flow: state.flow.id,
          completingAs: state.completingAs,
          fields: JSON.stringify(state.formFields),
          step: step ?? '',
          timezone: state.timezoneUser,
          metadata: JSON.stringify(state.metadata),
          customerId: state.customerId,
        },
      },
    });

    if (response.errors) return { ok: false };

    return { ok: true, error: null };
  };

  return { submitFlow };
};
