import React, { ReactNode, useEffect, useState } from 'react';
import { BackgroundColorProps, SpacingProps, TextProps } from '@shopify/restyle';
import { Theme } from './theme';
import { AutumnHStack } from './AutumnHStack';
import { useTranslation } from '../../../features/Localisations/hooks/useTranslation';
import parsePhoneNumber, { CountryCode, isValidPhoneNumber } from 'libphonenumber-js/max';
import PhoneInput, {
  getCountryByCca2,
  getCountryByPhoneNumber,
  ICountry,
} from 'react-native-international-phone-number';
import { ICountryCca2 } from 'react-native-international-phone-number/lib/interfaces/countryCca2';
import { AutumnFieldWrapper, AutumnFieldWrapperProps } from './AutumnFieldWrapper';

export type AutumnInputRestyleProps = SpacingProps<Theme> &
  BackgroundColorProps<Theme> &
  TextProps<Theme>;

export type AutumnPhoneNumberInputProps = {
  id?: string;
  value: string | undefined;
  initialCountry?: string;
  disabled?: boolean;
  onChange: (value: string) => void;
  leftElement?: ReactNode;
  rightElement?: ReactNode;
  children?: ReactNode;
};

export type PhoneConversionResponse = {
  couldConvert: boolean;
  number: string;
  countryCode: string | undefined;
};

export function InternationalPhoneFromLocal(
  phoneNumber: string,
  userCountryCode: string,
): PhoneConversionResponse {
  const invalidResponse = {
    couldConvert: false,
    number: phoneNumber,
    countryCode: undefined,
  };

  if (phoneNumber.length < 5) {
    return invalidResponse;
  }

  const phoneParse = parsePhoneNumber(phoneNumber, userCountryCode as CountryCode);

  if (phoneParse) {
    return {
      couldConvert: true,
      countryCode: phoneParse.country,
      number: phoneParse.number,
    };
  }

  return invalidResponse;
}

export function LocalPhoneFromInternational(phoneNumber: string, userCountryCode: string): string {
  const phoneParse = parsePhoneNumber(phoneNumber, userCountryCode as CountryCode);

  if (phoneParse && phoneParse.country === (userCountryCode as CountryCode)) {
    if (phoneParse.country === 'AU') {
      return '0' + phoneParse.nationalNumber;
    }
    return phoneParse.nationalNumber;
  }

  return phoneNumber;
}

export function AutumnPhoneNumberInput({
  value,
  initialCountry,
  disabled,
  onChange,
  leftElement = null,
  rightElement = null,
  children,
  ...rest
}: AutumnPhoneNumberInputProps & AutumnFieldWrapperProps) {
  const { t } = useTranslation();
  const [selectedCountry, setSelectedCountry] = useState<ICountry | undefined>(undefined);
  const [defaultCountry, setDefaultCountry] = useState<ICountryCca2 | undefined>(undefined);
  const [internalValue, setInternalValue] = useState<string | undefined>(undefined);

  useEffect(() => {
    let country: ICountry | undefined = getCountryByCca2(initialCountry ?? 'AU'); //this is the country used by the actual input object
    const phoneParse = parsePhoneNumber(value ?? '', initialCountry as CountryCode); //this allows the user to enter the number in a local format and us to figure out the international one

    if (phoneParse) {
      country = getCountryByPhoneNumber(phoneParse.number);
      setDefaultCountry(country?.cca2 as ICountryCca2);
      setSelectedCountry(country);
      handleInputValue(phoneParse.nationalNumber, country as ICountry);
      return;
    }

    setDefaultCountry(country?.cca2 as ICountryCca2);
    setSelectedCountry(country);
  }, [value]); // This is needed to update the controller component

  const handleInputValue = (phoneNumber: string, country: ICountry | undefined) => {
    if (phoneNumber) {
      if (country?.callingCode === '+61' && phoneNumber.startsWith('0 4')) {
        phoneNumber = phoneNumber.slice(1);
      }

      const isValid = isValidPhoneNumber(country?.callingCode + phoneNumber);

      setInternalValue(phoneNumber);

      phoneNumber = phoneNumber.replace(/ /g, '');

      if (isValid) {
        onChange(country?.callingCode + phoneNumber);
      }
    }
  };

  const handleSelectedCountry = (country: ICountry) => {
    setSelectedCountry(country);
  };

  return (
    <AutumnFieldWrapper {...rest}>
      <AutumnHStack flex={1} space={2} alignItems={'center'} paddingHorizontal={1} width={'100%'}>
        {leftElement}
        <PhoneInput
          value={internalValue ?? ''}
          placeholder={t('PhoneInputPlaceholder')}
          popularCountries={['AU']}
          defaultCountry={defaultCountry}
          disabled={disabled}
          onChangePhoneNumber={(newValue) => handleInputValue(newValue, selectedCountry)}
          selectedCountry={selectedCountry}
          onChangeSelectedCountry={handleSelectedCountry}
          phoneInputStyles={{
            container: {
              backgroundColor: 'white',
              borderWidth: 0,
              height: 30,
              flex: 1,
            },
            flagContainer: {
              backgroundColor: 'white',
              paddingHorizontal: 5,
              justifyContent: 'center',
            },
            flag: {},
            caret: {
              display: 'none',
              color: 'white',
              fontSize: 16,
            },
            divider: {
              display: 'none',
              backgroundColor: 'white',
            },
            callingCode: {
              fontSize: 14,
              fontWeight: 'bold',
              color: 'rgb(120,120,120)',
            },
            input: {
              color: 'black',
              paddingLeft: 5,
              fontSize: 14,
            },
          }}
        />
        {children}
        {rightElement}
      </AutumnHStack>
    </AutumnFieldWrapper>
  );
}
