import { PersistentStorage } from 'apollo3-cache-persist';
import { deleteStorageKey, getSecureStorage, setSecureStorage } from '../../Storage/utils/storage';

export class SecureGraphCache implements PersistentStorage<string | null> {
  protected encryptionKey: string;

  constructor(_encryptionKey?: undefined | string | null) {
    if (!_encryptionKey) throw new Error('Encryption key empty');

    this.encryptionKey = _encryptionKey;
  }

  async getItem(key: string): Promise<string | null> {
    return getSecureStorage<string>(key, this.encryptionKey);
  }

  removeItem(key: string): Promise<void> {
    deleteStorageKey(key);

    return Promise.resolve();
  }

  async setItem(key: string, value: string | null): Promise<void> {
    if (!value) return;

    return setSecureStorage(key, value, this.encryptionKey);
  }
}
