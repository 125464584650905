import React from 'react';
import { useOnboard } from '../hooks/useOnboard';
import { Onboarding } from '../view/Onboarding';
import { AutumnLoaderFullscreen } from '../../../Common/components/ui/AutumnLoaderFullscreen';

export function OnboardProvider({ children }: { children: React.ReactNode }) {
  const { loading, onboarded } = useOnboard();

  if (loading) {
    return <AutumnLoaderFullscreen />;
  }

  if (!onboarded) return <Onboarding />;

  return <>{children}</>;
}
