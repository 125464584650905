import React, { forwardRef, useEffect, useState } from 'react';
import { enLocalKeys } from '../../../features/Localisations/locales/en';
import {
  ActivityIndicator,
  GestureResponderEvent,
  NativeSyntheticEvent,
  Pressable,
  View,
} from 'react-native';
import {
  BackgroundColorProps,
  BorderProps,
  composeRestyleFunctions,
  createVariant,
  layout,
  LayoutProps,
  spacing,
  SpacingProps,
  useRestyle,
  VariantProps,
} from '@shopify/restyle';
import theme, { Theme } from './theme';
import { AutumnText } from './AutumnText';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { AutumnHStack } from './AutumnHStack';
import { AutumnIcon, AutumnIconsProps } from './AutumnIcon';
import Animated, {
  FadeOut,
  interpolate,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
  ZoomIn,
} from 'react-native-reanimated';
import {
  TranslationProps,
  useTranslation,
} from '../../../features/Localisations/hooks/useTranslation';
import { AutumnBox, AutumnBoxAnimated } from './AutumnBox';
import { useThemeColor } from '../../hooks/useThemeColor';

const variant = createVariant<Theme, 'buttonVariants'>({
  themeKey: 'buttonVariants',
});

type RestyleProps = VariantProps<Theme, 'buttonVariants'> &
  SpacingProps<Theme> &
  BorderProps<Theme> &
  LayoutProps<Theme> &
  BackgroundColorProps<Theme>;

const restyleFunctions = composeRestyleFunctions<Theme, RestyleProps>([spacing, layout, variant]);

type AutumnButtonProps = RestyleProps & {
  onPress: () => void;
  onKeyPress?: (event: NativeSyntheticEvent<KeyboardEvent>) => void;
  isLoading?: boolean;
  leftIcon?: IconDefinition;
  leftIconProps?: Omit<AutumnIconsProps, 'icon'>;
  rightIcon?: IconDefinition;
  disabled?: boolean;
  localeKey?: keyof typeof enLocalKeys | TranslationProps;
  children?: React.ReactNode;
  disableHoverInAnimation?: boolean;
  /**Good idea if pressing this button also hides the button**/
  disableHoverOutAnimation?: boolean;
};

export const AutumnButton = forwardRef<View, AutumnButtonProps>(function (
  {
    localeKey,
    onPress,
    onKeyPress,
    isLoading = false,
    leftIcon,
    leftIconProps,
    disabled = false,
    children,
    rightIcon,
    disableHoverOutAnimation = false,
    disableHoverInAnimation = false,
    ...rest
  },
  ref,
) {
  const { t } = useTranslation();
  const [hovering, setHovering] = useState(false);

  const props = useRestyle(restyleFunctions, rest);

  let textColor: keyof Theme['colors'] = 'white';
  let iconColor: keyof typeof theme.colors = 'white';

  const { colorFromTheme } = useThemeColor();

  if (['link', 'inLineLink'].includes(rest.variant ?? '')) {
    textColor = 'buttonPrimary';
    iconColor = colorFromTheme('green');
  } else if (['outline'].includes(rest.variant ?? '')) {
    textColor = 'black';
    iconColor = colorFromTheme('black');
  } else if (['ghost'].includes(rest.variant ?? '')) {
    textColor = 'black';
  }

  const isBold = rest.variant !== 'outline';

  const disableProgress = useSharedValue(1);

  const outerAnimatedStyle = useAnimatedStyle(() => {
    return {
      opacity: interpolate(disableProgress.value, [0, 1], [0.7, 1]),
    };
  });

  useEffect(() => {
    disableProgress.value = withTiming(disabled ? 0 : 1, {
      duration: 500,
    });
  }, [disabled]);

  return (
    <Animated.View style={outerAnimatedStyle}>
      <Pressable
        ref={ref}
        disabled={disabled || isLoading}
        //@ts-expect-error onKeydownCapture does actually exist
        onKeyDownCapture={onKeyPress ?? undefined}
        onPress={onPress}
        onHoverIn={() => !disabled && setHovering(true)}
        onHoverOut={() => setHovering(false)}
        {...props}
      >
        <AutumnHStack space={2} alignItems={'center'} justifyContent={'center'}>
          {leftIcon && (
            <AutumnIcon color={iconColor} size={16} icon={leftIcon} {...leftIconProps} />
          )}

          <AutumnText fontWeight={isBold ? 'bold' : '600'} color={textColor} textAlign={'center'}>
            {localeKey && t(localeKey)}
            {children}
          </AutumnText>

          {rightIcon && <AutumnIcon size={16} color={iconColor} icon={rightIcon} />}

          {isLoading && <ActivityIndicator color={textColor} />}
        </AutumnHStack>

        {hovering && (
          <AutumnBoxAnimated
            {...{
              width: '100%',
              height: '100%',
              position: 'absolute',
              exiting: disableHoverOutAnimation ? undefined : FadeOut.duration(300),
              entering: disableHoverInAnimation ? undefined : ZoomIn.duration(300),
            }}
          >
            <AutumnBox
              style={{
                flex: 1,
                opacity: 0.2,
                borderRadius: 6,
                backgroundColor: rest.variant === 'danger' ? 'darkred' : 'lightgreen',
              }}
            ></AutumnBox>
          </AutumnBoxAnimated>
        )}
      </Pressable>
    </Animated.View>
  );
});
