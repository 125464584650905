import Fuse, { FuseResult } from 'fuse.js';

export type LocalSearchRoute = {
  name: string;
  route: string;
  keywords: string[];
  description: string;
};

export const localSearchIndex: LocalSearchRoute[] = [
  {
    name: 'Admin - Product',
    description: 'Setup new products',
    route: '/settings/products',
    keywords: ['setup product'],
  },
];

const localSearchFuse = new Fuse(localSearchIndex, {
  keys: ['name', 'keywords'],
  includeScore: true,
});

export const doLocalSearch = (term: string): FuseResult<LocalSearchRoute>[] => {
  return localSearchFuse.search(term);
};
