import {
  backgroundColor,
  BackgroundColorProps,
  border,
  BorderProps,
  createBox,
  createRestyleComponent,
  layout,
  LayoutProps,
  opacity,
  OpacityProps,
  position,
  PositionProps,
  spacing,
  SpacingProps,
} from '@shopify/restyle';
import { Theme } from './theme';
import Animated, { AnimatedProps } from 'react-native-reanimated';
import { ViewProps } from 'react-native';

export const AutumnBox = createBox<Theme>();

export type AutumnBoxAnimatedProps = SpacingProps<Theme> &
  PositionProps<Theme> &
  BackgroundColorProps<Theme> &
  LayoutProps<Theme> &
  BorderProps<Theme> &
  OpacityProps<Theme> &
  AnimatedProps<ViewProps>;

export const AutumnBoxAnimated = createRestyleComponent<AutumnBoxAnimatedProps, Theme>(
  [spacing, layout, position, border, backgroundColor, opacity],
  Animated.View,
);
