import {
  Config_Portal_Pages_Component,
  Config_Portal_Pages_Component_PaymentProps,
} from '../../../WebApp/__generatedtypes__/graphql';
import { PaymentGateway, PaymentGatewayRef } from '../../PaymentGateways/components/PaymentGateway';
import React, { useContext, useEffect, useRef } from 'react';
import { ComponentContext } from '../context/ComponentContext';
import { useFlowFormField } from '../../Flows/hooks/useFlowFormField';
import { AutumnFieldWrapper } from '../../../WebApp/Common/components/ui/AutumnFieldWrapper';
import { FlowContext } from '../../Flows/context/FlowContext';

export function ComponentFormFieldPayment({
  component,
}: {
  component: Config_Portal_Pages_Component;
}) {
  const {
    renderContext: { customerId },
  } = useContext(ComponentContext);

  const props = component.props as Config_Portal_Pages_Component_PaymentProps;

  const { onChange } = useFlowFormField<string>(props.id);

  const context = useContext(FlowContext);

  const ref = useRef<PaymentGatewayRef>(null);

  useEffect(() => {
    context?.registerStepRef('payment', async () => {
      const token = await ref?.current?.saveToken();

      if (!token?.ok || !token.token) return false;

      onChange(token.token);

      return true;
    });

    return () => {
      context?.deregisterStepRef('payment');
    };
  }, []);

  if (!customerId) return null;

  return (
    <AutumnFieldWrapper
      label={{ key: props.title }}
      {...(props?.explainer && { explainer: { key: props?.explainer } })}
    >
      <PaymentGateway ref={ref} customerId={customerId} />
    </AutumnFieldWrapper>
  );
}
