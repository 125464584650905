import { createTheme, ThemeProvider } from '@shopify/restyle';
import { BaseTheme } from '../../../../WebApp/Common/components/ui/theme';
import { AutumnBox } from '../../../../WebApp/Common/components/ui';
import React from 'react';
import { ConfigTheme } from '../../../../WebApp/__generatedtypes__/graphql';

type PatientAppThemeProviderProps = {
  children: React.ReactNode;
  theme: ConfigTheme[];
};

export function PatientAppThemeProvider({ theme, children }: PatientAppThemeProviderProps) {
  const value = (key: string, fallback: string) =>
    theme?.find((a: ConfigTheme) => a.key === key)?.value ?? fallback;

  const isDefined = (key: string) => !!theme?.find((a) => a.key === key);

  return (
    <ThemeProvider
      theme={createTheme({
        ...BaseTheme,
        colors: {
          ...BaseTheme.colors,
          background: value('background', 'white'),

          ...(isDefined('buttonBg') && {
            [value('buttonBg', '')]: value('buttonBg', ''),
          }),
          ...(isDefined('buttonText') && {
            [value('buttonText', '')]: value('buttonText', ''),
          }),
        },
        buttonVariants: {
          ...BaseTheme.buttonVariants,
          defaults: {
            ...BaseTheme.buttonVariants.defaults,
            bg: value('buttonBg', 'black'),
            color: value('buttonText', 'black'),
          },
        },
        textVariants: {
          header: {
            ...BaseTheme.textVariants.header,
            fontSize: 25,
            fontFamily: isDefined('fontTitle') ? 'customFontTitle' : 'Arial',
            color: value('headerColor', 'black'),
          },
          subheader: {
            ...BaseTheme.textVariants.subheader,
            fontFamily: isDefined('fontTitle') ? 'customFontTitle' : 'Arial',
            color: value('headerColor', 'black'),
          },
          bold: {
            ...BaseTheme.textVariants.bold,
            fontFamily: isDefined('fontTitle') ? 'customFont' : 'Arial',
            color: value('textColor', 'black'),
          },
          defaults: {
            ...BaseTheme.textVariants.defaults,
            fontSize: 14,
            fontFamily: isDefined('fontTitle') ? 'customFont' : 'Arial',
            color: value('textColor', 'black'),
          },
        },
      })}
    >
      <AutumnBox flex={1} bg={'background'} height={'100%'} width={'100%'}>
        {children}
      </AutumnBox>
    </ThemeProvider>
  );
}
