import { FlowContextStateType } from '../types/types';
import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { graphql } from '../../../WebApp/__generatedtypes__';
import {
  Config_Portal_Pages_Component_DateInputProps,
  Config_Portal_Pages_Component_PhoneProps,
  Config_Portal_Pages_Component_TextInputProps,
} from '../../../WebApp/__generatedtypes__/graphql';

export const useFlowPrefill = (
  state: FlowContextStateType | undefined,
  setState: React.Dispatch<React.SetStateAction<FlowContextStateType>>,
) => {
  const [queryCustomer] = useLazyQuery(
    graphql(`
      query flowPrefill($id: ID!) {
        customer(id: $id) {
          gender
          given
          surname
          dob
          email
          mobile
          sex
          medicalhistory
          medications
          allergies
          address
          street
          surname
          state
          country
          postcode
        }
      }
    `),
  );

  const utilisedFields = state?.flow?.steps
    ?.map((step) => step.components)
    ?.flat()
    ?.map((component) => {
      if (component.type === 'FormField_Text') {
        const props = component.props as Config_Portal_Pages_Component_TextInputProps;

        return props.id.startsWith('patient.') ? props.id : null;
      }

      if (component.type === 'FormField_Date') {
        const props = component.props as Config_Portal_Pages_Component_DateInputProps;

        return props.id.startsWith('patient.') ? props.id : null;
      }

      if (component.type === 'FormField_Phone') {
        const props = component.props as Config_Portal_Pages_Component_PhoneProps;

        return props.id.startsWith('patient.') ? props.id : null;
      }

      return null;
    })
    .filter((fieldId) => fieldId !== null);

  const prefillCustomer = async () => {
    if (!state?.customerId) return;

    const { data } = await queryCustomer({
      variables: {
        id: state?.customerId,
      },
    });

    if (!data) return;

    setState((existingState) => {
      const formFields = { ...existingState.formFields };

      // Get the defined form fields required for patient object

      Object.keys(data?.customer).forEach((key: string) => {
        if (utilisedFields?.includes(`patient.${key}`)) {
          // @ts-expect-error Expected
          formFields[`patient.${key}`] = data?.customer[key] as string;
        }
      });

      return { ...existingState, formFields, formFieldsPrefilled: true } as FlowContextStateType;
    });
  };

  useEffect(() => {
    if (state?.customerId) {
      prefillCustomer().catch(null);
    }
  }, [state?.customerId]);
};
