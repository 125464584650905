import { InMemoryCache } from '@apollo/client';
import { offsetLimitPagination } from '@apollo/client/utilities';

export const cache = () =>
  new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          listMedications: offsetLimitPagination(),
          listTimesheets: offsetLimitPagination(['dateFrom', 'dateTo', 'users']),

          imagesForCustomer: offsetLimitPagination(['customerId', 'imageIDs']),
          products: offsetLimitPagination(['terms', 'tagIds', 'supplierIds', 'activeOnly']),
        },
      },
    },
  });
