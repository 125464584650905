import React, { useState } from 'react';
import { AutumnImage, AutumnImageSigned } from './AutumnImage';
import { graphql } from '../../../__generatedtypes__';
import { AutumnBox } from './AutumnBox';
import { AutumnButton } from './AutumnButton';
import { Platform } from 'react-native';
import { useAutumnMutation } from '../../../features/GraphQL/hooks/useAutumnMutation';
import { AutumnFieldWrapper, AutumnFieldWrapperProps } from './AutumnFieldWrapper';

export function AutumnImageSelector({
  src,
  disabled,
  isPublic = true,
  uploadFolder,
  onUpload,
  ...rest
}: {
  src?: string;
  disabled: boolean;
  isPublic?: boolean;
  uploadFolder?: string;
  onUpload: (key: string) => void;
} & AutumnFieldWrapperProps) {
  const [uploading, setUploading] = useState(false);
  const [file, setFile] = useState<File | null>();

  const [uploadAssetMutation] = useAutumnMutation(
    graphql(`
      mutation uploadFile(
        $public: Boolean!
        $folder: String
        $filename: String
        $mimeType: String!
      ) {
        uploadAsset(
          public: $public
          uploadFolder: $folder
          fileName: $filename
          mimeType: $mimeType
        ) {
          uploadURL
          id
          uploadURL
        }
      }
    `),
  );

  const onPressUpload = async () => {
    if (!file || uploading) return;

    setUploading(true);

    const { data } = await uploadAssetMutation({
      variables: {
        public: isPublic,
        folder: uploadFolder,
        filename: file.name,
        mimeType: file.type,
      },
    });

    if (!data?.uploadAsset?.uploadURL || !data?.uploadAsset?.id) return;

    const formData = new FormData();
    formData.append('file', file, file?.name);

    await fetch(data?.uploadAsset?.uploadURL, {
      method: isPublic ? 'POST' : 'PUT',
      body: isPublic ? formData : file, //for some reason cloudflare and s3 want the data differently
    });

    onUpload(data?.uploadAsset?.id);

    setFile(null);
    setUploading(false);
  };

  if (Platform.OS !== 'web') {
    return null;
  }

  return (
    <AutumnFieldWrapper {...rest}>
      <AutumnBox width={100} height={100} bg={'gray'}>
        {src && !isPublic && (
          <AutumnImageSigned contentFit={'contain'} width={100} height={100} source={src} />
        )}
        {src && isPublic && (
          <AutumnImage contentFit={'contain'} width={100} height={100} source={src} />
        )}
      </AutumnBox>

      <input
        accept={'image/png,image/jpeg'}
        type={'file'}
        disabled={disabled}
        onChange={(e) => {
          if (e.target?.files) {
            setFile(e.target.files[0]);
          }
        }}
      />

      {file && <AutumnButton onPress={onPressUpload} isLoading={uploading} localeKey={'Upload'} />}
    </AutumnFieldWrapper>
  );
}
