import { useRecoilState } from 'recoil';
import { patientAppAuthState } from '../state/patientAppAuthState';
import { jwtDecode } from 'jwt-decode';

export const usePatientAppAuth = () => {
  const [session, setAuthSession] = useRecoilState(patientAppAuthState);

  const setSignedIn = (token: string) => {
    const decoded = jwtDecode(token);

    setAuthSession({
      signedIn: true,
      token,
      sub: decoded.sub,
      // @ts-expect-error  expected
      tenant: decoded['tenant'],
      // @ts-expect-error  expected
      brand: decoded['brand'],
    });
  };

  const setSignedOut = (expired: boolean) => {
    setAuthSession({
      signedIn: false,
    });

    if (expired) {
      // Show a alert
    }
  };

  return { setSignedIn, setSignedOut, ...session };
};
