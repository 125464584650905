import React from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { Text, View } from 'react-native';

const FallbackRender = ({ error }: FallbackProps) => {
  return (
    <View style={{ alignItems: 'center', justifyContent: 'center', flex: 1 }}>
      <Text
        style={{
          fontWeight: 'bold',
          fontSize: 20,
        }}
      >
        Domain Not Found
      </Text>

      <Text>The domain has not been setup</Text>
    </View>
  );
};

export function PatientAppShellErrorBoundary({ children }: { children: React.ReactNode }) {
  return <ErrorBoundary fallbackRender={FallbackRender}>{children}</ErrorBoundary>;
}
