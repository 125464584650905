import { gql } from '@apollo/client';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { graphql } from '../../../__generatedtypes__';
import { faUser } from '@fortawesome/pro-solid-svg-icons/faUser';
import { unreadMessageCountQuery } from './useInboxMessagesCount';
import { AutumnBox, AutumnImage } from '../../../Common/components/ui';
import { AutumnIcon } from '../../../Common/components/ui/AutumnIcon';
import { useConfig } from '../../Config/hooks/useConfig';
import {
  Message,
  Message_Sender,
  Message_Thread,
  Messaging_Channel,
} from '../../../__generatedtypes__/graphql';
import { faRobot } from '@fortawesome/pro-duotone-svg-icons/faRobot';
import { usePlatformInformation } from '../../../Common/utils/usePlatformInformation';
import { inboxQuery } from './useInbox';
import { useAutumnQuery } from '../../GraphQL/hooks/useAutumnQuery';
import { useAutumnMutation } from '../../GraphQL/hooks/useAutumnMutation';
import { AutumnUserIcon } from '../../../Common/components/ui/AutumnUserIcon';
//TODO put this back in:
//import { pusherSubscribeObject } from '../../../Common/utils/pusher';

export const CUSTOMER_MESSAGES_QUERY = graphql(`
  query messages($id: String!, $brand: String!) {
    messages(threadId: $id, offset: 0, limit: 100) {
      __typename
      id
      channel
      sender
      senderId
      senderName
      contentType
      content
      timestamp
      actions {
        id
        data
      }
    }
    messagesThread(id: $id, brand: $brand) {
      ...InboxThreadFragment
      id
      unreadInternal
      __typename
    }
    messageChannelsForThread(threadId: $id, brand: $brand)
  }
`);

export const useCustomerMessages = (
  ThreadID: string,
  brand: string,
  MarkReadWhenViewed?: boolean,
  isCustomerFacing?: boolean,
) => {
  const messagesEndRef = useRef(null);

  const { users, config } = useConfig();
  const { isWeb } = usePlatformInformation();

  const [filterChannels, setFilterChannels] = useState<Messaging_Channel[]>([]);

  const { data, loading, refetch } = useAutumnQuery(CUSTOMER_MESSAGES_QUERY, {
    variables: {
      id: ThreadID,
      brand,
    },
    pollInterval: 30 * 1000,
    onCompleted: () => {
      scrollToBottom();
      setCalled(true);
    },
  });

  const lookupUser = (userId: string) => {
    return users.find((user) => user.id === userId);
  };

  const scrollToBottom = () => {
    if (!messagesEndRef.current) {
      return;
    }

    if (isWeb) {
      //@ts-expect-error "scrollTop"
      messagesEndRef.current.scrollTop = messagesEndRef.current?.scrollHeight;
    } else {
      //@ts-expect-error "scrollToEnd"
      messagesEndRef.current.scrollToEnd({ animated: true });
    }
  };

  const [called, setCalled] = useState(false);

  const [triggeredRead, setTriggeredRead] = useState(false);

  const isInternalUnread = data?.messagesThread?.unreadInternal ?? false;

  const isCustomerUnread = data?.messagesThread?.unreadInternal ?? false;

  const [readMessagesMutation] = useAutumnMutation(gql`
    mutation readThread($ID: String!, $markUnread: Boolean) {
      messagesThreadRead(threadID: $ID, unread: $markUnread) {
        id
        unreadCustomer
        unreadInternal
      }
    }
  `);

  const [toggleOpenMutation] = useAutumnMutation(gql`
    mutation openThread($ID: String!, $open: Boolean!) {
      messagesThreadMarkOpened(threadID: $ID, open: $open) {
        id
        openInternal
      }
    }
  `);

  const allMessages: Message[] = [...((data?.messages as Message[]) ?? [])].sort((a, b) =>
    dayjs(a?.timestamp).isAfter(dayjs(b?.timestamp)) ? 0 : -1,
  );

  const filteredMessages =
    filterChannels.length === 0
      ? allMessages
      : allMessages.filter((message: Message) =>
          filterChannels.includes(message?.channel ?? Messaging_Channel.Portal),
        );

  const transformFrom = (
    sender: Message_Sender,
    sendId: string,
    isCustomerFacing: boolean,
    senderName?: string,
  ) => {
    if (sender === Message_Sender.Customer) {
      if (isCustomerFacing) {
        return 'You';
      } else if (thread?.customer && thread.customer.name) {
        return thread.customer.name;
      }

      return senderName ?? 'Customer';
    }
    //TODO figure out the correct brand
    const tenantName = config[0].name;
    if (sender === Message_Sender.Platform) return !isCustomerFacing ? 'System' : tenantName;

    const userLookedUp = lookupUser(sendId);

    if (!userLookedUp) return '';

    const metaData =
      `${userLookedUp?.name}` + (isCustomerFacing ? ` - ${userLookedUp?.userType}` : '');

    return metaData;
  };

  const transformSenderImage = (
    sender: Message_Sender,
    sendId: string,
    isCustomerFacing: boolean,
  ) => {
    const size: number = 22;

    if (sender === Message_Sender.Customer) {
      return (
        <AutumnIcon
          backgroundSizeMultiplier={1.5}
          backgroundColor={isCustomerFacing ? 'blue' : 'black'}
          icon={faUser}
          color={'white'}
          size={size}
        />
      );
    }

    if (sender === Message_Sender.Platform) {
      const tenantLogo = config[0].logo;

      if (tenantLogo && tenantLogo.length > 5) {
        return (
          <AutumnBox width={size} height={size}>
            <AutumnImage resizeMode={'contain'} source={tenantLogo} width={size} height={size} />
          </AutumnBox>
        );
      }
      return (
        <AutumnIcon
          backgroundSizeMultiplier={1.5}
          icon={faRobot}
          color={'white'}
          backgroundColor={isCustomerFacing ? 'black' : 'messagingBubble'}
          size={size}
          iconTopOffset={-2}
        />
      );
    }

    return <AutumnUserIcon userId={sendId} iconSize={size * 1.5} />;
  };

  const markAsRead = async (markUnread: boolean = false) => {
    await readMessagesMutation({
      variables: {
        ID: ThreadID,
        markUnread,
      },
      refetchQueries: !isCustomerFacing
        ? [{ query: unreadMessageCountQuery, fetchPolicy: 'network-only' }]
        : [],
      onCompleted: async () => {
        await refetch();
        setTriggeredRead(true);
      },
    });
  };

  const toggleOpen = async (open: boolean) => {
    await toggleOpenMutation({
      variables: {
        ID: ThreadID,
        open,
      },
      refetchQueries: !isCustomerFacing
        ? [
            { query: unreadMessageCountQuery, fetchPolicy: 'network-only' },
            {
              query: inboxQuery,
              fetchPolicy: 'network-only',
              variables: { offset: 0, limit: 20, open: open },
            },
          ]
        : [],
      onCompleted: async () => {
        await refetch();
        setTriggeredRead(true);
      },
    });
  };

  useEffect(() => {
    if (data?.messagesThread?.unreadInternal && MarkReadWhenViewed) {
      markAsRead();
    }
  }, [data?.messagesThread?.id]);

  const onType = () => {
    if ((isInternalUnread || isCustomerUnread) && !triggeredRead) {
      setTriggeredRead(true);

      markAsRead();
    }
  };

  const lastMessageIndex = filteredMessages?.length - 1;

  useEffect(() => {
    scrollToBottom();
  }, [filteredMessages?.length]);

  const showMarkAsRead = !isCustomerFacing && isInternalUnread;

  const thread = data?.messagesThread as Message_Thread;

  const uniqueChannels = [
    ...new Set(allMessages.map((message) => message?.channel ?? Messaging_Channel.Portal)),
  ];

  return {
    channelsUsed: uniqueChannels,
    availableChannels: (data?.messageChannelsForThread ?? []) as Messaging_Channel[],
    filteredChannels: filterChannels,
    updatedFilteredChannels: setFilterChannels,
    customerId: thread?.customer?.id,
    filteredMessages,
    showMarkAsRead,
    refetch,
    markAsRead,
    isOpen: thread?.openInternal ?? true,
    toggleOpen,
    isUnreadInternal: thread?.unreadInternal ?? false,
    transformFrom,
    transformSenderImage,
    scrollToBottom,
    loading: !called && loading,
    lastMessageIndex,
    messagesEndRef,
    onType,
  };
};
