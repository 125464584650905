import { useAutumnMutation } from '../../../WebApp/features/GraphQL/hooks/useAutumnMutation';
import { graphql } from '../../../WebApp/__generatedtypes__';

export const usePaymentGatewayTokenCreate = () => {
  const [tokenSetupMutation] = useAutumnMutation(
    graphql(`
      mutation paymentTokenSetup($args: Payment_Token_Setup!) {
        paymentTokenSetup(args: $args)
      }
    `),
  );

  const [tokenCreateMutation] = useAutumnMutation(
    graphql(`
      mutation paymentTokenCreate($args: Payment_Token_Create!) {
        paymentTokenCreate(args: $args) {
          card {
            id
          }
          ok
        }
      }
    `),
  );

  const setupToken = async (customer: string, gateway: string): Promise<string> => {
    const { data } = await tokenSetupMutation({
      variables: {
        args: {
          customer,
          gateway,
        },
      },
    });

    if (!data?.paymentTokenSetup) throw new Error('Could not generated');

    return data?.paymentTokenSetup;
  };

  const createToken = async (customer: string, gateway: string, data: string) => {
    const { data: responseData } = await tokenCreateMutation({
      variables: {
        args: {
          customer,
          data,
          gateway,
        },
      },
    });

    return responseData?.paymentTokenCreate;
  };

  return { setupToken, createToken };
};
