import React from 'react';
import {
  Config_Portal_Pages_Component,
  Config_Portal_Pages_Component_TextInputProps,
} from '../../../WebApp/__generatedtypes__/graphql';
import { useFlowFormField } from '../../Flows/hooks/useFlowFormField';
import { AutumnInput } from '../../../WebApp/Common/components/ui';
import { AutumnFieldWrapper } from '../../../WebApp/Common/components/ui/AutumnFieldWrapper';

export function ComponentFormFieldText({
  component,
}: {
  component: Config_Portal_Pages_Component;
}) {
  const props = component.props as Config_Portal_Pages_Component_TextInputProps;

  const { onChange, value } = useFlowFormField<string>(props.id);

  const onChangeProxied = (value: string) => {
    onChange(value);
  };

  return (
    <AutumnFieldWrapper
      label={{ key: props.title }}
      {...(props?.explainer && { explainer: { key: props?.explainer } })}
    >
      <AutumnInput value={value} onChange={onChangeProxied} multiline={props?.large ?? false} />
    </AutumnFieldWrapper>
  );
}
