import { deleteStorageKey, getStorage, setStorage } from './storage';

export const recoilLocalStorageEffect =
  (key: string) =>
  ({ setSelf, onSet }: { setSelf: any; onSet: any }) => {
    const savedValue = getStorage(key);
    if (savedValue != null) {
      setSelf(savedValue);
    }

    onSet((newValue: object, _: any, isReset: boolean) => {
      isReset ? deleteStorageKey(key) : setStorage(key, newValue);
    });
  };
