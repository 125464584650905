import { AutumnBox, AutumnVStack } from '../../../../WebApp/Common/components/ui';
import React, { ReactNode, useContext, useMemo } from 'react';
import { useComponentRender } from '../../../../Common/Components/hooks/useComponentRender';
import { Config_Portal_Pages_Component } from '../../../../WebApp/__generatedtypes__/graphql';
import { ComponentUtils } from '../../../../Common/Components/utils/utils';
import { ComponentContext } from '../../../../Common/Components/context/ComponentContext';

export const PatientAppRootLayout = ({
  components,
  children,
}: {
  components: Config_Portal_Pages_Component[];
  children?: ReactNode;
}) => {
  const { renderComponent } = useComponentRender();

  const { visibilityContext } = useContext(ComponentContext);

  const renderedComponents = useMemo(() => {
    return components.map((component) => {
      if (component.type === 'Content')
        return (
          <AutumnBox style={ComponentUtils.convertToStyleObject(component.style)} key={'content'}>
            {children}
          </AutumnBox>
        );
      return renderComponent(component, visibilityContext);
    });
  }, [components, visibilityContext]);

  return <AutumnVStack flex={1}>{renderedComponents}</AutumnVStack>;
};
