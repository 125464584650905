import { useTheme } from '@shopify/restyle';
import theme from '../components/ui/theme';

export const useThemeColor = () => {
  const { colors } = useTheme();

  const colorFromTheme = (name: keyof typeof theme.colors) => {
    return colors[name];
  };

  return { colorFromTheme };
};
