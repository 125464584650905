import React, { Suspense } from 'react';
import { RecoilRoot } from 'recoil';
import { useAuthState } from './features/Login/hooks/useAuthState';
import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev';
import { ToastProvider } from './Common/providers/ToastProvider';
import { ConfigProvider } from './features/Config/providers/ConfigProvider';
import { OnboardProvider } from './features/Onboarding/providers/OnboardProvider';
import { ThemeProvider } from '@shopify/restyle';
import theme from './Common/components/ui/theme';
import { useFonts } from 'expo-font';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { PortalProvider } from '@gorhom/portal';
import { StatusBar } from 'expo-status-bar';
import { maybeCompleteAuthSession } from 'expo-web-browser';
import { GraphQLProvider } from './features/GraphQL/provider/GraphQLProvider';
import { View } from 'react-native';
import { AutumnLoaderFullscreen } from './Common/components/ui/AutumnLoaderFullscreen';
import CallingProvider from './features/Calling/providers/CallingProvider';
import { AutumnLightweightLoader } from './Common/components/ui/AutumnLightweightLoader';
import { PathRouter } from './Common/components/Navigation';
import { Route, Routes } from 'react-router';
import { DevSwitcher } from './features/DevSwitcher/components/DevSwitcher';
import { OfflineOnlineProvider } from './features/OnlineOffline/components/OfflineOnlineProvider';
import RealtimeProvider from './features/Realtime/provider/RealtimeProvider';
import { GestureHandlerRootView } from 'react-native-gesture-handler';

maybeCompleteAuthSession();

const Login = React.lazy(() => import('./features/Login/components/Login'));
const InnerApp = React.lazy(() => import('./WebApp'));
const SignUp = React.lazy(() => import('./features/Signup/views/Signup'));
const LoginRedirect = React.lazy(() => import('./features/Login/components/LoginRedirect'));

if (__DEV__) {
  loadDevMessages();
  loadErrorMessages();
}

const AppWrapper = () => {
  const { isAuthenticated } = useAuthState();

  useFonts({
    CormorantInfant: require('../fonts/CormorantInfant-Bold.ttf'),
    Mulish: require('../fonts/Mulish-VariableFont_wght.ttf'),
  });

  if (!isAuthenticated) {
    return (
      <Suspense fallback={<AutumnLoaderFullscreen />}>
        <OfflineOnlineProvider>
          <PortalProvider>
            <PathRouter>
              <Routes>
                <Route element={<Login />} path={'/signin'} />
                <Route element={<SignUp />} path={'/signup'} />
                <Route element={<LoginRedirect />} path="*" />
              </Routes>
            </PathRouter>
          </PortalProvider>

          <DevSwitcher />
        </OfflineOnlineProvider>
      </Suspense>
    );
  }

  return (
    <Suspense fallback={<AutumnLightweightLoader />}>
      {/*This prevents portal content (modals) from creating scroll bars*/}
      <View style={{ overflow: 'hidden', width: '100%', height: '100%' }}>
        <OfflineOnlineProvider>
          <GraphQLProvider>
            <ConfigProvider>
              <ToastProvider>
                <PortalProvider>
                  <OnboardProvider>
                    <RealtimeProvider>
                      <CallingProvider>
                        <StatusBar style={'light'} />
                        <InnerApp />
                      </CallingProvider>
                    </RealtimeProvider>
                  </OnboardProvider>
                </PortalProvider>
              </ToastProvider>
            </ConfigProvider>
          </GraphQLProvider>
        </OfflineOnlineProvider>
      </View>
    </Suspense>
  );
};

const RecoilWrapper = () => {
  return (
    <SafeAreaProvider>
      <GestureHandlerRootView style={{ flex: 1 }}>
        <RecoilRoot>
          <ThemeProvider theme={theme}>
            <AppWrapper />
          </ThemeProvider>
        </RecoilRoot>
      </GestureHandlerRootView>
    </SafeAreaProvider>
  );
};

export default RecoilWrapper;
