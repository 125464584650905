import React, { useRef } from 'react';
import { enLocalKeys } from '../../../features/Localisations/locales/en';
import { AutumnText } from './AutumnText';
import { AutumnVStack } from './AutumnVStack';
import { AutumnHStack } from './AutumnHStack';

import {
  TranslationProps,
  useTranslation,
} from '../../../features/Localisations/hooks/useTranslation';
import { TextInputProps } from 'react-native';
import { faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';
import { useSearch } from '../../../features/Header/hooks/useSearch';
import { AutumnInputElement, AutumnInputRestyleProps } from './AutumnInput';
import { AutumnIcon } from './AutumnIcon';
import { AutumnAlert } from './AutumnAlert';
import { AutumnPressable } from './AutumnPressable';
import { AutumnCustomerName } from './AutumnCustomerName';
import { AutumnButton } from './AutumnButton';
import { SearchResult } from '../../../features/Header/types/types';
import { useFragment } from '../../../__generatedtypes__';
import {
  SimpleCustomerFragment,
  SimpleCustomerFragmentType,
} from '../../../features/GraphQL/fragments/customer';
import { AutumnFieldWrapper, AutumnFieldWrapperProps } from './AutumnFieldWrapper';

export type AutumnCustomerSearchInputProps = {
  id?: string;
  initialValue: string | undefined;
  onSelectedCustomer: (customer: SimpleCustomerFragmentType) => void;
  disabled?: boolean;
  onBlur?: (e: FocusEvent) => void;
  placeholderLocale?: keyof typeof enLocalKeys | TranslationProps;
} & AutumnInputRestyleProps &
  Pick<TextInputProps, 'placeholder' | 'autoFocus' | 'onBlur'>;

export function AutumnCustomerSearch({
  initialValue,
  onSelectedCustomer,
  onBlur,
  disabled = false,
  autoFocus,
  placeholder,
  id,
  placeholderLocale,
  ...rest
}: AutumnCustomerSearchInputProps & AutumnFieldWrapperProps) {
  const { term, setTerm, results } = useSearch(initialValue, true);

  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>();

  const setFocus = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <AutumnFieldWrapper
      hasFocus={document.activeElement === inputRef.current}
      onLabelPressed={() => setFocus()}
      {...rest}
    >
      <AutumnHStack flex={1} space={2} alignItems={'center'} paddingHorizontal={1} width={'100%'}>
        <AutumnIcon icon={faMagnifyingGlass} size={15} />
        <AutumnInputElement
          id={id}
          ref={inputRef}
          value={term}
          placeholder={placeholderLocale ? t(placeholderLocale) : placeholder}
          placeholderTextColor={'gray'}
          flex={1}
          height={30}
          autoFocus={autoFocus}
          onChangeText={setTerm}
          onBlur={onBlur}
          editable={!disabled}
        />
        {term.length > 1 && (
          <AutumnButton
            onPress={() => {
              setTerm('');
              setFocus();
            }}
            variant={'link'}
            localeKey={'Clear'}
          />
        )}
      </AutumnHStack>

      {results.length === 0 && term.length > 2 && (
        <AutumnAlert message={'NoResultsFound'} type={'info'} />
      )}

      {results.map((result) => (
        <AutumnCustomerSearchResult
          result={result}
          onSelectedCustomer={onSelectedCustomer}
          key={result.id}
        />
      ))}
    </AutumnFieldWrapper>
  );
}

function AutumnCustomerSearchResult({
  result,
  onSelectedCustomer,
}: {
  result: SearchResult;
  onSelectedCustomer: (customer: SimpleCustomerFragmentType) => void;
}) {
  const customer = useFragment(SimpleCustomerFragment, result.customer);

  return (
    <AutumnPressable
      key={result?.id}
      onPress={() => {
        if (onSelectedCustomer && result.customer) {
          onSelectedCustomer(result.customer);
        }
      }}
    >
      <AutumnHStack space={'s'} alignItems={'center'}>
        <AutumnCustomerName iconSize={30} fragment={result.customer} />

        <AutumnVStack flex={1}>
          <AutumnText fontWeight={'bold'}>{customer?.name}</AutumnText>
          <AutumnText>{customer?.mobile}</AutumnText>
        </AutumnVStack>
      </AutumnHStack>
    </AutumnPressable>
  );
}
