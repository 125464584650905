import { AutumnVStack } from '../../../WebApp/Common/components/ui';
import React, { useContext, useMemo } from 'react';
import { useComponentRender } from '../../Components/hooks/useComponentRender';
import { useFlowContext } from '../hooks/useFlowContext';
import { ComponentContext } from '../../Components/context/ComponentContext';

export function FlowStep({ stepNumber }: { stepNumber: number }) {
  const { renderComponent } = useComponentRender();
  const { state } = useFlowContext();

  const { visibilityContext } = useContext(ComponentContext);

  const currentStep = state.flow.steps[stepNumber];

  const renderedComponents = useMemo(() => {
    return currentStep.components.map((component) => renderComponent(component, visibilityContext));
  }, [currentStep.components, visibilityContext]);

  return <AutumnVStack space={'s'}>{renderedComponents}</AutumnVStack>;
}
