import { graphql } from '../../../../__generatedtypes__';
import { TaxRate } from '../../../../__generatedtypes__/graphql';
import { useAutumnQuery } from '../../../GraphQL/hooks/useAutumnQuery';

export const TAXRATES_QUERY = graphql(`
  query TaxRates {
    taxRates {
      id
      active
      name
      taxPercentage
      currencyCode
    }
  }
`);

export const useTaxRates = () => {
  const { data: taxRateData, loading: taxRatesLoading } = useAutumnQuery(TAXRATES_QUERY);

  return {
    taxRateData: taxRateData?.taxRates ?? ([] as TaxRate[]),
    taxRatesLoading,
  };
};
