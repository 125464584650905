import { useAutumnMutation } from '../../../../WebApp/features/GraphQL/hooks/useAutumnMutation';
import { graphql } from '../../../../WebApp/__generatedtypes__';
import { usePatientAppConfig } from '../../AppConfig/hooks/usePatientAppConfig';
import { usePatientAppAuth } from '../../Auth/hooks/usePatientAppAuth';
import { useEffect, useState } from 'react';
import { PatientAppCompleteLoginForm, PatientAppLoginForm } from '../types/Form';
import { PatientAuthType } from '../../../../WebApp/__generatedtypes__/graphql';
import { useLocation, useNavigate } from 'react-router';

export const usePatientAppLogin = () => {
  const [session, setSession] = useState<string | null>(null);

  const { setSignedIn, signedIn } = usePatientAppAuth();

  const { brand, tenant } = usePatientAppConfig();

  const navigate = useNavigate();

  const { search } = useLocation();

  const getRedirectPath = (): string => {
    if (search) {
      const params = new URLSearchParams(search);

      if (params.get('redirect')) return params.get('redirect') ?? '/profile';
    }

    return '/profile';
  };

  useEffect(() => {
    if (signedIn) {
      navigate(getRedirectPath());
    }
  }, []);

  const [loginMutation, { loading }] = useAutumnMutation(
    graphql(`
      mutation login($args: PatientAuthSigninRequest!) {
        patientAuthSignin(args: $args) {
          session
          result
        }
      }
    `),
  );

  const [completeLoginMutation, { loading: completeLoading }] = useAutumnMutation(
    graphql(`
      mutation completeLogin($args: PatientAuthSigninCompleteRequest!) {
        patientAuthComplete(args: $args) {
          token
          ok
        }
      }
    `),
  );

  const onLogin = async (form: PatientAppLoginForm) => {
    const { data } = await loginMutation({
      variables: {
        args: {
          mobile: form.mobile,
          dob: form.dob,
          brand,
          type: PatientAuthType.Mobile,
          captcha: form.captcha,
          tenant,
        },
      },
    });

    if (data?.patientAuthSignin?.result && data?.patientAuthSignin?.session) {
      setSession(data?.patientAuthSignin?.session);
      return;
    }
  };

  const onCompleteLogin = async (form: PatientAppCompleteLoginForm) => {
    if (!session) return;

    const { data } = await completeLoginMutation({
      variables: {
        args: {
          captcha: form.captcha,
          session,
          code: form.code,
        },
      },
    });

    if (data?.patientAuthComplete.ok && data?.patientAuthComplete?.token) {
      setSignedIn(data?.patientAuthComplete?.token);

      navigate(getRedirectPath());

      return;
    }

    // Show error here
  };

  return {
    onLogin,
    onCompleteLogin,
    session,
    loading: loading || completeLoading,
  };
};
