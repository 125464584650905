import { AutumnBoxAnimated } from './AutumnBox';
import { AutumnIcon } from './AutumnIcon';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons/faCircleCheck';
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons/faCircleXmark';
import React from 'react';
import { FadingTransition } from 'react-native-reanimated';

export type AutumnFormValidationIconState = 'valid' | 'invalid' | 'none';

export function AutumnFormValidationIcon({ state }: { state: AutumnFormValidationIconState }) {
  const icon = () => {
    if (state === 'valid') return faCircleCheck;
    return faCircleXmark;
  };

  const color = () => {
    if (state === 'valid') return 'green';
    if (state === 'invalid') return 'red';

    return undefined;
  };

  return (
    <AutumnBoxAnimated layout={FadingTransition}>
      {state !== 'none' && <AutumnIcon icon={icon()} color={color()} size={15} />}
    </AutumnBoxAnimated>
  );
}
