import { enLocalKeys } from '../../../features/Localisations/locales/en';
import React, { ReactNode, useRef, useState } from 'react';
import { AutumnHStack } from './AutumnHStack';
import { AutumnLocaleText, AutumnText } from './AutumnText';
import { AutumnScrollView } from './AutumnScrollView';
import { AutumnLoader } from './AutumnLoader';
import {
  TranslationProps,
  useTranslation,
} from '../../../features/Localisations/hooks/useTranslation';
import { AutumnVStack } from './AutumnVStack';
import { AutumnCheckbox } from './AutumnCheckbox';
import { AutumnPressable } from './AutumnPressable';
import { Icon } from '../Icon';
import { faSearch } from '@fortawesome/pro-solid-svg-icons/faSearch';
import { AutumnInputElement } from './AutumnInput';
import { AutumnButton } from './AutumnButton';
import { usePlatformInformation } from '../../utils/usePlatformInformation';
import { AutumnHorizontalSelector } from './AutumnHorizontalSelector';
import { AutumnIcon } from './AutumnIcon';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';

export type GroupedListRow<T> = {
  object: T;
  title?: TranslationProps | keyof T;
  subTitle?: TranslationProps | keyof T;
  labelRight?: TranslationProps | keyof T;
  component?: React.ReactNode;
};

export type ActionsData = {
  localeKey: keyof typeof enLocalKeys | TranslationProps;
  onPress: (id: string) => void;
  disabled: boolean;
};

export type AutumnGroupedSelectionListProps<T> = {
  rows?: GroupedListRow<T>[];
  sortBy?: keyof T;
  groupBy: keyof T;
  searchBy?: keyof T;
  userFilterBy?: keyof T;
  defaultUserFilter?: keyof T;
  rowKey: keyof T;
  isSelectList?: boolean;
  selectedValues?: string[];
  onRowPress?: (keyValue: string, row: T) => void;
  onSelectionChange?: (
    allSelected: string[],
    newSelections: string[],
    newUnselections: string[],
  ) => void;
  loading?: boolean;
  maxHeight?: number;
  nonGroupedItemsLabel?: TranslationProps;
  groupPressAction?: 'collapse' | 'selectall' | { (group: string): void };
};

export function AutumnGroupedSelectionList<T>({
  rows,
  onRowPress,
  onSelectionChange,
  isSelectList,
  selectedValues,
  groupBy,
  sortBy,
  searchBy,
  defaultUserFilter,
  userFilterBy,
  rowKey,
  groupPressAction,
  loading = false,
  maxHeight,
  nonGroupedItemsLabel,
}: AutumnGroupedSelectionListProps<T>) {
  const { t } = useTranslation();
  const searchInputRef = useRef<HTMLInputElement>();
  const { isWeb } = usePlatformInformation();

  if (!isSelectList && groupPressAction === 'selectall') {
    groupPressAction = 'collapse';
  }

  //used to determine what has been change since component was loaded
  const [initialSelections] = useState(selectedValues);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filter, setFilter] = useState(defaultUserFilter ?? 'all');
  const [selectedFilter, setSelectedFilter] = useState<string | undefined>(undefined);

  const rowPressed = (key: string) => {
    const row = rows?.find((row) => row.object[rowKey] === key);

    if (onRowPress && row) {
      onRowPress(key, row.object);
    }

    if (isSelectList && onSelectionChange) {
      const isAlreadySelected = (selectedValues ?? []).includes(key);
      const selections = isAlreadySelected
        ? [...(selectedValues?.filter((value) => value !== key) ?? [])]
        : [...(selectedValues ?? []), key];
      const newlySelected = newSelections(selections);
      const newlyUnselected = newUnselections(selections);

      onSelectionChange(selections, newlySelected, newlyUnselected);
    }

    if (isWeb && searchInputRef.current && searchTerm.length > 0) {
      searchInputRef.current.focus();
    }
  };

  const toggleMany = (rowKeys: string[], action: 'add' | 'remove') => {
    const selections =
      action === 'remove'
        ? [...(selectedValues?.filter((value) => !rowKeys.includes(value)) ?? [])]
        : [...(selectedValues ?? []), ...rowKeys];

    if (onSelectionChange) {
      const newlySelected = newSelections(selections);
      const newlyUnselected = newUnselections(selections);
      onSelectionChange(selections, newlySelected, newlyUnselected);
    }
    if (isWeb && searchInputRef.current && searchTerm.length > 0) {
      searchInputRef.current.focus();
    }
  };

  //determines what rows are selected now, but weren't initially
  const newSelections = (currentSelections: string[]) => {
    if (!initialSelections || initialSelections.length === 0) return currentSelections;

    return currentSelections.filter(
      (currentSelection) => !initialSelections.includes(currentSelection),
    );
  };

  const newUnselections = (currentSelections: string[]) => {
    if (!initialSelections || initialSelections.length === 0) return [];

    return initialSelections.filter(
      (initialSelection) => !currentSelections.includes(initialSelection),
    );
  };

  const prefilterSelectedCount = selectedValues?.length ?? 0;
  const prefilterUnselectedCount = (rows ?? []).length - prefilterSelectedCount;

  if (selectedFilter === 'selected' && rows) {
    rows = rows.filter((row) => selectedValues?.includes(row.object[rowKey] as string));
  }

  if (selectedFilter === 'unselected' && rows) {
    rows = rows.filter((row) => !selectedValues?.includes(row.object[rowKey] as string));
  }

  if (searchBy && searchTerm.length > 0 && rows) {
    rows = rows.filter(
      (row) =>
        (row.object[searchBy] as string).toLowerCase().indexOf(searchTerm.toLowerCase()) > -1,
    );
  }

  const uniqueUserFilterValues = userFilterBy
    ? [
        ...new Set(
          rows?.filter((row) => row != undefined).map((row) => row.object[userFilterBy] as string),
        ),
      ].sort((a, b) => a.localeCompare(b))
    : [];

  if (uniqueUserFilterValues.length > 0 && filter !== 'all' && rows && userFilterBy) {
    rows = rows.filter((row) => (row.object[userFilterBy] as string) === filter);
  }

  const rowGroups = [
    ...new Set(rows?.filter((row) => row != undefined).map((row) => row.object[groupBy] as string)),
  ].sort((a, b) => a.localeCompare(b));

  if (sortBy && rows) {
    rows = rows
      .slice()
      .sort((a, b) => (a.object[sortBy] as string).localeCompare(b.object[sortBy] as string));
  }

  return (
    <AutumnVStack space={'s'} flex={maxHeight != undefined ? undefined : 1} maxHeight={maxHeight}>
      {searchBy != undefined && (
        <AutumnHStack
          space={2}
          alignItems={'center'}
          borderWidth={1}
          borderRadius={5}
          borderColor={'blackAlpha'}
          paddingLeft={5}
        >
          <Icon icon={faSearch} size={14} color={'black'} />
          <AutumnInputElement
            ref={searchInputRef}
            autoFocus={isWeb}
            selectTextOnFocus={true}
            height={35}
            color={'black'}
            flex={1}
            fontSize={16}
            placeholder={t('Search')}
            placeholderTextColor={'gray'}
            value={searchTerm}
            onChangeText={setSearchTerm}
          />
          {searchTerm.length > 0 && (
            <AutumnButton
              onPress={() => {
                setSearchTerm('');
              }}
              variant={'link'}
              localeKey={'Clear'}
            />
          )}
          {isSelectList && (
            <AutumnHorizontalSelector
              options={[
                {
                  id: 'selected',
                  label: {
                    key: 'SelectedCount',
                    renderParameters: { count: prefilterSelectedCount },
                  },
                },
                {
                  id: 'unselected',
                  label: {
                    key: 'UnselectedCount',
                    renderParameters: { count: prefilterUnselectedCount },
                  },
                },
              ]}
              onChange={(newValue) => setSelectedFilter(newValue)}
              selectedOption={selectedFilter}
              allowDeselect={true}
            />
          )}
        </AutumnHStack>
      )}

      <AutumnScrollView flex={1}>
        <AutumnVStack space={'s'}>
          {rowGroups.map((grouping) => {
            const rowsInGrouping =
              rows?.filter((row) => (row.object[groupBy] as string) === grouping) ?? [];

            const selectedRows = rowsInGrouping
              .filter((row) => selectedValues?.includes(row.object[rowKey] as string))
              .map((row) => row.object[rowKey] as string);
            const unselectedRows = rowsInGrouping
              .filter((row) => !selectedValues?.includes(row.object[rowKey] as string))
              .map((row) => row.object[rowKey] as string);

            const groupRows = rowsInGrouping.map((row, index) => {
              const key = row.object[rowKey] as string;
              return (
                <AutumnGroupedListRow<T>
                  key={key + index.toString()}
                  keyValue={key}
                  row={row}
                  index={index}
                  selectable={isSelectList}
                  isSelected={isSelectList && selectedValues?.includes(key)}
                  onPress={rowPressed}
                />
              );
            });

            return (
              <AutumnGroupedListGrouping
                groupName={grouping}
                onPress={groupPressAction}
                selectedRows={selectedRows}
                unselectedRows={unselectedRows}
                toggleMany={toggleMany}
                nonGroupedItemsLabel={nonGroupedItemsLabel}
              >
                {groupRows}
              </AutumnGroupedListGrouping>
            );
          })}
        </AutumnVStack>
      </AutumnScrollView>

      {uniqueUserFilterValues.length > 0 && (
        <AutumnHorizontalSelector
          options={[
            { id: 'all', label: 'All' },
            ...uniqueUserFilterValues.map((filterValue) => {
              return { id: filterValue, label: { key: filterValue } };
            }),
          ]}
          selectedOption={filter as string}
          onChange={(newValue) => setFilter(newValue ?? 'all')}
        />
      )}

      {rows && rows.length === 0 && (
        <AutumnLocaleText localeKey={'NoSearchResults'} textAlign={'center'} />
      )}
      {loading && <AutumnLoader />}
    </AutumnVStack>
  );
}

function AutumnGroupedListGrouping({
  groupName,
  onPress,
  selectedRows,
  toggleMany,
  unselectedRows,
  nonGroupedItemsLabel,
  children,
}: {
  groupName: string;
  selectedRows: string[];
  unselectedRows: string[];
  onPress?: 'collapse' | 'selectall' | { (group: string): void };
  toggleMany: (keys: string[], action: 'add' | 'remove') => void;
  nonGroupedItemsLabel?: TranslationProps;
  children: ReactNode[];
}) {
  const [hovering, setHovering] = useState(false);
  const [collapsed, setCollapsed] = useState(false);

  const pressed = () => {
    if (onPress === 'collapse') {
      setCollapsed(!collapsed);
    } else if (onPress === 'selectall') {
      if (unselectedRows.length > 0) {
        toggleMany(unselectedRows, 'add');
      } else {
        toggleMany(selectedRows, 'remove');
      }
    } else if (onPress) {
      onPress(groupName);
    }
  };

  const groupTitle =
    (groupName == null || groupName === '') && nonGroupedItemsLabel ? (
      <AutumnLocaleText fontWeight={'bold'} localeKey={nonGroupedItemsLabel} />
    ) : (
      <AutumnText fontWeight={'bold'} fontSize={14}>
        {groupName}
      </AutumnText>
    );

  return (
    <AutumnPressable
      disabled={!onPress}
      onHoverIn={() => setHovering(true)}
      onHoverOut={() => setHovering(false)}
      onPress={pressed}
    >
      <AutumnVStack>
        <AutumnHStack
          backgroundColor={hovering ? 'gray.100' : 'gray'}
          padding={'s'}
          borderRadius={5}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <AutumnHStack space={'s'} alignItems={'center'}>
            {onPress === 'selectall' && (
              <AutumnCheckbox
                height={20}
                width={20}
                isChecked={
                  unselectedRows.length > 0 && selectedRows.length > 0
                    ? 'partially'
                    : selectedRows.length > 0
                }
                onChange={pressed}
              />
            )}

            {groupTitle}
          </AutumnHStack>

          {onPress === 'collapse' && (
            <AutumnIcon icon={collapsed ? faChevronDown : faChevronUp} size={15} />
          )}
        </AutumnHStack>

        {!collapsed && children}
      </AutumnVStack>
    </AutumnPressable>
  );
}

function AutumnGroupedListRow<T>({
  row,
  keyValue,
  selectable,
  isSelected,
  onPress,
  index,
}: {
  row: GroupedListRow<T>;
  keyValue: string;
  index: number;
  onPress: (key: string) => void;
  selectable?: boolean;
  isSelected?: boolean;
}) {
  const [hovering, setHovering] = useState(false);

  return (
    <AutumnPressable
      onPress={() => onPress(keyValue)}
      onHoverIn={() => setHovering(true)}
      onHoverOut={() => setHovering(false)}
    >
      <AutumnHStack
        backgroundColor={hovering ? 'lightgrayAlpha' : undefined}
        borderRadius={5}
        padding={'s'}
        borderTopWidth={index > 0 ? 1 : 0}
        borderTopColor={'gray.50'}
        space={'m'}
        alignItems={'center'}
      >
        {selectable && (
          <AutumnCheckbox isChecked={isSelected ?? false} onChange={() => onPress(keyValue)} />
        )}

        <AutumnVStack space={1}>
          {row.title &&
            (typeof row.title === 'string' ? (
              <AutumnText minWidth={100} fontSize={14}>
                {row.object[row.title as keyof T] as string}
              </AutumnText>
            ) : (
              <AutumnLocaleText
                minWidth={100}
                fontSize={14}
                localeKey={row.title as TranslationProps}
              />
            ))}

          {row.subTitle &&
            (typeof row.subTitle === 'string' ? (
              <AutumnText>{row.object[row.subTitle as keyof T] as string}</AutumnText>
            ) : (
              <AutumnLocaleText localeKey={row.subTitle as TranslationProps} />
            ))}
        </AutumnVStack>
        <AutumnVStack space={1} flex={1}>
          {row.component}
        </AutumnVStack>
        <AutumnVStack space={1}>
          {row.labelRight &&
            (typeof row.subTitle === 'string' ? (
              <AutumnText>{row.object[row.labelRight as keyof T] as string}</AutumnText>
            ) : (
              <AutumnLocaleText localeKey={row.labelRight as TranslationProps} />
            ))}
        </AutumnVStack>
      </AutumnHStack>
    </AutumnPressable>
  );
}
