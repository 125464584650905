import React from 'react';
import { useRecoilState } from 'recoil';
import { devState } from '../state/devState';
import { useIsDev } from '../../../Common/hooks/useIsDev';
import { Pressable, Text, View } from 'react-native';

export function DevSwitcher() {
  const [{ localAPI }, setState] = useRecoilState(devState);

  const onSetLocalAPI = (value: boolean) => setState({ localAPI: value });

  const isDev = useIsDev();

  if (!isDev) return null;

  return (
    <View
      style={{
        position: 'absolute',
        borderRadius: 10,
        bottom: 10,
        right: 10,
        backgroundColor: 'black',
      }}
    >
      <Pressable onPress={() => onSetLocalAPI(!localAPI)}>
        <Text
          style={{
            color: 'white',
            padding: 5,
            fontSize: 10,
          }}
        >
          {localAPI ? 'Local API - Toggle' : 'Hosted API - Toggle'}
        </Text>
      </Pressable>
    </View>
  );
}
