import { createContext } from 'react';

export type ComponentCompletingAs = 'customer';

export type ComponentRenderContext = {
  customerId?: string;
  brand: string;
  tenant: string;
  completingAs: ComponentCompletingAs;
};

export type ComponentRenderVisibilityContext = Record<string, string | boolean | number>;

export type ComponentContextType = {
  renderContext: ComponentRenderContext;
  visibilityContext?: ComponentRenderVisibilityContext;
  onNavigate?: (path: string) => void;
};

// @ts-expect-error Allow null
export const ComponentContext = createContext<ComponentContextType>(null);
