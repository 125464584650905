import { Context } from '../utils/context';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Call, Device } from '@twilio/voice-sdk';
import { useLazyQuery } from '@apollo/client';
import { graphql } from '../../../__generatedtypes__';
import { v4 } from 'react-native-uuid/dist/v4';
import { IncomingCall_CallingDevice } from '../utils/types';
import { Customer } from '../../../__generatedtypes__/graphql';
import Codec = Call.Codec;

const parseCalledId = (calledID: string) => {
  if (calledID.startsWith('+614')) return `04${calledID.substring(4)}`;

  return calledID;
};

export default function ({ children }: { children: React.ReactNode }) {
  const device = useRef<Device | undefined>();

  const [getToken] = useLazyQuery(
    graphql(`
      query token {
        callsAuthorise {
          token
        }
      }
    `),
    { fetchPolicy: 'network-only' },
  );

  const [callID, setCallID] = useState<string | undefined>(undefined);

  const [lookupCustomer] = useLazyQuery(
    graphql(`
      query incomingCallCustomer($mobile: String!) {
        customers(limit: 5, offset: 0, filterKey: mobile, filterValue: $mobile) {
          id
          name
          dob
        }
      }
    `),
  );

  const currentCall = useRef<Call | undefined>(undefined);

  const [connected, setConnected] = useState(false);

  const [incomingCall, setIncomingCall] = useState<IncomingCall_CallingDevice | undefined>(
    undefined,
  );

  const [mute, setMute_] = useState(false);
  const [inCall, setInCall] = useState<string | undefined>(undefined);

  const handleRegistered = () => {
    setConnected(true);
  };

  const handleUnregistered = () => {
    setConnected(false);
  };

  const handleIncomingCall = (call: Call) => {
    const calledId = parseCalledId(call.parameters.From);

    setIncomingCall({
      calledId,
      customers: [],
    });

    currentCall.current = call;

    currentCall.current.on('accept', () => {
      setInCall(calledId);
      setMute_(false);
    });

    currentCall.current.on('reject', () => {
      setIncomingCall(undefined);
      setInCall(undefined);
      currentCall.current = undefined;
    });

    currentCall.current.on('cancel', () => {
      setIncomingCall(undefined);
      setInCall(undefined);
      currentCall.current = undefined;
    });

    currentCall.current.on('disconnect', () => {
      setIncomingCall(undefined);
      setInCall(undefined);
      currentCall.current = undefined;
    });

    lookupCustomer({
      variables: {
        mobile: calledId,
      },
    }).then((e) => {
      if (e?.data?.customers) {
        const customers = e.data?.customers as Customer[];

        setIncomingCall((existingState: IncomingCall_CallingDevice) => ({
          ...existingState,
          customers,
        }));
      }
    });
  };

  const handleTokenExpires = async () => {
    const tokenResponse = await getToken();

    if (!tokenResponse?.data?.callsAuthorise?.token) return;

    device?.current?.updateToken(tokenResponse?.data?.callsAuthorise?.token);
  };

  const setupDevice = async () => {
    const tokenResponse = await getToken();

    if (!tokenResponse?.data?.callsAuthorise?.token) return;

    device.current = new Device(tokenResponse?.data.callsAuthorise.token, {
      closeProtection: true,
      appName: 'Autumn',
      codecPreferences: [Codec.Opus, Codec.PCMU],
      allowIncomingWhileBusy: false,
    });

    device.current.on('incoming', handleIncomingCall);
    device.current.on('registered', handleRegistered);
    device.current.on('unregistered', handleUnregistered);
    device.current.on('tokenWillExpire', handleTokenExpires);

    await device.current.register();
  };

  const sendDigits = useCallback(
    (digit: string) => {
      if (currentCall?.current) {
        currentCall?.current?.sendDigits(digit);
      }
    },
    [currentCall],
  );

  const answerCall = useCallback(() => {
    if (currentCall?.current) {
      currentCall.current.accept();
    }
  }, [currentCall]);

  const rejectCall = useCallback(() => {
    if (currentCall?.current) {
      currentCall.current.reject();
    }
  }, [currentCall]);

  const hangup = useCallback(() => {
    if (currentCall?.current) {
      currentCall.current.disconnect();
      setIncomingCall(undefined);
      currentCall.current = undefined;
    }
  }, [currentCall]);

  const makeCall = useCallback(
    async (lineId: string, number: string, customerId?: string | undefined | null) => {
      if (!number || !device?.current) return;

      if (currentCall.current) {
        currentCall.current.disconnect();

        currentCall.current = undefined;

        return;
      }

      setMute_(false);
      setInCall(number);

      const CallID = v4().toString();

      setCallID(callID);

      currentCall.current = await device.current.connect({
        params: {
          LineId: lineId,
          To: number,
          CallID,
          ...(customerId && {
            CustomerID: customerId,
          }),
        },
      });

      currentCall.current.on('disconnect', () => {
        setInCall(undefined);
      });

      currentCall.current.on('cancel', () => {
        setInCall(undefined);
      });
    },
    [device, setInCall],
  );

  const hasPhonePermission = true;

  useEffect(() => {
    if (hasPhonePermission) {
      setupDevice().catch((e) => console.log(e));
    }
  }, [hasPhonePermission]);

  const setMute = useCallback(
    (value: boolean) => {
      if (currentCall.current) {
        currentCall.current.mute(value);
        setMute_(value);
      }
    },
    [setMute_],
  );

  return (
    <Context.Provider
      value={{
        connected,
        makeCall,
        inCall,
        sendDigits,
        hangup,
        mute,
        setMute,
        incomingCall,
        answerCall,
        rejectCall,
        callID,
      }}
    >
      {children}
    </Context.Provider>
  );
}
