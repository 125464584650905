import React from 'react';

import {
  AutumnButton,
  AutumnHStack,
  AutumnModal,
  AutumnVStack,
} from '../../../Common/components/ui';
import { useThreadLinking } from '../hooks/useThreadLinking';
import { AutumnCustomerSearch } from '../../../Common/components/ui/AutumnCustomerSearch';
import { AutumnCustomerName } from '../../../Common/components/ui/AutumnCustomerName';

export function LinkThreadToCustomerModal({
  threadId,
  onClose,
  onLinked,
}: {
  threadId: string;
  onClose: () => void;
  onLinked: (newThreadId: string) => void;
}) {
  const { linkThreadToCustomer, linking, selectedCustomer, setSelectedCustomer, canLink } =
    useThreadLinking();

  const doLink = async () => {
    const newId = await linkThreadToCustomer(threadId);

    if (newId) {
      onLinked(newId);
    }
  };

  return (
    <AutumnModal
      show={true}
      title={'LinkToCustomer'}
      type={'rightSide'}
      onClose={onClose}
      onRequestClose={canLink ? undefined : () => onClose()}
    >
      <AutumnVStack space={'m'}>
        {!selectedCustomer && (
          <AutumnCustomerSearch
            label={'Customer'}
            initialValue={undefined}
            onSelectedCustomer={(customer) => {
              setSelectedCustomer(customer);
            }}
          />
        )}

        {selectedCustomer && (
          <AutumnHStack space={'s'} justifyContent={'space-between'} alignItems={'center'}>
            <AutumnCustomerName
              iconSize={40}
              fragment={selectedCustomer}
              includeName={true}
              fontSize={16}
              includeMobile={true}
              variant={'bold'}
            />

            <AutumnButton
              onPress={() => setSelectedCustomer(undefined)}
              localeKey={'Change'}
              variant={'link'}
            />
          </AutumnHStack>
        )}

        <AutumnButton
          onPress={doLink}
          localeKey={'LinkToCustomer'}
          isLoading={linking}
          disabled={!canLink}
        />
      </AutumnVStack>
    </AutumnModal>
  );
}
