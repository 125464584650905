import { AutumnVStack } from '../../../../WebApp/Common/components/ui';
import React, { useContext, useMemo } from 'react';
import { Config_Portal_Pages } from '../../../../WebApp/__generatedtypes__/graphql';
import { useComponentRender } from '../../../../Common/Components/hooks/useComponentRender';
import { ComponentContext } from '../../../../Common/Components/context/ComponentContext';

export function PatientAppPage({ page }: { page: Config_Portal_Pages }) {
  const { renderComponent } = useComponentRender();
  const { visibilityContext } = useContext(ComponentContext);

  const renderedComponents = useMemo(() => {
    return page.components.map((component) => renderComponent(component, visibilityContext));
  }, [page.components, visibilityContext]);

  return <AutumnVStack space={'s'}>{renderedComponents}</AutumnVStack>;
}
