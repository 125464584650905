import React, { useEffect, useState } from 'react';
import { enLocalKeys } from '../../../features/Localisations/locales/en';
import { AutumnBox, AutumnBoxAnimated, AutumnBoxAnimatedProps } from './AutumnBox';
import { AutumnScrollView } from './AutumnScrollView';
import { AutumnHStack } from './AutumnHStack';
import { AutumnPressable } from './AutumnPressable';
import { AutumnText } from './AutumnText';
import { Portal } from '@gorhom/portal';
import { BoxProps } from '@shopify/restyle';
import { Theme } from './theme';
import {
  runOnJS,
  SlideInDown,
  SlideInRight,
  SlideOutDown,
  SlideOutRight,
  useSharedValue,
  withSpring,
  ZoomIn,
  ZoomOut,
} from 'react-native-reanimated';
import { KeyboardAvoidingView, Pressable } from 'react-native';
import {
  TranslationProps,
  useTranslation,
} from '../../../features/Localisations/hooks/useTranslation';
import { AutumnIcon } from './AutumnIcon';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { AutumnVStack } from './AutumnVStack';
import { usePlatformInformation } from '../../utils/usePlatformInformation';

const animationDuration = 300;

export function AutumnModal({
  show,
  children,
  title,
  onClose,
  type = 'bottomSheet',
  onRequestClose,
  disableScroll,
  disableAnimation,
  minWidth = 500,
}: {
  show: boolean;
  children: React.ReactNode;
  title: keyof typeof enLocalKeys | TranslationProps;
  onClose: () => void;
  onRequestClose?: () => void;
  type?: 'bottomSheet' | 'centered' | 'rightSide' | 'fullscreen';
  disableScroll?: boolean; //useful if the content needs to handle its own scrolling
  disableAnimation?: boolean;
  minWidth?: number; //only applies on 'rightSide'
}) {
  const { isSmallScreen, screenHeight } = usePlatformInformation();
  const [hovering, setHovering] = useState(false);
  const [animating, setAnimating] = useState(!disableAnimation);

  if (isSmallScreen) {
    type = 'bottomSheet';
  }

  const outerBoxStyling: BoxProps<Theme> = {
    ...(type === 'centered' && {
      justifyContent: 'center',
      alignItems: 'center',
    }),
    ...(type === 'bottomSheet' && {
      justifyContent: 'flex-end',
    }),
    ...(type === 'rightSide' && {
      justifyContent: 'flex-end',
      flexDirection: 'row',
    }),
  };

  const finishedAnimation = () => {
    //this rerenders the child component on animation completion. Prevents child objects getting incorrect layout props
    setAnimating(false);
  };

  const boxStyling: AutumnBoxAnimatedProps = {
    backgroundColor: 'white',
    padding: 'm',
    ...(type === 'rightSide' && {
      height: '100%',
      minWidth: minWidth,
      marginTop: 0,
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5,
      entering: disableAnimation
        ? undefined
        : SlideInRight.duration(animationDuration).withCallback(() => runOnJS(finishedAnimation)()),
      exiting: disableAnimation ? undefined : SlideOutRight.duration(animationDuration),
    }),
    ...(type === 'bottomSheet' && {
      width: '100%',
      maxHeight: screenHeight - 60,
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      entering: disableAnimation
        ? undefined
        : SlideInDown.duration(animationDuration).withCallback(() => runOnJS(finishedAnimation)()),
      exiting: disableAnimation ? undefined : SlideOutDown.duration(animationDuration),
    }),
    ...(type === 'fullscreen' && {
      width: '100%',
      height: screenHeight - 100,
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      bottom: 0,
      entering: disableAnimation
        ? undefined
        : SlideInDown.duration(animationDuration).withCallback(() => runOnJS(finishedAnimation)()),
      exiting: disableAnimation ? undefined : SlideOutDown.duration(animationDuration),
    }),
    ...(type === 'centered' && {
      width: 300,
      maxHeight: screenHeight - 60,
      borderRadius: 5,
      exiting: disableAnimation ? undefined : ZoomOut.duration(animationDuration),
      entering: disableAnimation
        ? undefined
        : ZoomIn.duration(animationDuration).withCallback(() => runOnJS(finishedAnimation)()),
      style: {
        shadowColor: '#000',
        shadowOffset: {
          width: 0,
          height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
      },
    }),
  };

  const { t } = useTranslation();

  const hoverAnimationProgress = useSharedValue(0);

  useEffect(() => {
    hoverAnimationProgress.value = withSpring(hovering ? -10 : 0);
  }, [hovering]);

  if (!show) return;

  return (
    <Portal>
      <AutumnBox
        width={'100%'}
        height={'100%'}
        position={'absolute'}
        left={0}
        top={0}
        bg={'black'}
        opacity={0.6}
      />

      <AutumnBox
        width={'100%'}
        height={'100%'}
        position={'absolute'}
        left={0}
        top={0}
        justifyContent={'flex-end'}
        {...outerBoxStyling}
      >
        {onRequestClose && (
          <AutumnBox width={'100%'} height={'100%'} position={'absolute'} left={0} top={0}>
            <Pressable
              onHoverIn={() => setHovering(true)}
              onHoverOut={() => setHovering(false)}
              style={{
                width: '100%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onPress={onRequestClose}
            />
          </AutumnBox>
        )}

        {type === 'rightSide' && onRequestClose && (
          <Pressable onPress={onRequestClose} style={{ alignSelf: 'center', margin: 10 }}>
            <AutumnBoxAnimated
              style={{ marginRight: hoverAnimationProgress }}
              {...{ entering: SlideInRight.duration(animationDuration) }}
            >
              <AutumnIcon color={'white'} icon={faChevronRight} size={30} />
            </AutumnBoxAnimated>
          </Pressable>
        )}
        <KeyboardAvoidingView behavior={'padding'}>
          <AutumnBoxAnimated {...boxStyling}>
            <AutumnHStack marginBottom={'m'}>
              <AutumnBox flex={1}>
                <AutumnText fontSize={15} fontWeight={'bold'}>
                  {t(title)}
                </AutumnText>
              </AutumnBox>

              <AutumnPressable onPress={onClose}>
                <AutumnText color={'black'} opacity={0.5}>
                  {t('Close')}
                </AutumnText>
              </AutumnPressable>
            </AutumnHStack>

            {disableScroll && (
              <AutumnVStack key={animating.toString()} flex={1}>
                {show && children}
              </AutumnVStack>
            )}
            {!disableScroll && (
              <AutumnScrollView key={animating.toString()}>{show && children}</AutumnScrollView>
            )}
          </AutumnBoxAnimated>
        </KeyboardAvoidingView>
      </AutumnBox>
    </Portal>
  );
}
