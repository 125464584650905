import { useEffect, useMemo, useState } from 'react';
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';
import { usePaymentGatewayContext } from './usePaymentGatewayContext';
import { usePaymentGatewayTokenCreate } from './usePaymentGatewayToken';

export const usePaymentGatewayStripeSetup = () => {
  const { customerId, cardGateway } = usePaymentGatewayContext();

  const stripePromise = useMemo(() => loadStripe(cardGateway.gatewayPublicKey), []);

  const [options, setOptions] = useState<StripeElementsOptions | undefined>(undefined);

  const { setupToken } = usePaymentGatewayTokenCreate();

  const setupSession = async () => {
    setOptions(undefined);

    const clientSecret = await setupToken(customerId, cardGateway.id);

    setOptions({
      clientSecret,
    });
  };

  useEffect(() => {
    setupSession().catch(null);
  }, [customerId]);

  return { options, stripePromise };
};
