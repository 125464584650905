import React, { memo } from 'react';
import { Message } from './Message';
import { ComposeMessage } from './ComposeMessage';
import { useCustomerMessages } from '../hooks/useCustomerMessages';
import {
  AutumnBox,
  AutumnLoader,
  AutumnScrollView,
  AutumnVStack,
} from '../../../Common/components/ui';
import {
  Message_Action,
  Message_ContentType,
  Message_Sender,
  Messaging_Channel,
} from '../../../__generatedtypes__/graphql';
import { MessagesHeader } from './MessagesHeader';
import { AutumnEmptyData } from '../../../Common/components/ui/AutumnEmptyData';

export const Messages = memo(
  ({
    threadId,
    markReadWhenViewed,
    isCustomerFacing,
    hideActionsHeader,
    brand,
  }: {
    threadId: string;
    markReadWhenViewed?: boolean;
    isCustomerFacing: boolean;
    hideActionsHeader: boolean;
    brand: string;
  }) => {
    const {
      filteredMessages,
      customerId,
      channelsUsed,
      availableChannels,
      showMarkAsRead,
      loading,
      transformSenderImage,
      markAsRead,
      isUnreadInternal,
      onType,
      transformFrom,
      messagesEndRef,
      toggleOpen,
      isOpen,
    } = useCustomerMessages(threadId, brand, markReadWhenViewed);

    const messageList = filteredMessages.map((message) => {
      return (
        <Message
          key={message?.id}
          isCustomerFacing={isCustomerFacing}
          threadId={threadId}
          channel={message?.channel ?? Messaging_Channel.Portal}
          messageId={message?.id ?? ''}
          contentType={message?.contentType ?? Message_ContentType.Text}
          messageContent={message?.content ?? ''}
          sentAt={message?.timestamp}
          senderId={message?.senderId ?? ''}
          senderType={message.sender as Message_Sender}
          senderImage={transformSenderImage(
            message.sender as Message_Sender,
            message.senderId ?? '',
            isCustomerFacing,
          )}
          senderName={transformFrom(
            message.sender as Message_Sender,
            message.senderId ?? '',
            isCustomerFacing,
            message.senderName ?? undefined,
          )}
          actions={message.actions as Message_Action[]}
        />
      );
    });

    return (
      <AutumnVStack flex={1}>
        {!hideActionsHeader && !isCustomerFacing && (
          <MessagesHeader
            threadId={threadId}
            customerId={customerId}
            channelsUsed={channelsUsed}
            threadRead={!isUnreadInternal}
            markAsRead={() => showMarkAsRead && markAsRead()}
            markAsUnread={() => markAsRead(true)}
            isOpen={isOpen}
            toggleOpen={toggleOpen}
            brand={brand}
          />
        )}

        <AutumnScrollView ref={messagesEndRef}>
          <AutumnVStack space={'m'} padding={'s'}>
            {!hideActionsHeader && !isCustomerFacing && <AutumnBox height={30}></AutumnBox>}
            {messageList}
          </AutumnVStack>

          {!loading && messageList.length === 0 && <AutumnEmptyData name={'Messages'} />}

          {loading && <AutumnLoader />}
        </AutumnScrollView>

        <ComposeMessage
          key={threadId}
          isCustomerFacing={isCustomerFacing}
          channels={availableChannels}
          brand={brand}
          onType={onType}
          threadId={threadId}
          customerId={customerId}
        />
      </AutumnVStack>
    );
  },
  (oldProps, newProps) => oldProps.threadId === newProps.threadId,
);
