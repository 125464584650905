import { stringToColour } from '../../../Common/utils/color';
import { Message_Thread, Messaging_Channel } from '../../../__generatedtypes__/graphql';
import { faMessageSms } from '@fortawesome/pro-solid-svg-icons';
import { faUser } from '@fortawesome/pro-solid-svg-icons/faUser';
import { faMailbox } from '@fortawesome/pro-solid-svg-icons/faMailbox';
import { faFacebookMessenger, faInstagram } from '@fortawesome/free-brands-svg-icons';

export const titleForMessageThread = (message: Message_Thread) => {
  if (message?.customer) {
    return `${message?.customer?.given} ${message?.customer?.surname}`;
  }

  return `Message`;
};

export const colorForMessageThread = (message: Message_Thread) => {
  return stringToColour(
    message?.customer ? `${message?.customer?.given} ${message?.customer?.surname}` : '',
  );
};

export const initialForMessageThread = (message: Message_Thread) => {
  if (message?.customer) {
    return `${message?.customer?.given?.substr(0, 1)}${message?.customer?.surname?.substr(
      0,
      1,
    )}`?.toUpperCase();
  }

  return '';
};

export const iconForChannel = (channel: Messaging_Channel) => {
  switch (channel) {
    case Messaging_Channel.Sms:
      return faMessageSms;
    case Messaging_Channel.EMail:
      return faMailbox;
    case Messaging_Channel.Facebook:
      return faFacebookMessenger;
    case Messaging_Channel.Instagram:
      return faInstagram;
  }

  return faUser;
};
