import { AutumnBox } from './AutumnBox';
import { AutumnLoader } from './AutumnLoader';
import React from 'react';

export function AutumnLoaderFullscreen() {
  return (
    <AutumnBox bg={'forestGreen'} alignItems={'center'} justifyContent={'center'} flex={1}>
      <AutumnLoader />
    </AutumnBox>
  );
}
