import {
  Config_Portal_Pages_Component,
  Config_Portal_Pages_Component_TitleProps,
} from '../../../WebApp/__generatedtypes__/graphql';
import { AutumnText } from '../../../WebApp/Common/components/ui';
import React from 'react';

export function ComponentTitle({ component }: { component: Config_Portal_Pages_Component }) {
  const props = component.props as Config_Portal_Pages_Component_TitleProps;

  return <AutumnText variant={'header'}>{props.title}</AutumnText>;
}
