import {
  Config_Portal_Pages_Component,
  Config_Portal_Pages_Component_HStackVStackProps,
} from '../../../WebApp/__generatedtypes__/graphql';
import { useComponentRender } from '../hooks/useComponentRender';
import { AutumnVStack } from '../../../WebApp/Common/components/ui';
import React, { useContext, useMemo } from 'react';
import { Theme } from '../../../WebApp/Common/components/ui/theme';
import { FlexAlignType } from 'react-native';
import { ComponentUtils } from '../utils/utils';
import { ComponentContext } from '../context/ComponentContext';

export function ComponentVStack({ component }: { component: Config_Portal_Pages_Component }) {
  const { renderComponent } = useComponentRender();

  const { visibilityContext } = useContext(ComponentContext);

  const props = component.props as Config_Portal_Pages_Component_HStackVStackProps;

  const components = useMemo(() => {
    return component.children?.map((child) => renderComponent(child, visibilityContext));
  }, [component.children, visibilityContext]);

  const style = ComponentUtils.convertToStyleObject(component.style);

  return (
    <AutumnVStack
      {...(props?.spacing && { space: props?.spacing as keyof Theme['spacing'] })}
      {...(props?.alignItems && {
        alignItems: props?.alignItems as FlexAlignType,
      })}
      style={style}
    >
      {components}
    </AutumnVStack>
  );
}
