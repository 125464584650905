import { Flow } from '../../../../Common/Flows/components/Flow';
import { usePatientAppConfig } from '../../AppConfig/hooks/usePatientAppConfig';
import { useParams } from 'react-router';
import React from 'react';
import { usePatientAppAuth } from '../../Auth/hooks/usePatientAppAuth';

export function PatientAppFlow() {
  const { tenant, brand } = usePatientAppConfig();
  const { sub } = usePatientAppAuth();
  const { id } = useParams();

  return (
    <Flow
      customerId={sub}
      flowId={id as string}
      completingAs={'customer'}
      brand={brand}
      tenant={tenant}
      persist={true}
    />
  );
}
