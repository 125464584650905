import React, { forwardRef } from 'react';
import { PaymentGatewayContext } from '../context/context';
import { usePaymentGateway } from '../hooks/usePaymentGateway';
import { Stripe } from './Stripe';
import { ActivityIndicator } from 'react-native';
import { Payment_Gateway_Config } from '../../../WebApp/__generatedtypes__/graphql';

export type PaymentGatewaySaveToken = {
  ok: boolean;
  token?: string;
};

export type PaymentGatewayRef = {
  saveToken: () => Promise<PaymentGatewaySaveToken>;
};

export type PaymentGatewayProps = {
  customerId: string;
};

export const PaymentGateway = forwardRef<PaymentGatewayRef, PaymentGatewayProps>(
  ({ customerId }, ref) => {
    const { state, setState, cardGateway } = usePaymentGateway(customerId);

    if (!cardGateway) return <ActivityIndicator />;

    return (
      <PaymentGatewayContext.Provider
        value={{
          customerId,
          state,
          cardGateway: cardGateway as Payment_Gateway_Config,
        }}
      >
        {cardGateway?.type === 'Stripe' && state.selectedPaymentOption === 'new' && (
          <Stripe ref={ref} />
        )}
      </PaymentGatewayContext.Provider>
    );
  },
);
