import { useAutumnQuery } from '../../../../WebApp/features/GraphQL/hooks/useAutumnQuery';
import { graphql } from '../../../../WebApp/__generatedtypes__';
import { Config_Portal_Pages } from '../../../../WebApp/__generatedtypes__/graphql';
import { usePatientAppConfig } from '../../AppConfig/hooks/usePatientAppConfig';

export const usePatientAppPages = () => {
  const { brand, tenant } = usePatientAppConfig();

  const { data, loading } = useAutumnQuery(
    graphql(`
      query pages($tenant: String!, $brand: String!) {
        pagesForPortal(tenant: $tenant, brand: $brand) {
          name
          id
          components {
            key
            type
            visibilityExpression
            style {
              key
              value
              type
            }
            props {
              ... on Config_Portal_Pages_Component_ButtonProps {
                action
                actionLink
                label
              }
              ... on Config_Portal_Pages_Component_HorizontalPickerProps {
                options
                explainer
                title
                id
              }
              ... on Config_Portal_Pages_Component_HStackVStackProps {
                spacing
                alignItems
              }
              ... on Config_Portal_Pages_Component_ImageProps {
                source
                maxHeight
                maxWidth
                width
                height
              }
              ... on Config_Portal_Pages_Component_TextInputProps {
                explainer
                title
                id
              }
              ... on Config_Portal_Pages_Component_TextProps {
                text
              }
            }
            children {
              type
              style {
                key
                value
                type
              }
              props {
                ... on Config_Portal_Pages_Component_ButtonProps {
                  action
                  actionLink
                  label
                }
                ... on Config_Portal_Pages_Component_HorizontalPickerProps {
                  options
                  explainer
                  title
                  id
                }
                ... on Config_Portal_Pages_Component_HStackVStackProps {
                  spacing
                  alignItems
                }
                ... on Config_Portal_Pages_Component_ImageProps {
                  source
                  maxHeight
                  maxWidth
                  width
                  height
                }
                ... on Config_Portal_Pages_Component_TitleProps {
                  title
                }
                ... on Config_Portal_Pages_Component_TextInputProps {
                  explainer
                  title
                  id
                }
                ... on Config_Portal_Pages_Component_TextProps {
                  text
                }
              }
            }
          }
          protected
          __typename
        }
      }
    `),
    { variables: { brand, tenant }, fetchPolicy: 'cache-first' },
  );

  const pageForRoute = (path: string) => data?.pagesForPortal?.find((a) => a.id === path);

  return { pageForRoute, pages: (data?.pagesForPortal ?? []) as Config_Portal_Pages[], loading };
};
