import { AutumnTitle, AutumnVStack } from '../../../../WebApp/Common/components/ui';
import React from 'react';
import { AutumnLocaleText } from '../../../../WebApp/Common/components/ui/AutumnText';

export function NotFound() {
  return (
    <AutumnVStack space={'m'} alignItems={'center'} justifyContent={'center'} flex={1}>
      <AutumnTitle localeKey={'NotFound'} />
      <AutumnLocaleText localeKey={'NotFoundDescription'} />
    </AutumnVStack>
  );
}
