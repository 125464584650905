import { useAutumnQuery } from '../../../WebApp/features/GraphQL/hooks/useAutumnQuery';
import { graphql } from '../../../WebApp/__generatedtypes__';
import { useTimezones } from '../../../WebApp/features/Time/hooks/useTimezones';
import { useEffect, useMemo, useRef, useState } from 'react';
import { FlowContextStateType, FlowProps, PersistFlowStateType } from '../types/types';
import { Config_Portal_Flow } from '../../../WebApp/__generatedtypes__/graphql';
import dayjs from 'dayjs';
import { v4 } from 'uuid';
import {
  deleteStorageKey,
  getStorage,
  setStorage,
} from '../../../WebApp/features/Storage/utils/storage';
import { useFlowManager } from './useFlowManager';
import { useNavigate } from 'react-router';
import { ComponentContextType } from '../../Components/context/ComponentContext';
import { useFlowServerProgress } from './useFlowServerProgress';
import { useFlowCustomerCheck } from './useFlowCustomerCheck';
import { useFlowPrefill } from './useFlowPrefill';

export const useFlow = ({
  tenant,
  flowId,
  customerId,
  persist,
  brand,
  completingAs,
}: FlowProps) => {
  const { data, loading } = useAutumnQuery(
    graphql(`
      query patientAppFlow($brand: String!, $tenant: String!, $id: ID!) {
        flowForPortal(tenant: $tenant, brand: $brand, id: $id) {
          name
          id
          steps {
            name
            validation
            components {
              key
              type
              visibilityExpression
              style {
                key
                value
                type
              }
              props {
                ... on Config_Portal_Pages_Component_ButtonProps {
                  action
                  actionLink
                  label
                }
                ... on Config_Portal_Pages_Component_HorizontalPickerProps {
                  options
                  explainer
                  title
                  id
                }
                ... on Config_Portal_Pages_Component_HStackVStackProps {
                  spacing
                  alignItems
                }
                ... on Config_Portal_Pages_Component_ImageProps {
                  source
                  maxHeight
                  maxWidth
                  width
                  height
                }
                ... on Config_Portal_Pages_Component_PaymentProps {
                  id
                  title
                  explainer
                }
                ... on Config_Portal_Pages_Component_TextInputProps {
                  explainer
                  title
                  large
                  id
                }
                ... on Config_Portal_Pages_Component_TitleProps {
                  title
                }
                ... on Config_Portal_Pages_Component_PhoneProps {
                  title
                  id
                  explainer
                  country
                }
                ... on Config_Portal_Pages_Component_DateInputProps {
                  title
                  id
                  explainer
                }
                ... on Config_Portal_Pages_Component_TextProps {
                  text
                }
              }
              children {
                type
                style {
                  key
                  value
                  type
                }
                props {
                  ... on Config_Portal_Pages_Component_ButtonProps {
                    action
                    actionLink
                    label
                  }
                  ... on Config_Portal_Pages_Component_PaymentProps {
                    id
                    title
                    explainer
                  }
                  ... on Config_Portal_Pages_Component_HorizontalPickerProps {
                    options
                    explainer
                    title
                    id
                  }
                  ... on Config_Portal_Pages_Component_HStackVStackProps {
                    spacing
                    alignItems
                  }
                  ... on Config_Portal_Pages_Component_ImageProps {
                    source
                    maxHeight
                    maxWidth
                    width
                    height
                  }
                  ... on Config_Portal_Pages_Component_TextInputProps {
                    explainer
                    title
                    id
                  }
                  ... on Config_Portal_Pages_Component_PhoneProps {
                    title
                    id
                    explainer
                    country
                  }
                  ... on Config_Portal_Pages_Component_TitleProps {
                    title
                  }
                  ... on Config_Portal_Pages_Component_TextProps {
                    text
                  }
                }
              }
            }
          }
        }
      }
    `),
    {
      variables: { tenant, brand, id: flowId ?? '' },
      skip: !flowId,
    },
  );

  const { usersTimezone } = useTimezones();

  const [state, setState] = useState<FlowContextStateType | undefined>(undefined);

  const persistKey = `flow_${flowId}`;

  useEffect(() => {
    if (!loading && data && !state) {
      const flowState: FlowContextStateType = {
        flow: data.flowForPortal as Config_Portal_Flow,
        currentStep: 0,
        started: dayjs().toISOString(),
        formFields: {},
        formFieldsValidation: true,
        timezoneUser: usersTimezone,
        id: v4(),
        brand,
        tenant,
        completingAs,
        restored: false,
        formFieldsValidationErrors: {},
        stepRefs: {},
        metadata: {},
        completed: false,
        loading: false,
        customerId,
        formFieldsPrefilled: false,
      };

      if (persist) {
        const persistedData = getStorage<PersistFlowStateType>(persistKey);

        if (persistedData) {
          const startedWhen = dayjs().diff(dayjs(persistedData.started), 'minutes');

          if (startedWhen <= 15) {
            setState({ ...flowState, ...persistedData, restored: true });

            return;
          } else {
            deleteStorageKey(persistKey);
          }
        }
      }

      setState(flowState);
    }
  }, [loading, data, state]);

  useEffect(() => {
    if (persist && state) {
      setStorage<PersistFlowStateType>(persistKey, {
        formFields: state.formFields,
        formFieldsValidation: state.formFieldsValidation,
        id: state.id,
        formFieldsValidationErrors: state.formFieldsValidationErrors,
        started: state.started,
        currentStep: state.currentStep,
        customerId: state.customerId,
        metadata: state.metadata,
      });
    }
  }, [persist, state]);

  const [captchaShow, setCaptchaShow] = useState(false);

  const captchaToken = useRef<string | undefined>(undefined); // Must be a ref - state won't get captured within the return promise!

  const triggerRecaptcha = async (): Promise<string | null> => {
    captchaToken.current = undefined;

    setCaptchaShow(true);

    const checkInterval = 50; // Check every 50ms
    const timeout = 120 * 1000; // 120 second timeout

    return new Promise((accept, reject) => {
      const start = new Date().getTime();

      const checkValue = () => {
        if (captchaToken.current !== undefined) {
          setCaptchaShow(false);
          accept(captchaToken.current);
        } else {
          const now = new Date().getTime();

          if (now - start >= timeout) {
            reject(new Error('Operation timed out'));
          } else {
            setTimeout(checkValue, checkInterval);
          }
        }
      };

      checkValue();
    });
  };

  useFlowServerProgress(state);

  useFlowCustomerCheck(state, setState, triggerRecaptcha);

  useFlowPrefill(state, setState);

  const { nextStep, backStep } = useFlowManager(state, setState);

  const setFormValue = (key: string, value: string | number | boolean) => {
    setState((existingState) => {
      return {
        ...existingState,
        formFields: { ...existingState?.formFields, [key]: value },
      } as FlowContextStateType;
    });
  };

  const navigate = useNavigate();

  const context = useMemo(() => {
    return {
      renderContext: {
        tenant,
        brand,
        completingAs,
        customerId,
      },
      visibilityContext: {
        ...state?.formFields,
        loggedIn: state?.customerId ? true : false,
        prefilled: state?.formFieldsPrefilled,
      },
      onNavigate: navigate,
    } as ComponentContextType;
  }, [
    tenant,
    brand,
    completingAs,
    customerId,
    state?.formFields,
    navigate,
    state?.customerId,
    state?.formFieldsPrefilled,
  ]);

  const setLoggedIn = (loggedInCustomerId: string) => {
    setState((existingState) => {
      return {
        ...existingState,
        login: undefined,
        customerId: loggedInCustomerId,
      } as FlowContextStateType;
    });

    // Do we advance a step or just rerun visibility expression(?)
  };

  const registerStepRef = (name: string, ref: () => Promise<boolean>) => {
    setState((existingState: FlowContextStateType) => {
      return {
        ...existingState,
        stepRefs: { ...existingState.stepRefs, [name]: ref },
      };
    });
  };

  const deregisterStepRef = (name: string) => {
    setState((existingState: FlowContextStateType) => {
      const stepRefs = { ...existingState.stepRefs };
      delete stepRefs[name];

      return {
        ...existingState,
        stepRefs,
      };
    });
  };

  const isLoading = loading || state === undefined;

  return {
    isLoading,
    context,
    state: state as FlowContextStateType,
    setFormValue,
    setLoggedIn,
    nextStep,
    backStep,
    captchaToken,
    registerStepRef,
    deregisterStepRef,
    captchaShow,
  };
};
