import { useQuery } from '@apollo/client';
import { graphql } from '../../../../../WebApp/__generatedtypes__';
import { CustomTreatment } from '../components/Treatment';
import { useConfig } from '../../../../../WebApp/features/Config/hooks/useConfig';
import { useUserSettings } from '../../../../../WebApp/features/UserSettings/hooks/useUserSettings';
import * as currencies from 'iso-country-currency';
import { TreatmentOption } from '../../../../__generatedtypes__/graphql';

export const TREATMENTS_QUERY = graphql(`
  query Treatments($term: String) {
    treatments(term: $term) {
      id
      tenant
      active
      name
      publicName
      tag {
        id
        name
      }
      defaultColor
      publicTagId
      publicDescription
      publicImage
      defaultAftercare
      defaultImageRequirements {
        id
        angle
        frequencyType
        frequency
        optional
        imageCaptureType
      }
      defaultResourceRequirements {
        id
        resources
      }
      options {
        id
        active
        name
        fullName
        publicName
        publicDescription
        publicImage
        bookableOnline
        aftercare_Default
        aftercare
        color_Default
        color
        imageRequirements_Default
        resourceRequirements_Default
        prices {
          id
          active
          currencyCode
          taxRateId
          taxRate {
            id
            name
            active
            taxPercentage
            currencyCode
          }
          priceEx
          priceTax
          priceTotal
          appliesToUser
          appliesToLocation
          appliesToUserType
        }
        timing {
          id
          slots
          active
          isDefault
          appliesToUser
          appliesToLocation
          appliesToUserType
        }
        aftercare_Default
        aftercare
        imageRequirements_Default
        imageRequirements {
          id
          angle
          frequencyType
          frequency
          optional
          imageCaptureType
        }
        resourceRequirements {
          id
          resources
        }
      }
    }
  }
`);

export type FlattenTreatmentOption = TreatmentOption & {
  treatmentId: string;
  treatmentName: string;
  treatmentTagName: string;
  treatmentActive: boolean;
};

export const useTreatment = () => {
  const { locations, tenant } = useConfig();
  const { settings } = useUserSettings();

  const { data: treatmentData, loading: treatmentListLoading } = useQuery(TREATMENTS_QUERY);

  const getFormattedDataForTreatment = (treatmentArray: CustomTreatment[]) => {
    const newAr = treatmentArray?.map((result) => {
      const resultCopy = { ...result };

      resultCopy.activeCount = resultCopy.options?.filter((ele) => ele?.active).length;

      resultCopy.onlineBookableCount = resultCopy.options?.filter(
        (ele) => ele?.active && ele.bookableOnline,
      ).length;

      const slotDurations =
        resultCopy.options?.flatMap((option) =>
          option?.timing?.flatMap((timing) => timing?.slots || []),
        ) || [];

      const validDurations =
        slotDurations
          .filter((duration) => typeof duration === 'number')
          .map((duration) => {
            return (duration ?? 0) * (tenant?.appointmentDuration || 0);
          }) || [];

      if (validDurations.length > 0) {
        const minDuration = Math.min(...validDurations);
        const maxDuration = Math.max(...validDurations);

        resultCopy.duration =
          minDuration === maxDuration
            ? `${minDuration} minutes`
            : `${minDuration} to ${maxDuration} minutes`;
      } else {
        resultCopy.duration = '-';
      }

      const getCurrency = locations.find(
        (location) => location.id === settings?.location,
      )?.currency;

      const priceTotals =
        resultCopy.options?.flatMap((option) => {
          return option?.prices
            ?.filter((price) => price?.currencyCode === getCurrency)
            .map((price) => price?.priceTotal);
        }) || [];

      const validPrices = priceTotals
        .filter((price) => typeof price === 'number')
        .filter((a) => a !== undefined) as number[];

      if (validPrices.length > 0) {
        const minPrice = Math.min(...validPrices);
        const maxPrice = Math.max(...validPrices);

        const sign = currencies.getParamByParam('currency', getCurrency as string, 'symbol');

        resultCopy.priceRange =
          minPrice === maxPrice ? `${sign}${minPrice}` : `${sign}${minPrice} to ${sign}${maxPrice}`;
      } else {
        resultCopy.priceRange = '-';
      }
      resultCopy.color = resultCopy.defaultColor as string;

      return resultCopy;
    });

    return newAr;
  };

  const treatmentOptionsFlattened =
    treatmentData?.treatments?.reduce(
      (rows: FlattenTreatmentOption[], treatment) =>
        rows.concat(
          treatment?.options
            ?.filter((option) => option != undefined)
            .map((option: TreatmentOption) => {
              const flattenedOption: FlattenTreatmentOption = {
                ...option,
                treatmentId: treatment.id,
                treatmentName: treatment.name,
                treatmentTagName: treatment.tag?.name ?? 'None',
                treatmentActive: treatment.active ?? false,
              };
              return flattenedOption;
            }) ?? [],
        ),
      [],
    ) ?? [];

  return {
    getFormattedDataForTreatment,
    treatmentData: treatmentData?.treatments,
    treatmentListLoading,
    treatmentOptionsFlattened,
  };
};
