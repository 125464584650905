import {
  composeRestyleFunctions,
  createText,
  layout,
  LayoutProps,
  spacing,
  TextProps,
  useRestyle,
} from '@shopify/restyle';
import { Theme } from './theme';
import { enLocalKeys } from '../../../features/Localisations/locales/en';
import {
  TranslationProps,
  useTranslation,
} from '../../../features/Localisations/hooks/useTranslation';
import React, { ReactNode } from 'react';
import { TextProps as NativeTextprops } from 'react-native';
import { AutumnPressable } from './AutumnPressable';
import { AutumnIcon } from './AutumnIcon';
import { faClipboard } from '@fortawesome/pro-regular-svg-icons/faClipboard';
import { AutumnBox } from './AutumnBox';
import * as Clipboard from 'expo-clipboard';

const restyleFunctions = composeRestyleFunctions<Theme, TextProps<Theme> & LayoutProps<Theme>>([
  spacing,
  layout,
]);

export const AutumnTextBase = createText<Theme>();

export type AutumnTextProps = {
  children?: ReactNode;
  showCopyShortcut?: boolean;
} & TextProps<Theme> &
  LayoutProps<Theme> &
  Pick<NativeTextprops, 'numberOfLines'>;

export function AutumnText({
  children,
  numberOfLines,
  showCopyShortcut,
  ...rest
}: AutumnTextProps) {
  const props = useRestyle(restyleFunctions, rest);

  const onCopyPressed = () => {
    if (children) {
      Clipboard.setStringAsync(children?.toString()).catch((e) => null);
    }
  };

  return (
    <AutumnTextBase numberOfLines={numberOfLines} {...props}>
      {children}

      {showCopyShortcut && (
        <AutumnBox marginLeft={2}>
          <AutumnPressable onPress={onCopyPressed}>
            <AutumnIcon icon={faClipboard} color={'gray'} size={'sm'} />
          </AutumnPressable>
        </AutumnBox>
      )}
    </AutumnTextBase>
  );
}

export function AutumnLocaleText({
  localeKey,
  children,
  ...rest
}: {
  localeKey: keyof typeof enLocalKeys | TranslationProps;
  renderParameters?: Record<string, string | undefined | null>;
  plural?: number | boolean;
} & AutumnTextProps) {
  const { t } = useTranslation();

  const props = useRestyle(restyleFunctions, rest);

  return (
    <AutumnTextBase {...props}>
      {t(localeKey)}
      {children}
    </AutumnTextBase>
  );
}
