import dayjs, { Dayjs } from 'dayjs';
import { useState } from 'react';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { Platform } from 'react-native';
import { getCalendars } from 'expo-localization';
import * as timezones from 'countries-and-timezones';

dayjs.extend(utc);
dayjs.extend(timezone);

const usersTimezone =
  Platform.OS === 'web' ? dayjs.tz.guess() : (getCalendars()[0].timeZone as string);

export const useTimezones = () => {
  const [today] = useState(dayjs());

  const usersTime = () => {
    return today.tz(usersTimezone, true);
  };

  const usersTimezoneName = (formatted: boolean) => {
    if (formatted) {
      return usersTimezone.toString().split('/')[1];
    }

    return usersTimezone;
  };

  const convertDateToUser = (time: string | null | undefined): Dayjs => {
    return convertUTCDateToUserISO(time);
  };

  const convertDateToSpecificTimezone = (
    time: string | null | undefined,
    timezone: string,
  ): Dayjs => {
    try {
      const parsedTime = dayjs.utc(time).tz(timezone);

      return parsedTime;
    } catch (e) {
      return dayjs();
    }
  };

  const convertUTCDateToUserISO = (time: string | null | Dayjs | undefined): Dayjs => {
    try {
      const parsedTime = dayjs.utc(time).tz(usersTimezone);

      return parsedTime;
    } catch (e) {
      return dayjs();
    }
  };

  const formatDateToUser = (
    date: string | null | undefined,
    format?: string | null | undefined,
  ): string => {
    if (!date) return '';

    const convertedTime = convertUTCDateToUserISO(date);

    if (!convertedTime?.isValid()) return '';

    return convertedTime?.format(format ?? 'DD/MM/YYYY') ?? '';
  };

  const getTimezones: string[] = Object.keys(timezones.getAllTimezones());

  const getTimezonesForCountry = (countryCode: string) => {
    return timezones.getTimezonesForCountry(countryCode);
  };

  return {
    formatDateToUser,
    usersTimezone,
    usersTimezoneName,
    convertUTCDateToUserISO,
    convertDateToSpecificTimezone,
    convertDateToUser,
    today,
    usersTime,
    getTimezones,
    getTimezonesForCountry,
  };
};
