import { AutumnBox } from '../../../WebApp/Common/components/ui';
import { SignoutButton } from '../../../PatientApp/components/SignIn/components/SignoutButton';
import { PatientAppSignin } from '../../../PatientApp/components/SignIn/components/Login';
import React from 'react';
import {
  Config_Portal_Pages_Component,
  Config_Portal_Pages_Component_Type,
} from '../../../WebApp/__generatedtypes__/graphql';
import { ComponentButton } from '../elements/ComponentButton';
import { ComponentHStack } from '../elements/ComponentHStack';
import { ComponentFormFieldHorizontalOptions } from '../elements/ComponentFormFieldHorizontalOptions';
import { apply } from 'json-logic-js';
import { ComponentVStack } from '../elements/ComponentVStack';
import { ComponentFormFieldText } from '../elements/ComponentFormFieldText';
import { ComponentImage } from '../elements/ComponentImage';
import { ComponentText } from '../elements/ComponentText';
import { ComponentTitle } from '../elements/ComponentTitle';
import { ComponentMessages } from '../elements/ComponentMessages';
import { ComponentProgressBar } from '../elements/ComponentProgressBar';
import { ComponentFormFieldPayment } from '../elements/ComponentFormFieldPayment';
import { ComponentFormFieldPhone } from '../elements/ComponentFormFieldPhone';
import { ComponentFormFieldDate } from '../elements/ComponentFormFieldDate';

export const useComponentRender = () => {
  const renderComponent = (
    component: Config_Portal_Pages_Component,
    visibilityContext?: Record<string, string | number | boolean>,
  ) => {
    const { type } = component;

    if (component.visibilityExpression) {
      const shouldDisplay = apply(
        JSON.parse(component.visibilityExpression),
        visibilityContext ?? {},
      );

      if (!shouldDisplay) return null;
    }

    if (type === Config_Portal_Pages_Component_Type.VStack) {
      return <ComponentVStack component={component} key={component.key} />;
    }

    if (type === Config_Portal_Pages_Component_Type.HStack) {
      return <ComponentHStack key={component.key} component={component} />;
    }

    if (type === Config_Portal_Pages_Component_Type.Image) {
      return <ComponentImage component={component} key={component.key} />;
    }

    if (type === Config_Portal_Pages_Component_Type.Text) {
      return <ComponentText component={component} key={component.key} />;
    }

    if (type === Config_Portal_Pages_Component_Type.Title) {
      return <ComponentTitle component={component} key={component.key} />;
    }

    if (type === Config_Portal_Pages_Component_Type.Messages)
      return <ComponentMessages component={component} key={component.key} />;

    if (type === Config_Portal_Pages_Component_Type.Spacer)
      return <AutumnBox key={component.key} flex={1} />;

    if (type === Config_Portal_Pages_Component_Type.SignoutButton)
      return <SignoutButton key={component.key} />;

    if (type === Config_Portal_Pages_Component_Type.SignIn)
      return <PatientAppSignin key={component.key} />;

    if (type === Config_Portal_Pages_Component_Type.Button)
      return <ComponentButton component={component} key={component.key} />;

    if (type === Config_Portal_Pages_Component_Type.FormFieldHorizontalPicker)
      return <ComponentFormFieldHorizontalOptions component={component} key={component.key} />;

    if (type === Config_Portal_Pages_Component_Type.FormFieldText)
      return <ComponentFormFieldText component={component} key={component.key} />;

    if (type === Config_Portal_Pages_Component_Type.FormProgressBar)
      return <ComponentProgressBar component={component} key={component.key} />;

    if (type === Config_Portal_Pages_Component_Type.FormFieldPayment)
      return <ComponentFormFieldPayment component={component} key={component.key} />;

    if (type === Config_Portal_Pages_Component_Type.FormFieldPhone)
      return <ComponentFormFieldPhone component={component} key={component.key} />;

    if (type === Config_Portal_Pages_Component_Type.FormFieldDate)
      return <ComponentFormFieldDate component={component} key={component.key} />;

    return null;
  };

  return {
    renderComponent,
  };
};
