import React, { createRef, useEffect, useState } from 'react';
import { useSendMessage } from '../hooks/useSendMessage';

import { faArrowCircleUp, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import {
  AutumnButton,
  AutumnHStack,
  AutumnInput,
  AutumnText,
  AutumnVStack,
} from '../../../Common/components/ui';
import { Messaging_Channel } from '../../../__generatedtypes__/graphql';
import {
  AutumnHorizontalSelector,
  AutumnHorizontalSelectorIconPosition,
} from '../../../Common/components/ui/AutumnHorizontalSelector';
import { iconForChannel } from '../utils/messageUtils';
import { AutumnLocaleText } from '../../../Common/components/ui/AutumnText';
import { AutumnIcon } from '../../../Common/components/ui/AutumnIcon';
import { AutumnBoxAnimated } from '../../../Common/components/ui/AutumnBox';
import { FadeIn, FadeOut } from 'react-native-reanimated';
import { useHasPermission } from '../../../Common/hooks/useHasPermission';

export const ComposeMessage = ({
  threadId,
  brand,
  channels,
  isCustomerFacing,
  customerId,
  onType,
}: {
  threadId: string;
  brand: string;
  isCustomerFacing: boolean;
  channels: Messaging_Channel[];
  customerId?: string;
  onType: (typing: boolean) => void;
}) => {
  const hasPermission = useHasPermission('MessagesCrud');

  const [message, setMessage] = useState('');
  const [isFocused, setFocused] = useState(false);

  const inputRef = createRef<HTMLInputElement>();

  const { sendMessage, sending } = useSendMessage(threadId, customerId);

  const [channel, setChannel] = useState<Messaging_Channel | undefined>(
    channels?.length === 1 ? channels[0] : undefined, //make the user choose the channel if more than 1
  );

  const changeChannel = (newChannel: Messaging_Channel) => {
    setChannel(newChannel);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const setFocus = (focused: boolean) => {
    setFocused(focused);
    if (onType) onType(focused); //sets the thread to read (if it hasnt been already)
  };

  useEffect(() => {
    setMessage('');
  }, [threadId]);

  const send = async () => {
    if (message.length === 0 || !channel) return;

    await sendMessage(message, channel, brand);

    setMessage('');
  };

  const smsMessages = Math.ceil(message?.length / 160);

  if (!hasPermission) {
    return null;
  }

  return (
    <AutumnVStack mt={2} space={2} paddingHorizontal={'s'}>
      {!isCustomerFacing && (
        <AutumnHStack space={'s'} justifyContent={'flex-end'} alignItems={'center'}>
          {channel === Messaging_Channel.Sms && message?.length > 0 && (
            <AutumnText
              color={smsMessages > 1 ? 'red' : 'black'}
              fontSize={10}
            >{`Cost: ${smsMessages} SMS messages`}</AutumnText>
          )}

          {channels.length > 0 && !channel && isFocused && (
            <AutumnBoxAnimated entering={FadeIn.duration(200)} exiting={FadeOut.duration(200)}>
              <AutumnLocaleText fontWeight={'bold'} localeKey={'SelectAChannel'}>
                <AutumnIcon icon={faChevronRight} size={14} />
              </AutumnLocaleText>
            </AutumnBoxAnimated>
          )}

          {channels.length > 0 && (
            <AutumnHorizontalSelector
              options={channels.map((ch) => {
                return {
                  id: ch.toString(),
                  localeKey: { key: ch.toString() },
                  icon: { icon: iconForChannel(ch), size: 15 },
                  iconPosition: AutumnHorizontalSelectorIconPosition.Left,
                };
              })}
              selectedOption={channel}
              onChange={(selected) => changeChannel(selected as Messaging_Channel)}
            />
          )}
          {channels.length === 0 && (
            <AutumnLocaleText
              variant={isFocused ? 'bold' : undefined}
              localeKey={'NoChannelsAvailable'}
            />
          )}
        </AutumnHStack>
      )}

      <AutumnInput
        ref={inputRef}
        value={message}
        onChange={(text: string) => setMessage(text)}
        multiline={true}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        placeholderLocale={{
          key: 'TypeYourMessageTypeHere',
          renderParameters: { channel: channel ?? 'new' },
        }}
      >
        <AutumnButton
          variant={'ghost'}
          onPress={send}
          disabled={sending || !channel}
          leftIconProps={{
            color: 'black',
            size: 15,
          }}
          leftIcon={faArrowCircleUp}
          localeKey={'Send'}
          isLoading={sending}
        />
      </AutumnInput>
    </AutumnVStack>
  );
};
