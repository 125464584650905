import React from 'react';
import {
  Config_Portal_Pages_Component,
  Config_Portal_Pages_Component_HorizontalPickerProps,
} from '../../../WebApp/__generatedtypes__/graphql';
import {
  AutumnHorizontalSelector,
  AutumnHorizontalSelectorOption,
} from '../../../WebApp/Common/components/ui/AutumnHorizontalSelector';
import { useFlowFormField } from '../../Flows/hooks/useFlowFormField';
import { AutumnFieldWrapper } from '../../../WebApp/Common/components/ui/AutumnFieldWrapper';

export function ComponentFormFieldHorizontalOptions({
  component,
}: {
  component: Config_Portal_Pages_Component;
}) {
  const props = component.props as Config_Portal_Pages_Component_HorizontalPickerProps;

  const { onChange, value } = useFlowFormField<string>(props.id);

  const onChangeProxied = (value: string) => {
    onChange(value);
  };

  return (
    <AutumnFieldWrapper
      label={{ key: props.title }}
      {...(props?.explainer && { explainer: { key: props?.explainer } })}
    >
      <AutumnHorizontalSelector
        options={props.options.map(
          (option: string) => ({ label: option, id: option } as AutumnHorizontalSelectorOption),
        )}
        onChange={onChangeProxied}
        selectedOption={value}
      />
    </AutumnFieldWrapper>
  );
}
