import { createContext } from 'react';
import {
  Config,
  CustomField,
  ImageAngle,
  Location,
  Permission,
  Resource,
  Tag,
  Tenant,
  Treatment,
  User,
  UserPermissions,
  UserSettings,
} from '../../../__generatedtypes__/graphql';

export type ConfigContextType = {
  config: Config[];
  treatments: Treatment[];
  users: User[];
  locations: Location[];
  availableLocations: Location[];
  me?: User;
  tenant?: Tenant;
  settings?: UserSettings;
  permissions: Permission[];
  userPermissions: UserPermissions[];
  customFields: CustomField[];
  tags: Tag[];
  imageAngles: ImageAngle[];
  resources: Resource[];
  tenantId: string;
};

export const ConfigContext = createContext<ConfigContextType>({
  config: [],
  users: [],
  treatments: [],
  locations: [],
  availableLocations: [],
  permissions: [],
  userPermissions: [],
  customFields: [],
  tags: [],
  imageAngles: [],
  resources: [],
  tenantId: '',
});
