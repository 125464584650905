import { useEffect, useRef, useState } from 'react';
import { useTimezones } from '../../features/Time/hooks/useTimezones';
import dayjs from 'dayjs';

export const useTimeAgo = (date: string) => {
  const { convertUTCDateToUserISO } = useTimezones();

  const [time] = useState<dayjs.Dayjs>(convertUTCDateToUserISO(date) ?? dayjs());

  const calculateTimeAgo = () => {
    const now = dayjs();

    const minsDifference = now.diff(time, 'minutes');

    if (minsDifference < 1) return 'Now';

    if (minsDifference < 60) return `${minsDifference} mins ago`;

    const hoursAgo = now.diff(time, 'hours');

    if (hoursAgo < 24 && now.isSame(time, 'day')) return time?.format('h:mm a');

    return time?.format('h:mma DD/MM');
  };

  const timerRef = useRef<NodeJS.Timer | null>(null);

  const [timeAgo, setTimeAgo] = useState<string>(calculateTimeAgo() ?? '');

  const tickTimeAgo = () => {
    setTimeAgo(calculateTimeAgo());
  };

  useEffect(() => {
    if (timerRef.current) {
      clearInterval(timerRef.current as unknown as number);
    }

    setTimeAgo(calculateTimeAgo());

    timerRef.current = setInterval(tickTimeAgo, 60 * 1000);

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current as unknown as number);
      }
    };
  }, [date]);

  return { timeAgo };
};
