import { AutumnBox, AutumnBoxAnimated } from './AutumnBox';
import React, { useEffect, useRef, useState } from 'react';
import { LayoutRectangle, Platform, Pressable, useWindowDimensions, View } from 'react-native';
import { Portal } from '@gorhom/portal';
import { FadeIn, FadeOut } from 'react-native-reanimated';
import { AutumnScrollView } from './AutumnScrollView';

export function AutumnPopover({
  children,
  show,
  popoverComponent,
  showOverlay = true,
  onOutsidePress,
  popoverWidth,
  topOffset = 58, //this should be 0 if the component is not inside a tab
  disableAnimation = false, //you might need to do this if you need focus on load
}: {
  popoverComponent: React.ReactNode;
  children: React.ReactNode;
  popoverWidth?: number;
  showOverlay?: boolean;
  onOutsidePress?: () => void;
  show: boolean;
  topOffset?: number;
  disableAnimation?: boolean;
}) {
  const [layoutPosition, setLayoutPosition] = useState<LayoutRectangle | null>();
  const [popoverHeight, setPopoverHeight] = useState<number>(0);
  const [dynamicWidth, setPopoverWidth] = useState<number>(400);

  const boxRef = useRef<View>();

  const { width, height } = useWindowDimensions();

  const safeXLayoutPosition = layoutPosition?.x ?? 0;
  const safeYLayoutPosition = layoutPosition?.y ?? 0;

  const widthToUse = popoverWidth ?? dynamicWidth; //if the width isn't provided, use the whole element width

  const xPosition =
    safeXLayoutPosition + widthToUse >= width ? width - widthToUse : safeXLayoutPosition;

  const yPosition = //check if this popover will be offscreen
    safeYLayoutPosition + (layoutPosition?.height ?? 0) + popoverHeight > height
      ? height - popoverHeight - topOffset
      : safeYLayoutPosition + (layoutPosition?.height ?? 0) - topOffset;

  useEffect(() => {
    if (show && boxRef) {
      boxRef.current?.measure((_x, _y, width, height, pageX, pageY) => {
        setLayoutPosition({ x: pageX, y: pageY, width, height });
      });
    }
  }, [show]);

  return (
    <AutumnBox
      ref={boxRef}
      onLayout={(event) => {
        if (Platform.OS === 'web') {
          // @ts-expect-error this actually works fine
          event.nativeEvent.target.measure((_x, _y, width, height, pageX, pageY) => {
            setLayoutPosition({ x: pageX, y: pageY, width, height });
            setPopoverWidth(width);
          });
        } else {
          event.target.measure((_x, _y, width, height, pageX, pageY) => {
            setLayoutPosition({ x: pageX, y: pageY, width, height });
            setPopoverWidth(width);
          });
        }
      }}
    >
      {children}
      {show && onOutsidePress && (
        <Portal>
          <Pressable
            onPress={onOutsidePress}
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              left: 0,
              top: 0,
              ...(showOverlay && {
                backgroundColor: 'rgba(250,250,250,.3)',
              }),
            }}
          />
        </Portal>
      )}

      {show && (
        <Portal>
          <AutumnBoxAnimated
            onLayout={(event) => {
              if (popoverHeight > 0) return;

              if (Platform.OS === 'web') {
                // @ts-expect-error this actually works fine
                event.nativeEvent.target.measure((_x, _y, width, height) => {
                  setPopoverHeight(height);
                });
              } else {
                event.target.measure((_x, _y, width, height) => {
                  setPopoverHeight(height);
                });
              }
            }}
            entering={!disableAnimation ? FadeIn.duration(50) : undefined}
            exiting={!disableAnimation ? FadeOut.duration(25) : undefined}
            style={{
              position: 'absolute',
              left: xPosition,
              top: yPosition,
              backgroundColor: 'white',
              shadowColor: '#000',
              shadowOffset: {
                width: 0,
                height: 2,
              },
              shadowOpacity: 0.25,
              shadowRadius: 3.84,
              borderRadius: 5,
              elevation: 5,
              width: widthToUse,
              maxHeight: height - 60,
            }}
          >
            <AutumnScrollView>{popoverComponent}</AutumnScrollView>
          </AutumnBoxAnimated>
        </Portal>
      )}
    </AutumnBox>
  );
}
