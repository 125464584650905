import { atom } from 'recoil';
import { Auth_State, Current_User_State } from '../types/types';
import { recoilLocalStorageEffect } from '../../Storage/utils/recoilStorageEffect';

export const authStateAtom = atom<Auth_State>({
  key: 'authStateAtom',
  default: { users: {} },
  effects: [recoilLocalStorageEffect('users')],
});

export const currentUserStateAtom = atom<Current_User_State | null>({
  key: 'currentUserStateAtom',
  default: null,
  ...(__DEV__ && {
    // This is to allow easy dev without refreshing!
    effects: [recoilLocalStorageEffect('devCurrentUser')],
  }),
});
