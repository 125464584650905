import React, { memo, useState } from 'react';
import { MessageMarkdown } from './MessageMarkdown';
import { faPencil } from '@fortawesome/pro-solid-svg-icons/faPencil';

//import { DB4MessageComponent } from '../../../DB4/components/DB4MessageComponent';
//import { MessagePathologyRequest } from './MessagePathologyRequest';
import { useTimeAgo } from '../../../Common/hooks/useTimeAgo';
import { WebView } from '../../../Common/components/WebView';
import {
  AutumnBox,
  AutumnHStack,
  AutumnPressable,
  AutumnText,
  AutumnVStack,
} from '../../../Common/components/ui';
import { useConfig } from '../../Config/hooks/useConfig';
//import { useRouting } from '../../Tabs/hooks/useRouting';
import { AutumnIcon } from '../../../Common/components/ui/AutumnIcon';
import {
  Message_Action,
  Message_ContentType,
  Message_Sender,
  Messaging_Channel,
} from '../../../__generatedtypes__/graphql';
import { LinkThreadToCustomerModal } from './EditMessageModal';

export const Message = memo(
  ({
    senderId,
    senderImage,
    senderName,
    senderType,
    channel,
    threadId,
    messageId,
    messageContent,
    contentType,
    sentAt,
    actions,
    isCustomerFacing,
  }: {
    senderId: string;
    senderImage: React.ReactNode;
    senderName: string;
    senderType: Message_Sender;
    channel: Messaging_Channel;
    threadId: string;
    messageId: string;
    messageContent: string;
    contentType: Message_ContentType;
    sentAt: string;
    actions: Message_Action[] | undefined;
    isCustomerFacing: boolean; //aka being viewed by a customer outisde autumn
  }) => {
    const { me: user } = useConfig();

    const { timeAgo } = useTimeAgo(sentAt);

    const [editingMessage, setEditingMessage] = useState(false);

    const canEditMessage = senderId === user?.id && channel === Messaging_Channel.Portal;

    //determines how to render the appearance of this message
    const messageBelongsToMe =
      (!isCustomerFacing && senderType !== Message_Sender.Customer) ||
      (isCustomerFacing && senderType === Message_Sender.Customer);

    const isRightAligned = messageBelongsToMe;

    const messageOptions = () => {
      //TODO actions?
      return null;
    };

    return (
      <AutumnHStack
        alignSelf={!isRightAligned ? 'flex-start' : 'flex-end'}
        width={!isRightAligned ? '80%' : '85%'}
      >
        <AutumnBox marginHorizontal={'ms'}>{!isRightAligned && senderImage}</AutumnBox>
        <AutumnVStack space={1} flex={1} alignItems={!isRightAligned ? 'flex-start' : 'flex-end'}>
          <AutumnHStack space={2} padding={1}>
            <AutumnText variant={'small'} color="messagingBubbleHeader">
              {senderName} ({channel})
            </AutumnText>
          </AutumnHStack>

          <AutumnVStack
            space={2}
            bg={!isRightAligned ? 'messagingBubbleAlt' : 'messagingBubble'}
            px={contentType === Message_ContentType.Html ? 's' : 'ms'}
            py={'s'}
            borderRadius={15}
          >
            {contentType === Message_ContentType.Text && (
              <MessageMarkdown content={messageContent} />
            )}

            {contentType === Message_ContentType.Html && (
              <AutumnBox width={400} height={100}>
                <WebView
                  html={messageContent}
                  webViewStyling={{
                    borderWidth: 0,
                    borderRadius: 10,
                    backgroundColor: 'rgb(252,252,252)',
                  }}
                />
              </AutumnBox>
            )}

            {editingMessage && (
              <LinkThreadToCustomerModal
                messageId={messageId}
                initialMessageContent={messageContent}
                onClose={() => setEditingMessage(false)}
              />
            )}

            {!editingMessage && messageOptions()}
          </AutumnVStack>

          <AutumnHStack space={'s'} alignItems={'center'}>
            <AutumnText fontWeight={'normal'} variant={'small'} color={'messagingBubbleFooter'}>
              {timeAgo}
            </AutumnText>
            {canEditMessage && (
              <AutumnPressable ml={2} onPress={() => setEditingMessage(true)}>
                <AutumnIcon icon={faPencil} color={'gray'} size={12} />
              </AutumnPressable>
            )}
          </AutumnHStack>
        </AutumnVStack>

        <AutumnBox marginHorizontal={'ms'}>{isRightAligned && senderImage}</AutumnBox>
      </AutumnHStack>
    );
  },
);
