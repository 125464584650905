import { AutumnLocaleText, AutumnText } from './AutumnText';
import React from 'react';
import { enLocalKeys } from '../../../features/Localisations/locales/en';
import {
  TranslationProps,
  useTranslation,
} from '../../../features/Localisations/hooks/useTranslation';
import { AutumnBox } from './AutumnBox';
import { AutumnVStack } from './AutumnVStack';
import { AutumnHStack } from './AutumnHStack';
import { AutumnIcon } from './AutumnIcon';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

export function AutumnAlert({
  message,
  title,
  type,
  icon,
}: {
  message: keyof typeof enLocalKeys | string | TranslationProps;
  title?: keyof typeof enLocalKeys | TranslationProps;
  type: 'info' | 'warning' | 'error' | 'good';
  icon?: IconDefinition;
}) {
  const { t } = useTranslation();

  const fontColour = ['good', 'error'].includes(type) ? 'white' : 'black';

  return (
    <AutumnBox
      p={3}
      borderRadius={4}
      {...(type === 'info' && {
        bg: 'gray',
      })}
      {...(type === 'error' && {
        bg: 'red',
      })}
      {...(type === 'warning' && {
        bg: 'yellow',
      })}
      {...(type === 'good' && {
        bg: 'green',
      })}
    >
      <AutumnHStack alignItems={'center'} space={'s'}>
        {icon && <AutumnIcon icon={icon} size={18} color={fontColour} />}
        <AutumnVStack flex={1}>
          {title && <AutumnLocaleText localeKey={title} color={fontColour} fontWeight={'bold'} />}
          <AutumnText color={fontColour}>{t(message)}</AutumnText>
        </AutumnVStack>
      </AutumnHStack>
    </AutumnBox>
  );
}
