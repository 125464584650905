import { AutumnHStack } from './AutumnHStack';
import React, { useEffect, useState } from 'react';
import { enLocalKeys } from '../../../features/Localisations/locales/en';
import { AutumnLocaleText } from './AutumnText';
import { AutumnPressable } from './AutumnPressable';
import { AutumnIcon, AutumnIconsProps } from './AutumnIcon';
import { AutumnVStack } from './AutumnVStack';
import Animated, {
  interpolateColor,
  useSharedValue,
  useAnimatedStyle,
  interpolate,
  withTiming,
} from 'react-native-reanimated';
import { TranslationProps } from '../../../features/Localisations/hooks/useTranslation';
import { useThemeColor } from '../../hooks/useThemeColor';
import { AutumnFieldWrapper, AutumnFieldWrapperProps } from './AutumnFieldWrapper';

export type AutumnHorizontalSelectorProps = {
  options: AutumnHorizontalSelectorOption[];
  selectedOption?: string;
  onChange: (selectedValue: string | undefined) => void;
  /**
   * When enabled, tapping an already selected option will call onChange(undefined)
   */
  allowDeselect?: boolean;
  disabled?: boolean;
};

export enum AutumnHorizontalSelectorIconPosition {
  Left,
  Right,
  Top,
}
export type AutumnHorizontalSelectorOption = {
  id: string;
  label?: keyof typeof enLocalKeys | TranslationProps;
  localeKey?: keyof typeof enLocalKeys | TranslationProps;
  descriptionLines?: TranslationProps[];
  icon?: AutumnIconsProps;
  iconPosition?: AutumnHorizontalSelectorIconPosition;
};

export function AutumnHorizontalSelectorField({
  ...rest
}: AutumnHorizontalSelectorProps & AutumnFieldWrapperProps) {
  return (
    <AutumnFieldWrapper {...rest}>
      <AutumnVStack style={{ marginTop: -2 }}>
        <AutumnHorizontalSelector isField={true} {...rest} />
      </AutumnVStack>
    </AutumnFieldWrapper>
  );
}

export function AutumnHorizontalSelector({
  options,
  selectedOption,
  allowDeselect,
  disabled = false,
  isField = false,
  onChange,
}: { isField?: boolean } & AutumnHorizontalSelectorProps) {
  const changedSelection = (selectionId: string) => {
    onChange(selectionId === selectedOption && allowDeselect ? undefined : selectionId);
  };

  return (
    <AutumnHStack>
      {options.map((option, index) => (
        <HorizontalSelectionItem
          key={option.id + index.toString()}
          option={option}
          onChange={changedSelection}
          isSelected={option.id === selectedOption}
          disabled={disabled}
          isFirst={index === 0}
          isLast={index === options.length - 1}
          verticalPadding={isField ? 6.5 : undefined}
        />
      ))}
    </AutumnHStack>
  );
}

const HorizontalSelectionItem = ({
  option,
  onChange,
  isSelected,
  disabled,
  isFirst,
  isLast,
  verticalPadding = 10,
}: {
  option: AutumnHorizontalSelectorOption;
  onChange: (selectedValue: string | undefined) => void;
  isSelected: boolean;
  disabled: boolean;
  isFirst: boolean;
  isLast: boolean;
  verticalPadding?: number;
}) => {
  option.localeKey = option.localeKey ?? option.label;

  const [hovering, setHovering] = useState(false);

  const { colorFromTheme } = useThemeColor();
  const buttonColor = colorFromTheme('buttonPrimary');

  const progress = useSharedValue(isSelected ? 1 : 0);

  const animatedStyle = useAnimatedStyle(() => {
    return {
      borderColor: interpolateColor(progress.value, [0, 1], ['lightgrey', buttonColor]),
      backgroundColor: interpolateColor(progress.value, [0, 1], ['white', buttonColor]),
      opacity: interpolate(progress.value, [0, 1], [hovering ? 1 : 0.7, 1]),
      borderWidth: isSelected ? 1.5 : 1,
      borderTopLeftRadius: isFirst ? 5 : 0,
      borderBottomLeftRadius: isFirst ? 5 : 0,
      borderTopRightRadius: isLast ? 5 : 0,
      borderBottomRightRadius: isLast ? 5 : 0,
    };
  });

  useEffect(() => {
    progress.value = withTiming(isSelected ? 1 : 0, {
      duration: 400,
    });
  }, [isSelected]);

  return (
    <Animated.View style={animatedStyle}>
      <AutumnPressable
        disabled={disabled}
        onPress={() => onChange(option.id)}
        onHoverIn={() => setHovering(true)}
        onHoverOut={() => setHovering(false)}
        style={{ padding: 10, paddingVertical: verticalPadding }}
      >
        <AutumnHStack space={2}>
          {option.icon &&
            (option.iconPosition === undefined ||
              option.iconPosition === AutumnHorizontalSelectorIconPosition.Left) && (
              <AutumnIcon {...option.icon} color={isSelected ? 'white' : option.icon.color} />
            )}

          <AutumnVStack space={2} justifyContent={'center'} alignItems={'center'}>
            {option.icon && option.iconPosition === AutumnHorizontalSelectorIconPosition.Top && (
              <AutumnIcon {...option.icon} color={isSelected ? 'white' : option.icon.color} />
            )}

            {option.localeKey && (
              <AutumnLocaleText
                localeKey={option.localeKey}
                color={isSelected ? 'white' : 'black'}
                fontWeight={isSelected ? 'bold' : '400'}
              />
            )}

            {option.descriptionLines && (
              <AutumnVStack space={1}>
                {option.descriptionLines.map((line, index) => (
                  <AutumnLocaleText key={'line' + index.toString()} localeKey={line} />
                ))}
              </AutumnVStack>
            )}
          </AutumnVStack>
          {option.icon && option.iconPosition === AutumnHorizontalSelectorIconPosition.Right && (
            <AutumnIcon {...option.icon} color={isSelected ? 'white' : option.icon.color} />
          )}
        </AutumnHStack>
      </AutumnPressable>
    </Animated.View>
  );
};
