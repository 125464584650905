import { DocumentNode } from '@apollo/client';
import { useState } from 'react';
import { graphql } from '../../../__generatedtypes__';
import { Messaging_Channel } from '../../../__generatedtypes__/graphql';
import { useAutumnMutation } from '../../GraphQL/hooks/useAutumnMutation';
import { uuid } from '../../../Common/utils/uuid';
import { CUSTOMER_MESSAGES_QUERY } from './useCustomerMessages';

export const useSendMessage = (threadId: string, customerId?: string) => {
  const [sending, setSending] = useState(false);

  const [sendMessageMutation] = useAutumnMutation(
    graphql(`
      mutation newMessageSend(
        $messageId: String!
        $threadId: String!
        $message: String!
        $channel: Messaging_Channel!
        $brand: String!
        $customerId: String
      ) {
        messagesSend(
          messageId: $messageId
          threadID: $threadId
          message: $message
          channel: $channel
          brand: $brand
          customerID: $customerId
        ) {
          __typename
          thread {
            ...InboxThreadFragment
          }
          message {
            id
          }
        }
      }
    `) as DocumentNode,
  );

  const sendMessage = async (message: string, channel: Messaging_Channel, brand: string) => {
    setSending(true);

    await sendMessageMutation({
      variables: {
        messageId: uuid(),
        threadId,
        channel,
        message,
        brand,
        customerId,
      },
      refetchQueries: [CUSTOMER_MESSAGES_QUERY],
    });

    setSending(false);
  };

  return {
    sendMessage,
    sending,
    setSending,
  };
};
