import { useRecoilValue } from 'recoil';
import { devState } from '../../features/DevSwitcher/state/devState';

export const useAPIEndpoint = () => {
  const { localAPI } = useRecoilValue(devState);

  const baseURL = localAPI ? `http://localhost:3000` : 'https://clinic-api-dev.autumn.clinic';

  return { baseURL };
};
