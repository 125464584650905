import { AutumnButton } from '../../../../WebApp/Common/components/ui';
import React from 'react';
import { usePatientAppAuth } from '../../Auth/hooks/usePatientAppAuth';
import { useNavigate } from 'react-router';

export function SignoutButton() {
  const { setSignedOut, signedIn } = usePatientAppAuth();

  const navigate = useNavigate();

  const onPress = () => {
    setSignedOut(false);

    navigate('/signin');
  };

  if (!signedIn) return null;

  return <AutumnButton onPress={onPress} localeKey={'Signout'} />;
}
