import React, { createRef, useRef, useState } from 'react';
import { AutumnLocaleText, AutumnText } from './AutumnText';
import { AutumnVStack } from './AutumnVStack';
import { AutumnHStack } from './AutumnHStack';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {
  NativeSyntheticEvent,
  Pressable,
  TextInputFocusEventData,
  TextInputKeyPressEventData,
  View,
} from 'react-native';
import { AutumnPopover } from './AutumnPopover';
import { AutumnHorizontalSelector } from './AutumnHorizontalSelector';
import { AutumnFieldWrapper, AutumnFieldWrapperProps } from './AutumnFieldWrapper';
import { AutumnNumberInputElement } from './AutumnMoneyInput';
import { AutumnButton } from './AutumnButton';
import { AutumnBox } from './AutumnBox';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { usePlatformInformation } from '../../utils/usePlatformInformation';
import { faClock } from '@fortawesome/pro-duotone-svg-icons/faClock';
import { useTimezones } from '../../../features/Time/hooks/useTimezones';
import { AutumnDropdown } from './AutumnDropdown';

dayjs.extend(customParseFormat);

export function AutumnTimePicker({
  value,
  onChange,
  timezones,
  disabled = false,
  ...rest
}: {
  value: string | Date;
  disabled?: boolean;
  timezones?: string[];
  onChange: (value: string) => void;
} & AutumnFieldWrapperProps) {
  const { isWeb } = usePlatformInformation();
  const { usersTimezone } = useTimezones();

  timezones = [...new Set(timezones)];

  const [isOpen, setIsOpen] = React.useState(false);

  const [entryTimezone, setTimezone] = useState<string>(
    timezones && timezones.length > 0 ? timezones[0] : usersTimezone,
  );

  const [typedHour, setTypedHour] = React.useState<number | undefined>(
    Number(dayjs(value).tz(entryTimezone).format('hh')),
  );
  const [typedMinute, setTypedMinute] = React.useState<number | undefined>(
    Number(dayjs(value).format('mm')),
  );
  const [lastTypedValue, setLastTypedValue] = React.useState<number | undefined>(undefined);

  const hourInputRef = useRef<HTMLInputElement>();
  const minuteInputRef = useRef<HTMLInputElement>();
  const doneButtonRef = createRef<View>();

  const typedInHours = (newHours: number) => {
    setTypedHour(newHours);
    setLastTypedValue(newHours);

    if (lastTypedValue === 0 && newHours === 0) {
      updateTime('12', minutes, 'AM');
    }

    if (newHours > 2) {
      minuteInputRef?.current?.focus();
    }
  };

  const blurredHours = (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
    const inputValue = e.nativeEvent.text;

    setLastTypedValue(undefined);

    updateTime(inputValue, minutes, period);
  };

  const onHoursKeyPress = (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
    if (e.nativeEvent.key == 'Enter' || e.nativeEvent.key == 'Tab') {
      minuteInputRef?.current?.focus();
    }
    if (e.nativeEvent.key == 'ArrowUp') {
      updateTime((Number(hours) + 1).toString(), minutes, period);
      e.stopPropagation();
      e.preventDefault();
    }
    if (e.nativeEvent.key == 'ArrowDown') {
      updateTime((Number(hours) - 1).toString(), minutes, period);
      e.stopPropagation();
      e.preventDefault();
    }
    handleAnyKeyPress(e);
  };

  const typedInMinutes = (newMinutes: number) => {
    setTypedMinute(newMinutes);
    setLastTypedValue(newMinutes);

    if (lastTypedValue === 0) {
      doneButtonRef.current?.focus();
    }

    if (newMinutes?.toString().length > 1) {
      doneButtonRef.current?.focus();
    }
  };

  const blurredMinutes = (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
    const inputValue = e.nativeEvent.text;

    setLastTypedValue(undefined);

    updateTime(hours, inputValue, period);
  };

  const onMinutesKeyPress = (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
    if (e.nativeEvent.key == 'Enter') {
      doneButtonRef.current?.focus();
    }

    if (e.nativeEvent.key == 'ArrowUp') {
      updateTime(hours, (Math.ceil(Number(minutes) / 5) * 5 + 5).toString(), period);
      e.stopPropagation();
      e.preventDefault();
    }
    if (e.nativeEvent.key == 'ArrowDown') {
      updateTime(hours, (Math.ceil(Number(minutes) / 5) * 5 - 5).toString(), period);
      e.stopPropagation();
      e.preventDefault();
    }

    handleAnyKeyPress(e);
  };

  const handleAnyKeyPress = (
    e: NativeSyntheticEvent<KeyboardEvent> | NativeSyntheticEvent<TextInputKeyPressEventData>,
  ) => {
    //pressing a button in any field should do this
    if (e.nativeEvent.key == 'a') {
      updateTime(hours, minutes, 'AM');
      e.stopPropagation();
      e.preventDefault();
    }
    if (e.nativeEvent.key == 'p') {
      updateTime(hours, minutes, 'PM');
      e.stopPropagation();
      e.preventDefault();
    }
  };

  const selectedPeriod = (newPeriod: string) => {
    updateTime(hours, minutes, newPeriod ?? 'AM');

    if (newPeriod === period && !invalid) {
      //pressed the already selected option
      setIsOpen(false);
    }
  };

  const date = dayjs(value).tz(entryTimezone);

  const invalid = !value || value === '';

  const minutes = date.format('mm');
  const hours = date.format('hh');
  const period = date.format('A');

  const periodOptions = ['AM', 'PM'];

  const updateTime = (h: string, m: string, p: string, timezone?: string) => {
    let hoursNumber: number = +h;

    if (!timezone) {
      timezone = entryTimezone;
    } else {
      setTimezone(timezone);
    }

    if (p === 'PM' && hoursNumber < 12) {
      //aka 1pm becomes 13, but 12pm stays 12
      hoursNumber = hoursNumber + 12;
    } else if (p === 'AM' && hoursNumber === 12) {
      //12am becomes 0;
      hoursNumber = 0;
    }

    const newDate = dayjs()
      .tz(timezone, false)
      .hour(hoursNumber)
      .minute(+m)
      .second(0)
      .millisecond(0);

    setTypedHour(Number(newDate.format('hh')));
    setTypedMinute(Number(m));

    if (newDate.isValid()) {
      onChange(newDate.format('YYYY-MM-DDTHH:mm:ssZ'));
    }
  };

  const pressedDone = () => {
    if (invalid) {
      onChange(dayjs().format('YYYY-MM-DDTHH:mm:ssZ'));
    }
    setIsOpen(false);
  };

  const doOpen = () => {
    setIsOpen(true);
    if (timezones && timezones.length > 0 && !timezones.includes(entryTimezone)) {
      setTimezone(timezones[0]);
    }
    setTypedHour(Number(dayjs(value).tz(entryTimezone).format('hh')));
    setTypedMinute(Number(dayjs(value).format('mm')));
  };

  const timeEditor = () => {
    return (
      <AutumnVStack space={2}>
        <AutumnHStack
          space={2}
          borderRadius={5}
          borderColor={'gray.50'}
          borderWidth={1}
          padding={'s'}
          alignItems={'center'}
        >
          <AutumnNumberInputElement
            ref={hourInputRef}
            onKeyPress={onHoursKeyPress}
            value={typedHour ?? 0}
            maxValue={23}
            minValue={0}
            precision={0}
            prefix={typedHour && typedHour < 10 ? '0' : ''}
            fontSize={18}
            width={25}
            textAlign={'center'}
            selectTextOnFocus={true}
            autoFocus={isWeb}
            style={{
              backgroundColor:
                document.activeElement === hourInputRef.current ? 'rgb(240,240,245)' : undefined,
              paddingVertical: 5,
            }}
            onBlur={(e) => blurredHours(e)}
            onChangeValue={typedInHours}
          />

          <AutumnText>:</AutumnText>
          <AutumnNumberInputElement
            value={typedMinute ?? 0}
            ref={minuteInputRef}
            onKeyPress={onMinutesKeyPress}
            maxValue={59}
            minValue={0}
            precision={0}
            prefix={typedMinute === 0 ? '0' : typedMinute && typedMinute < 10 ? '0' : ''}
            fontSize={18}
            width={25}
            textAlign={'center'}
            selectTextOnFocus={true}
            style={{
              backgroundColor:
                document.activeElement === minuteInputRef.current ? 'rgb(240,240,245)' : undefined,
              paddingVertical: 5,
            }}
            onBlur={blurredMinutes}
            onChangeValue={typedInMinutes}
          />
          <AutumnText> </AutumnText>
          <AutumnHorizontalSelector
            selectedOption={period}
            options={periodOptions.map((p) => {
              return { id: p, localeKey: { key: p } };
            })}
            isField={true}
            onChange={(newPeriod: string) => selectedPeriod(newPeriod)}
          />
          <AutumnBox flex={1}></AutumnBox>
          <AutumnButton
            ref={doneButtonRef}
            onPress={pressedDone}
            onKeyPress={handleAnyKeyPress}
            localeKey={'Done'}
            leftIcon={faCheck}
            variant={'link'}
          />
        </AutumnHStack>

        {timezones && timezones.length > 1 && (
          <AutumnVStack marginHorizontal={'s'} marginBottom={'s'}>
            {timezones.length === 2 && (
              <AutumnHorizontalSelector
                selectedOption={entryTimezone}
                onChange={(newTimezone: string) => updateTime(hours, minutes, period, newTimezone)}
                options={timezones?.map((tz) => {
                  return { id: tz, localeKey: { key: tz } };
                })}
              />
            )}
            {timezones.length > 2 && (
              <AutumnDropdown
                selectedOption={entryTimezone}
                onChange={(newTimezone: string) => updateTime(hours, minutes, period, newTimezone)}
                options={timezones?.map((tz) => {
                  return { id: tz, localeKey: { key: tz } };
                })}
              />
            )}
          </AutumnVStack>
        )}
      </AutumnVStack>
    );
  };

  const timeDisplay = () => {
    return (
      <AutumnHStack space={1} alignItems={'center'}>
        <AutumnButton
          padding={0}
          disabled={disabled}
          onPress={doOpen}
          leftIcon={faClock}
          variant={'inLineLink'}
          localeKey={{
            key: invalid ? 'Invalid' : 'TimePickerDisplay',
            renderParameters: {
              hours,
              minutes,
              period,
            },
          }}
        />
        {entryTimezone !== usersTimezone && (
          <AutumnLocaleText
            localeKey={{
              key: 'YourTime',
              renderParameters: { userTime: date.tz(usersTimezone).format('hh:mm A') },
            }}
          />
        )}
      </AutumnHStack>
    );
  };

  return (
    <AutumnPopover
      onOutsidePress={() => setIsOpen(false)}
      disableAnimation={true}
      popoverComponent={
        <AutumnVStack space={'s'} bg="white" borderRadius={5}>
          {isOpen && timeEditor()}
        </AutumnVStack>
      }
      popoverWidth={300}
      show={isOpen}
    >
      <Pressable disabled={disabled} onPress={doOpen}>
        <AutumnFieldWrapper hasFocus={isOpen} {...rest}>
          {timeDisplay()}
        </AutumnFieldWrapper>
      </Pressable>
    </AutumnPopover>
  );
}
