import React from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { AutumnBox } from './AutumnBox';
import { Icon } from '../Icon';
import { useThemeColor } from '../../hooks/useThemeColor';
import theme from './theme';

export type AutumnIconSize = 'sm' | 'md' | 'lg' | number;

export type AutumnIconsProps = {
  color?: keyof typeof theme.colors;
  backgroundColor?: keyof typeof theme.colors;
  backgroundSizeMultiplier?: number;
  iconTopOffset?: number; //sometimes an icon doesn't look centred, when it actually is. Use this to move it around
  icon: IconDefinition;
  size: AutumnIconSize;
};

export function AutumnIcon({
  icon,
  size,
  color = 'black',
  backgroundColor = undefined,
  backgroundSizeMultiplier = 2,
  iconTopOffset = 0,
}: AutumnIconsProps) {
  const { colorFromTheme } = useThemeColor();

  const translatedSize = () => {
    if (typeof size === 'number') return size;

    if (size === 'sm') return 10;

    if (size === 'md') return 15;

    return 20;
  };

  if (backgroundColor) {
    return (
      <AutumnBox
        width={translatedSize() * backgroundSizeMultiplier - 1}
        height={translatedSize() * backgroundSizeMultiplier - 1}
        borderRadius={translatedSize() * backgroundSizeMultiplier - 1}
        justifyContent={'center'}
        alignItems={'center'}
        style={{ backgroundColor: colorFromTheme(backgroundColor) }}
      >
        <AutumnBox style={{ marginTop: iconTopOffset }}>
          <Icon icon={icon} color={colorFromTheme(color)} size={translatedSize()} />
        </AutumnBox>
      </AutumnBox>
    );
  }

  return <Icon icon={icon} color={color} size={translatedSize()} />;
}
