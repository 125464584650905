import { AutumnScrollView } from './AutumnScrollView';
import React, { ReactNode } from 'react';
import { AutumnVStack } from './AutumnVStack';
import { KeyboardAvoidingView } from 'react-native';

export function AutumnScrollableLayout({ children }: { children: ReactNode }) {
  return (
    <KeyboardAvoidingView keyboardVerticalOffset={100} style={{ flex: 1 }} behavior="padding">
      <AutumnScrollView flex={1}>
        <AutumnVStack mx={'m'} space={'m'}>
          {children}
        </AutumnVStack>
      </AutumnScrollView>
    </KeyboardAvoidingView>
  );
}
