import React, { memo } from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import AdaptiveCard from 'react-adaptivecards';

export const AutumnCard = memo(
  ({ card }: { card?: string | null | undefined }) => {
    if (!card) return null;

    return <AdaptiveCard payload={card}></AdaptiveCard>;
  },
  (oldsProps, newProps) => oldsProps.card === newProps.card,
);
