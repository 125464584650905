import { FlowContextStateType } from '../types/types';
import React from 'react';
import Ajv from 'ajv';
import { useFlowSubmit } from './useFlowSubmit';

export const useFlowManager = (
  state: FlowContextStateType | undefined,
  setState: React.Dispatch<React.SetStateAction<FlowContextStateType>>,
) => {
  const { submitFlow } = useFlowSubmit(state);

  const getNextStep = () => {
    if (!state) throw new Error('State is null');

    const currentStep = state.currentStep;

    const isLastStep = state.flow.steps.length;

    const newStep = currentStep + 1;

    if (newStep < isLastStep) {
      return newStep;
    }

    return currentStep;
  };

  const backStep = () => {
    if (!state) return;

    setState((existingState) => {
      let currentStep = existingState.currentStep;

      if (currentStep > 0) {
        currentStep = currentStep - 1;
      }

      return {
        ...existingState,
        currentStep,
      } as FlowContextStateType;
    });
  };

  const nextStep = async (submit: boolean) => {
    if (!state) return;

    const response = await Promise.all(
      Object.keys(state.stepRefs).map((key) => {
        return state.stepRefs[key]();
      }),
    );

    if (response.includes(false)) {
      return;
    }

    const { validation } = state.flow.steps[state.currentStep];

    let formFieldsValidation = true;
    let formFieldsValidationErrors: Record<string, string> = {};

    if (validation) {
      const ajv = new Ajv({ allErrors: true });
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('ajv-errors')(ajv);

      const validationSchema = ajv.compile(JSON.parse(validation));

      formFieldsValidation = validationSchema(state.formFields);

      formFieldsValidationErrors = Object.fromEntries(
        (validationSchema?.errors ?? []).map((error) => {
          const idPath = error.instancePath.substr(1).split('/');
          return [idPath, error.message];
        }),
      ) as Record<string, string>;
    }

    let currentStep = state.currentStep;

    if (formFieldsValidation) {
      const isLastStep = state.flow.steps.length;

      const newStep = currentStep + 1;

      if (newStep < isLastStep && !submit) {
        currentStep = newStep;
      }
    }

    setState((existingState) => {
      return {
        ...existingState,
        currentStep,
        formFieldsValidation,
        formFieldsValidationErrors,
        loading: submit,
        error: undefined,
      } as FlowContextStateType;
    });

    if (submit) {
      const response = await submitFlow();

      if (!response.ok) {
        setState((existingState) => {
          return {
            ...existingState,
            loading: false,
            error: response.error ?? 'A error occurred. Please try again',
          } as FlowContextStateType;
        });

        return;
      }

      setState((existingState) => {
        return {
          ...existingState,
          completed: true,
          loading: false,
          error: undefined,
          currentStep: getNextStep(),
        } as FlowContextStateType;
      });
    }
  };

  return { nextStep, backStep };
};
