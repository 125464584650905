import { usePatientAppAuth } from '../hooks/usePatientAppAuth';
import { useEffect } from 'react';
// @ts-expect-error NO TS def
import { isJwtExpired } from 'jwt-check-expiration';

export const PatientAppAuthProvider = ({ children }: { children: React.ReactNode }) => {
  const { token, setSignedOut } = usePatientAppAuth();

  useEffect(() => {
    if (token) {
      const expired = isJwtExpired(token);

      if (expired) {
        setSignedOut(true);
      }
    }
  }, []);

  return children;
};
