import { useElements, useStripe } from '@stripe/react-stripe-js';
import { usePaymentGatewayContext } from './usePaymentGatewayContext';
import { usePaymentGatewayTokenCreate } from './usePaymentGatewayToken';
import { Ref, useImperativeHandle } from 'react';
import { PaymentGatewayRef, PaymentGatewaySaveToken } from '../components/PaymentGateway';

export const usePaymentGatewayStripeComplete = (ref: Ref<PaymentGatewayRef>) => {
  const stripe = useStripe();
  const elements = useElements();

  const { customerId, cardGateway } = usePaymentGatewayContext();

  const { createToken } = usePaymentGatewayTokenCreate();

  useImperativeHandle(ref, () => {
    return {
      saveToken: async (): Promise<PaymentGatewaySaveToken> => {
        if (!stripe || !elements) {
          return { ok: false };
        }

        const result = await stripe.confirmSetup({
          elements,
          redirect: 'if_required',
          confirmParams: {
            return_url: 'https://example.com/order/123/complete',
          },
        });

        if (!result?.setupIntent?.payment_method) return { ok: false };

        const token = await createToken(
          customerId,
          cardGateway.id,
          result?.setupIntent?.payment_method as string,
        );

        return { ok: true, token: token?.card?.id };
      },
    };
  });

  return {};
};
