import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { useAutumnGraphQLClient } from '../hooks/useAutumnGraphQLClient';
import { AutumnLoaderFullscreen } from '../../../Common/components/ui/AutumnLoaderFullscreen';
import { useAuthState } from '../../Login/hooks/useAuthState';

export function GraphQLProvider({ children }: { children: React.ReactNode }) {
  const { getAccessToken } = useAuthState();

  const { client, cacheRestoring } = useAutumnGraphQLClient(true, getAccessToken);

  if (cacheRestoring || !client) return <AutumnLoaderFullscreen />;

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

export function GraphQLProviderUnauthenticated({ children }: { children: React.ReactNode }) {
  const { client, cacheRestoring } = useAutumnGraphQLClient(true, undefined);

  if (cacheRestoring || !client) return <AutumnLoaderFullscreen />;

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
