import { FragmentType, graphql } from '../../../__generatedtypes__';

export type SimpleCustomerFragmentType =
  | FragmentType<typeof SimpleCustomerFragment>
  | undefined
  | null;

export const SimpleCustomerFragment = graphql(`
  fragment SimpleCustomerFragment on Customer {
    __typename
    name
    dob
    notes
    email
    given
    surname
    mobile
    id
    imageKey
    preferredName
    pronouns
  }
`);

export const fullCustomerFragment = graphql(`
  fragment fullCustomerFragment on Customer {
    __typename
    id
    tenant
    name
    createdAt
    active
    lastUpdated
    given
    brand
    surname
    notes
    email
    mobile
    dob
    sex
    preferredName
    pronouns
    gender
    hearaboutus
    phone
    medicalhistory
    allergies
    medications
    address
    street
    suburb
    state
    labels
    country
    postcode
    canLogIn
    subscribedEmail
    subscribedSMS
    imageKey
    concerns {
      id
      active
      name
      description
    }
    customFields {
      fieldValue
      fieldId
    }
    preferredLocations {
      id
    }
    preferredUsers {
      id
    }
    cards {
      id
      expiry
      type
      number
      reference
    }
  }
`);
