import {
  border,
  BorderProps,
  composeRestyleFunctions,
  createRestyleComponent,
  layout,
  LayoutProps,
  spacing,
  SpacingProps,
  useRestyle,
} from '@shopify/restyle';
import React from 'react';
import { Theme } from './theme';
import { Image, ImageProps } from 'expo-image';
import { useImageAssets } from '../../../features/Config/hooks/useImageAssets';

export type AutumnImageProps = SpacingProps<Theme> &
  BorderProps<Theme> &
  LayoutProps<Theme> &
  ImageProps;

const restyleFunctions = composeRestyleFunctions<Theme, AutumnImageProps>([
  spacing,
  border,
  layout,
]);

export const AutumnImage = createRestyleComponent<AutumnImageProps, Theme>(
  [spacing, border, layout],
  Image,
);

export function AutumnImageSigned({ source, ...rest }: { source: string } & AutumnImageProps) {
  const { signedAsset } = useImageAssets(source);

  // @ts-expect-error TODO Fix
  const props = useRestyle(restyleFunctions, rest);

  return <AutumnImage {...props} source={signedAsset} />;
}
