import { useEffect, useRef, useState } from 'react';
import { onError } from '@apollo/client/link/error';
import { isDev } from '../../../Common/hooks/useIsDev';
import { ApolloClient, ApolloLink, HttpLink, NormalizedCacheObject } from '@apollo/client';
import { cache } from '../cache/cache';
import { useAuthState } from '../../Login/hooks/useAuthState';
import { setContext } from '@apollo/client/link/context';
import { persistCache } from 'apollo3-cache-persist';
import { SecureGraphCache } from '../cache/cachestorage';
import { useRecoilValue } from 'recoil';
import { devState } from '../../DevSwitcher/state/devState';
import { useOfflineOnline } from '../../OnlineOffline/hooks/useOfflineOnline';
import { useAPIEndpoint } from '../../../Common/hooks/useAPIEndpoint';

export let exposedClientObj: ApolloClient<NormalizedCacheObject> | null = null;

export const useAutumnGraphQLClient = (
  useCache: boolean,
  resolveToken?: () => Promise<string | null | undefined>,
) => {
  const { pin, user, setUserSessionExpired } = useAuthState();

  const [cacheRestoring, setCacheRestoring] = useState(true);

  const { setOnlineStatus } = useOfflineOnline();

  const { localAPI } = useRecoilValue(devState);

  const { baseURL } = useAPIEndpoint();

  const client = useRef<ApolloClient<NormalizedCacheObject>>();

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
      });

      return;
    }

    if (networkError && [401, 403].includes((networkError as never)['statusCode'])) {
      // Show an alert about session expired
      setUserSessionExpired();

      return;
    } else {
      // Network error
      setOnlineStatus(false);
    }
  });

  const asyncAuthLink = setContext(
    (_, { headers }) =>
      new Promise((success) => {
        if (resolveToken) {
          resolveToken().then((accessToken) => {
            success({
              headers: {
                ...headers,
                authorization: `Bearer ${accessToken}`,
              },
            });
          });
        } else {
          success({
            headers: {
              ...headers,
            },
          });
        }
      }),
  );

  const setupClient = async () => {
    const includeCredentialsInRequest = isDev();

    const httpLink = new HttpLink({
      uri: `${baseURL}/graphql`,
      credentials: !localAPI ? 'include' : undefined,
      headers: {
        // To Do. Remove for prod
        'CF-Access-Client-Id': '3b2dae548b1cdcfc0a934c13651f48ba.access',
        'CF-Access-Client-Secret':
          '0dae12c5cf633fa0d24f2db9efb3cd735aabf3ea8dae7b07ef551bfa9489d70a',
        'X-Requested-With': 'XMLHttpRequest',
      },
    });

    const link = ApolloLink.from([errorLink, asyncAuthLink, httpLink]);

    const cacheInstance = cache();

    if (useCache && pin && user) {
      await persistCache({
        cache: cacheInstance,
        storage: new SecureGraphCache(pin),
        key: `cache:${user?.org_id}:${user?.sub}`,
      });
    }

    client.current = new ApolloClient({
      cache: cacheInstance,
      link,
      ...(includeCredentialsInRequest && {
        credentials: 'include',
      }),
    });

    exposedClientObj = client.current;

    setCacheRestoring(false);
  };

  useEffect(() => {
    setupClient().catch(() => console.error('Error setting up client'));
  }, []);

  return { client: client.current, cacheRestoring };
};
