import { createContext } from 'react';
import { Payment_Gateway_Config } from '../../../WebApp/__generatedtypes__/graphql';

export type PaymentGatewayState = {
  selectedPaymentOption: string;
};

export type PaymentGatewayContext = {
  customerId: string;
  state: PaymentGatewayState;
  cardGateway: Payment_Gateway_Config;
};

export const PaymentGatewayContext = createContext<PaymentGatewayContext | null>(null);
