import { graphql } from '../../../__generatedtypes__';
import { useAutumnMutation } from '../../GraphQL/hooks/useAutumnMutation';
import { useState } from 'react';

export const useMessageEdit = () => {
  const [doEditMessage, { loading }] = useAutumnMutation(
    graphql(`
      mutation messageEdit($messageId: String!, $content: String!) {
        messageEdit(messageId: $messageId, message: $content) {
          id
          content
        }
      }
    `),
  );

  const editPortalMessage = async (messageId: string, newContent: string, callback: () => void) => {
    await doEditMessage({
      variables: {
        messageId,
        content: newContent,
      },
    });

    callback();
  };

  return {
    editPortalMessage,
    editing: loading,
  };
};
