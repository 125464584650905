import { enLocalKeys } from '../../../features/Localisations/locales/en';
import React, { ReactNode } from 'react';
import { Pressable } from 'react-native';
import { AutumnHStack } from './AutumnHStack';
import { AutumnLocaleText, AutumnText } from './AutumnText';
import { AutumnBox } from './AutumnBox';
import { AutumnScrollView } from './AutumnScrollView';
import { AutumnLoader } from './AutumnLoader';
import { TranslationProps } from '../../../features/Localisations/hooks/useTranslation';

export type AutumnTableRow<T> = {
  values: string[];
  object: T;
};

export type ActionsData = {
  localeKey: keyof typeof enLocalKeys | TranslationProps;
  onPress: (id: string) => void;
  disabled: boolean;
};

export function AutumnTable<T>({
  headers,
  rows,
  columnsSimple,
  onPress,
  rowKey,
  loading = false,
  rowActions,
}: {
  headers: (keyof typeof enLocalKeys | TranslationProps)[];
  rows?: AutumnTableRow<T>[] | T[];
  columnsSimple?: (keyof T)[];
  actions?: ActionsData[];
  rowKey: keyof T;
  onPress?: (row: T) => void;
  loading?: boolean;
  rowActions?: (data: AutumnTableRow<T> | T) => ReactNode;
}) {
  const testIsRow = (row: T | AutumnTableRow<T>): row is AutumnTableRow<T> => {
    return (
      (row as AutumnTableRow<T>)?.object !== undefined &&
      (row as AutumnTableRow<T>)?.values !== undefined
    );
  };

  const renderValue = (row: object, key: string) => {
    const getNestedValue = (obj: object, keyPath: string) => {
      // @ts-expect-error To fix
      return keyPath.split('.').reduce((acc, part) => acc && (acc[part as string] as string), obj);
    };
    const value = getNestedValue(row, key);

    if (typeof value === 'boolean') return (value as boolean) ? 'Yes' : 'No';
    return value?.toString();
  };

  return (
    <AutumnBox flex={1}>
      <AutumnHStack mb={2} bg={'gray'}>
        {headers.map((header, index) => (
          <AutumnBox flex={1} p={2} key={'header' + index.toString()}>
            <AutumnLocaleText localeKey={header} fontWeight={'bold'} />
          </AutumnBox>
        ))}
      </AutumnHStack>

      <AutumnScrollView>
        {rows?.map((row) => {
          if (row) {
            const isRow = testIsRow(row);
            const key = isRow ? (row.object[rowKey] as string) : (row[rowKey] as string);

            return (
              <Pressable
                key={key}
                disabled={!onPress}
                onPress={() => {
                  if (onPress) {
                    onPress(isRow ? row.object : row);
                  }
                }}
              >
                <AutumnHStack pb={2} mb={2} borderBottomColor={'gray'} borderBottomWidth={1}>
                  {isRow &&
                    row?.values?.map((value) => (
                      <AutumnBox flex={1} p={2} key={value}>
                        <AutumnText>{value}</AutumnText>
                      </AutumnBox>
                    ))}

                  {(row as object)['color' as keyof object] && (
                    <AutumnBox
                      width={4}
                      style={{
                        backgroundColor: (row as object)['color' as keyof object],
                      }}
                    />
                  )}
                  {!isRow &&
                    columnsSimple?.map((key, index) => (
                      <AutumnBox flex={1} p={2} key={(row[key] as string) + index.toString()}>
                        <AutumnText>{renderValue(row as object, key as string)}</AutumnText>
                      </AutumnBox>
                    ))}
                  {rowActions && rowActions(row)}
                </AutumnHStack>
              </Pressable>
            );
          }
          return;
        })}
      </AutumnScrollView>

      {loading && <AutumnLoader />}
    </AutumnBox>
  );
}
