import { makeRedirectUri } from 'expo-auth-session';
import { Platform } from 'react-native';

export const issuer = 'https://auth-dev.autumn.clinic';
export const audience = 'https://clinic-api-dev.autumn.clinic';
export const scopes = ['openid', 'profile', 'email', 'offline_access'];
export const clientId = Platform.select({
  web: 'ZDyVmjaGn9qprB8GkIdD5QKFgTH4bY0f',
  default: 'BwHFc6lH1IoMNNvvDXrSYeVV57yAAo1g',
});

export const useAuthInfo = () => {
  const redirectUri = makeRedirectUri({
    path: 'login',
  });

  return {
    clientId,
    audience,
    issuer,
    redirectUri,
    scopes,
  };
};
