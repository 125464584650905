import { enLocalKeys } from '../../../features/Localisations/locales/en';
import React, { ReactNode } from 'react';
import { AutumnLocaleText } from './AutumnText';
import { TextProps } from '@shopify/restyle';
import { Theme } from './theme';
import { TranslationProps } from '../../../features/Localisations/hooks/useTranslation';
import { AutumnHStack } from './AutumnHStack';
import { AutumnIcon, AutumnIconsProps } from './AutumnIcon';
import { AutumnVStack } from './AutumnVStack';

export function AutumnTitle({
  localeKey,
  subheaderKey,
  icon,
  children,
  rightActions,
  ...rest
}: {
  localeKey: keyof typeof enLocalKeys | TranslationProps;
  subheaderKey?: keyof typeof enLocalKeys | TranslationProps;
  icon?: AutumnIconsProps;
  children?: ReactNode;
  rightActions?: React.ReactNode[];
} & TextProps<Theme>) {
  return (
    <AutumnHStack space={'m'} alignItems={'center'}>
      {icon && <AutumnIcon {...icon} />}
      <AutumnHStack flex={1} justifyContent={'space-between'}>
        <AutumnVStack space={1}>
          <AutumnLocaleText localeKey={localeKey} variant={'header'} {...rest} />
          {subheaderKey && <AutumnLocaleText localeKey={subheaderKey} variant={'bold'} />}
        </AutumnVStack>
        {children}
      </AutumnHStack>

      {rightActions}
    </AutumnHStack>
  );
}
