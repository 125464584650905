import { useState } from 'react';
import { useDebounce } from '@uidotdev/usehooks';
import { graphql } from '../../../__generatedtypes__';
import { FilterKey } from '../../../__generatedtypes__/graphql';
import { doLocalSearch } from '../utils/localsearch';
import { SearchResult } from '../types/types';
import { fuzzy } from 'fast-fuzzy';
import { useConfig } from '../../Config/hooks/useConfig';
import { useUserSettings } from '../../UserSettings/hooks/useUserSettings';
import { InternationalPhoneFromLocal } from '../../../Common/components/ui/AutumnPhoneNumberInput';
import { useAutumnQuery } from '../../GraphQL/hooks/useAutumnQuery';

export const useSearch = (initialValue: string = '', customerOnly: boolean = false) => {
  const [term, setTerm] = useState(initialValue);

  const debouncedTerm = useDebounce(term, 100);

  const { locations } = useConfig();
  const { settings } = useUserSettings();

  const userLocation =
    locations && locations.length > 0
      ? locations?.find((loc) => loc.id === settings?.location) ?? locations[0]
      : { country: 'AU' };

  //this allows the user to enter the number in their local format, but for us to search the international format
  const termConvertedToInternationalPhone = InternationalPhoneFromLocal(
    debouncedTerm,
    userLocation.country,
  );

  const filterKey = (value: string) => {
    if (termConvertedToInternationalPhone.couldConvert) return FilterKey.Mobile;
    if (value.includes('@')) return FilterKey.Email;

    return FilterKey.Name;
  };

  const isCustomerSearch =
    debouncedTerm?.length > 0 &&
    (debouncedTerm.split(' ').length <= 2 ||
      termConvertedToInternationalPhone.couldConvert ||
      debouncedTerm.includes('@'));

  const { data: customerSearch, loading } = useAutumnQuery(
    graphql(`
      query search($limit: Int!, $offset: Int!, $filterKey: FilterKey!, $filterValue: String!) {
        customers(
          limit: $limit
          offset: $offset
          filterKey: $filterKey
          filterValue: $filterValue
        ) {
          ...SimpleCustomerFragment
          name
          id
          __typename
        }
      }
    `),
    {
      skip: debouncedTerm.length === 0 || !isCustomerSearch,
      variables: {
        limit: 15,
        offset: 0,
        filterKey: filterKey(term),
        filterValue: termConvertedToInternationalPhone.couldConvert
          ? termConvertedToInternationalPhone.number
          : debouncedTerm,
      },
    },
  );

  const localResults = customerOnly
    ? []
    : isCustomerSearch
    ? doLocalSearch(debouncedTerm).map((result) => {
        return {
          type: 'localRoute',
          localRoute: result,
          id: result.item.route,
          ranking: fuzzy(debouncedTerm, result.item.name),
        } as SearchResult;
      })
    : [];

  const customerResults =
    customerSearch?.customers?.map((customer) => {
      return {
        type: 'customer',
        customer: customer,
        ranking: fuzzy(debouncedTerm, customer.name),
        id: customer.id,
      } as SearchResult;
    }) ?? [];

  const results = [...localResults, ...customerResults].sort((a, b) => a.ranking - b.ranking);

  return { term, setTerm, loading, results, isCustomerSearch };
};
