import React, { useEffect, useRef, useState } from 'react';
import { SocketIOContext } from '../context/socketiocontext';
import { io, Socket } from 'socket.io-client';
import { useAuthState } from '../../Login/hooks/useAuthState';
import { useAPIEndpoint } from '../../../Common/hooks/useAPIEndpoint';

export default function ({ children }: { children: React.ReactNode }) {
  const { getAccessToken } = useAuthState();

  const [connected, setConnected] = useState(false);

  const client = useRef<Socket>();

  const onConnect = () => {
    setConnected(true);
  };

  const onDisconnect = () => {
    setConnected(false);
  };

  const { baseURL } = useAPIEndpoint();

  const connect = async () => {
    const token = await getAccessToken();

    client.current = io(baseURL, {
      reconnection: true,
      transports: ['websocket', 'polling'],
      path: '/realtime',
      auth: {
        token: `Bearer ${token}`,
      },
    });

    client.current.on('connect', onConnect);
    client.current.on('disconnect', onDisconnect);
  };

  useEffect(() => {
    connect().catch((e) => console.error(e));

    return () => {
      if (client.current) {
        client.current.off('connect', onConnect);
        client.current.off('disconnect', onDisconnect);
      }
    };
  }, []);

  return (
    // @ts-expect-error value error
    <SocketIOContext.Provider value={{ client: client.current, connected }}>
      {children}
    </SocketIOContext.Provider>
  );
}
