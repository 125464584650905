import toast from 'react-hot-toast';
import { enLocalKeys } from '../../features/Localisations/locales/en';

export const showSuccessToast = (message: keyof typeof enLocalKeys | string) => {
  // @ts-expect-error Expected
  toast.success(enLocalKeys[message] ?? message, {});
};

export const showErrorToast = (message: keyof typeof enLocalKeys | string) => {
  // @ts-expect-error Expected
  toast.error(enLocalKeys[message] ?? message, {});
};
