import { TRouteType } from '../types/types';

export const routeTopLevel = (route: string): TRouteType => {
  const pathComponents = route.split('/');

  const pathsWithSecondary = ['customers'];

  pathComponents.shift();
  return {
    grouper: pathComponents[0],
    secondaryGrouper: pathsWithSecondary.includes(pathComponents[0])
      ? pathComponents[1]
      : undefined,
    pathComponents: pathComponents,
    route,
  };
};
