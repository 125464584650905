import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import React, { useEffect, useRef, useState } from 'react';
import { setContext } from '@apollo/client/link/context';
import { usePatientAppAuth } from '../../Auth/hooks/usePatientAppAuth';
import { isDev } from '../../../../WebApp/Common/hooks/useIsDev';
import { useRecoilValue } from 'recoil';
import { devState } from '../../../../WebApp/features/DevSwitcher/state/devState';
import { useAPIEndpoint } from '../../../../WebApp/Common/hooks/useAPIEndpoint';
import { ActivityIndicator } from 'react-native';

export const PatientAppGraphQLProvider = ({ children }: { children: React.ReactNode }) => {
  const client = useRef<ApolloClient<NormalizedCacheObject>>();

  const { localAPI } = useRecoilValue(devState);
  const { baseURL } = useAPIEndpoint();

  const { signedIn, token } = usePatientAppAuth();

  const [setup, setSetup] = useState(false);

  const asyncAuthLink = setContext(
    (_, { headers }) =>
      new Promise((success) => {
        if (signedIn && token) {
          success({
            headers: {
              ...headers,
              authorization: `Bearer ${token}`,
            },
          });
        } else {
          success({
            headers: {
              ...headers,
            },
          });
        }
      }),
  );

  const setupClient = async () => {
    const includeCredentialsInRequest = isDev();

    const httpLink = new HttpLink({
      uri: `${baseURL}/graphql`,
      credentials: !localAPI ? 'include' : undefined,
      headers: {
        // To Do. Remove for prod
        'CF-Access-Client-Id': '3b2dae548b1cdcfc0a934c13651f48ba.access',
        'CF-Access-Client-Secret':
          '0dae12c5cf633fa0d24f2db9efb3cd735aabf3ea8dae7b07ef551bfa9489d70a',
        'X-Requested-With': 'XMLHttpRequest',
      },
    });

    const link = ApolloLink.from([asyncAuthLink, httpLink]);

    client.current = new ApolloClient({
      cache: new InMemoryCache(),
      link,
      ...(includeCredentialsInRequest && {
        credentials: 'include',
      }),
    });

    setSetup(true);
  };

  useEffect(() => {
    setupClient().catch(() => console.error('Error setting up client'));
  }, []);

  if (!setup) return <ActivityIndicator />;

  // @ts-expect-error This is caught above via the state
  return <ApolloProvider client={client.current}>{children}</ApolloProvider>;
};
