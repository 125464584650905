import { usePatientAppPages } from '../hooks/usePatientAppPages';
import { ActivityIndicator } from 'react-native';
import React from 'react';

export const PatientAppPagesProvider = ({ children }: { children: React.ReactNode }) => {
  const { loading } = usePatientAppPages();

  if (loading) return <ActivityIndicator />;

  return children;
};
