import { graphql } from '../../../__generatedtypes__';
import { useAutumnQuery } from '../../GraphQL/hooks/useAutumnQuery';

export const getSignedAsset = graphql(`
  query signAsset($assetKey: String!) {
    signAsset(assetKey: $assetKey)
  }
`);

export const useImageAssets = (url?: string | undefined) => {
  const { data, loading } = useAutumnQuery(getSignedAsset, {
    variables: {
      assetKey: url ?? '',
    },
    skip: !url,
  });

  return {
    signedAsset: data?.signAsset as string,
    loadingAsset: loading,
  };
};
