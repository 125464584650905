import { AutumnModal, AutumnVStack } from '../../../WebApp/Common/components/ui';
import React from 'react';
import { useFlowContext } from '../hooks/useFlowContext';
import { yupTypedSchema } from '../../../WebApp/Common/utils/yup';
import { string } from 'yup';
import { AutumnForm } from '../../../WebApp/Common/components/ui/AutumnForm';
import { AutumnLocaleText } from '../../../WebApp/Common/components/ui/AutumnText';
import { CompleteLoginForm, useFlowLogin } from '../hooks/useFlowLogin';

export function FlowLogin() {
  const { state } = useFlowContext();
  const { completeLogin, completingLogin } = useFlowLogin();

  if (!state.login) return;

  return (
    <AutumnModal show={true} title={'ExistingAccount'} onClose={() => null} type={'centered'}>
      <AutumnVStack space={'m'}>
        <AutumnLocaleText localeKey={'ExistingAccountExplainer'} />

        <AutumnForm<CompleteLoginForm>
          initialValues={{
            code: '',
            captcha: '',
          }}
          validation={yupTypedSchema<CompleteLoginForm>({
            code: string()
              .length(6)
              .test((e) => /^[0-9]{6}$/gm.test(e ?? '')),
            captcha: string().required(),
          })}
          buttonTitle={'Signin'}
          onSubmit={completeLogin}
          loading={completingLogin}
          elements={[
            {
              type: 'input',
              label: 'SigninCode',
              key: 'code',
            },
            {
              type: 'captcha',
              key: 'captcha',
            },
          ]}
        />
      </AutumnVStack>
    </AutumnModal>
  );
}
