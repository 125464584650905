import { AutumnHStack } from './AutumnHStack';
import React, { useEffect, useState } from 'react';
import { enLocalKeys } from '../../../features/Localisations/locales/en';
import { AutumnLocaleText } from './AutumnText';
import { AutumnPressable } from './AutumnPressable';
import { AutumnIcon, AutumnIconsProps } from './AutumnIcon';
import { TranslationProps } from '../../../features/Localisations/hooks/useTranslation';
import Animated, { useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated';
import theme, { Theme } from './theme';

import { BackgroundColorProps, SpacingProps, TextProps } from '@shopify/restyle';
import { AutumnBox } from './AutumnBox';

import { AutumnScrollView } from './AutumnScrollView';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown';
import { faSearch } from '@fortawesome/pro-solid-svg-icons/faSearch';
import { Icon } from '../Icon';
import { AutumnInputElement } from './AutumnInput';
import { usePlatformInformation } from '../../utils/usePlatformInformation';
import { AutumnFieldWrapper, AutumnFieldWrapperProps } from './AutumnFieldWrapper';
import { AutumnPopover } from './AutumnPopover';

export type AutumnDropdownProps = {
  options: AutumnDropdownOption[];
  selectedOption: string | undefined | null;
  onChange: (selectedValue: string | undefined) => void;
  /**
   * When enabled, tapping an already selected option will call onChange(undefined)
   */
  allowDeselect?: boolean;
  disabled?: boolean;
  noSelectionLabel?: keyof typeof enLocalKeys | TranslationProps;

  /**
   * When enabled: Dropdown items will show above other content in a Portal
   * When disabled: Dropdown items will cause the component height to grow, pushing down other content
   */
  popout?: boolean;
  dropdownHeight?: number;
  initialShow?: boolean;
  disableSort?: boolean;
  disableFilter?: boolean;
} & AutumnDropdownRestyleProps;

export type AutumnDropdownOption = {
  id: string;
  label?: keyof typeof enLocalKeys | TranslationProps;
  localeKey?: keyof typeof enLocalKeys | TranslationProps;
  icon?: AutumnIconsProps;
};

export type AutumnDropdownRestyleProps = SpacingProps<Theme> &
  BackgroundColorProps<Theme> &
  TextProps<Theme>;

export function AutumnDropdown({
  options,
  selectedOption,
  allowDeselect,
  disabled = false,
  onChange,
  noSelectionLabel,
  popout,
  initialShow = false,
  dropdownHeight = 200,
  disableSort,
  disableFilter,
  ...rest
}: AutumnDropdownProps & AutumnFieldWrapperProps) {
  const [showing, setShowing] = useState(initialShow);
  const [typedValue, setTypedValue] = useState('');

  const { isWeb } = usePlatformInformation();

  const changedSelection = (selectionId: string) => {
    onChange(selectionId === selectedOption && allowDeselect ? undefined : selectionId);
    setShowing(false);
    setTypedValue('');
  };

  const popoutWidth = 300;

  const rotation = useSharedValue(90);
  const animatedStyle = useAnimatedStyle(() => {
    return {
      transform: [{ rotateZ: `${rotation.value}deg` }],
    };
  });

  useEffect(() => {
    rotation.value = withTiming(showing ? 180 : 0, {
      duration: 750,
    });
  }, [showing]);

  const sortedOptions = disableSort
    ? options
    : options.sort((a, b) => {
        const aLabel = typeof a.label === 'string' ? a.label : a.label?.key ?? '';
        const bLabel = typeof b.label === 'string' ? b.label : b.label?.key ?? '';

        return aLabel.localeCompare(bLabel);
      });

  const dropdownOptions = sortedOptions
    .filter((a) =>
      typedValue?.length > 0
        ? (typeof a.label === 'string' ? a.label : a.label?.key ?? '')
            .toLowerCase()
            .includes(typedValue.toLowerCase())
        : true,
    )
    .map((option) => (
      <AutumnDropdownItem
        key={option.id}
        option={option}
        onChange={changedSelection}
        isSelected={option.id === selectedOption}
      />
    ));

  const dropdownContent = showing ? (
    <AutumnBox flex={1}>
      {!disableFilter && (
        <AutumnHStack
          space={2}
          alignItems={'center'}
          borderBottomColor={'gray.50'}
          borderBottomWidth={1}
        >
          <Icon icon={faSearch} size={14} color={theme.colors.buttonPrimary} />
          <AutumnInputElement
            height={30}
            value={typedValue}
            padding={3}
            placeholder={'Filter...'}
            onChangeText={(e) => setTypedValue(e)}
            autoFocus={isWeb}
            editable={!disabled}
          />
        </AutumnHStack>
      )}

      <AutumnScrollView flex={1} borderRadius={10} persistentScrollbar={false}>
        {dropdownOptions}
      </AutumnScrollView>
    </AutumnBox>
  ) : (
    <AutumnBox></AutumnBox>
  );

  const selectedListOption = selectedOption
    ? options.find((option) => option.id === selectedOption)
    : null;

  const pressed = () => {
    if (!disabled) {
      setShowing(!showing);
    }
  };

  return (
    <AutumnPopover
      popoverWidth={popoutWidth}
      show={showing && popout == true}
      onOutsidePress={() => setShowing(false)}
      disableAnimation={true}
      popoverComponent={
        <AutumnBox style={{ padding: 5, maxHeight: dropdownHeight }}>{dropdownContent}</AutumnBox>
      }
    >
      <AutumnFieldWrapper hasFocus={showing} {...rest} onLabelPressed={pressed}>
        <AutumnPressable onPress={pressed}>
          <AutumnHStack height={30} alignItems={'center'}>
            {selectedListOption && (
              <AutumnLocaleText
                flex={1}
                localeKey={selectedListOption.localeKey ?? selectedListOption.label ?? { key: '' }}
                color={selectedListOption ? 'black' : 'buttonPrimary'}
              />
            )}

            {!selectedListOption && noSelectionLabel && (
              <AutumnLocaleText
                flex={1}
                localeKey={noSelectionLabel}
                color={selectedListOption ? 'black' : 'buttonPrimary'}
              />
            )}

            {!selectedListOption && !noSelectionLabel && <AutumnBox flex={1}></AutumnBox>}

            <AutumnBox width={5} />
            <Animated.View style={animatedStyle}>
              <Icon icon={faChevronDown} size={20} color={'#1F472D'} />
            </Animated.View>
          </AutumnHStack>
        </AutumnPressable>

        {showing && !popout && <AutumnBox height={dropdownHeight}>{dropdownContent}</AutumnBox>}
      </AutumnFieldWrapper>
    </AutumnPopover>
  );
}

const AutumnDropdownItem = ({
  option,
  onChange,
  isSelected,
}: {
  option: AutumnDropdownOption;
  onChange: (selectedValue: string | undefined) => void;
  isSelected: boolean;
}) => {
  return (
    <AutumnPressable
      onPress={() => onChange(option.id)}
      style={{ paddingVertical: 10, paddingLeft: 5 }}
    >
      <AutumnHStack
        space={2}
        alignItems={'center'}
        borderBottomWidth={1}
        borderBottomColor={'gray.50'}
      >
        {option.icon && <AutumnIcon {...option.icon} />}

        <AutumnLocaleText
          localeKey={option.label ?? option.localeKey ?? { key: '' }}
          fontWeight={isSelected ? 'bold' : '400'}
        />
      </AutumnHStack>
    </AutumnPressable>
  );
};
