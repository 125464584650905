import React, { useContext } from 'react';
import { AutumnButton } from '../../../WebApp/Common/components/ui';
import {
  Config_Portal_Pages_Component,
  Config_Portal_Pages_Component_ButtonProps,
} from '../../../WebApp/__generatedtypes__/graphql';
import { useFlowNext } from '../../Flows/hooks/useFlowNext';
import { ComponentContext } from '../context/ComponentContext';
import { FlowContext } from '../../Flows/context/FlowContext';

export function ComponentButton({ component }: { component: Config_Portal_Pages_Component }) {
  const props = component.props as Config_Portal_Pages_Component_ButtonProps;

  const { onNavigate } = useContext(ComponentContext);
  const { state } = useContext(FlowContext);

  const { requestNext, requestBack } = useFlowNext();

  const onPress = () => {
    if (props.action === 'FormNext') {
      requestNext(false);
    } else if (props.action === 'FormSubmit') {
      requestNext(true);
    } else if (props.action === 'FormBack') {
      requestBack();
    } else if (props.action === 'Navigate') {
      if (onNavigate && props.actionLink) {
        onNavigate(props.actionLink);
      }
    }
  };

  const isDisabled = state?.loading && props.action !== 'FormSubmit';
  const isLoading = state?.loading && props.action === 'FormSubmit';

  return (
    <AutumnButton disabled={isDisabled} isLoading={isLoading} onPress={onPress}>
      {props.label}
    </AutumnButton>
  );
}
