import {
  AutumnBox,
  AutumnButton,
  AutumnHStack,
  AutumnModal,
  AutumnPopover,
  AutumnPressable,
  AutumnScrollView,
  AutumnVStack,
} from './index';
import React, { useState } from 'react';
import { enLocalKeys } from '../../../features/Localisations/locales/en';
import { AutumnInputElement } from './AutumnInput';
import { useConfig } from '../../../features/Config/hooks/useConfig';
import { AutumnIcon } from './AutumnIcon';
import { faClose } from '@fortawesome/pro-solid-svg-icons/faClose';
import { TranslationProps } from '../../../features/Localisations/hooks/useTranslation';
import { User, UserType } from '../../../__generatedtypes__/graphql';
import { AutumnLocaleText } from './AutumnText';
import { usePlatformInformation } from '../../utils/usePlatformInformation';
import { AutumnFieldWrapper, AutumnFieldWrapperProps } from './AutumnFieldWrapper';
import {
  FlattenTreatmentOption,
  useTreatment,
} from '../../../features/Admin/Treatment/hooks/useTreatment';
import { AutumnGroupedSelectionList } from './AutumnGroupedSelectionList';

export type AutumnOptionElement = {
  id: string;
  label: keyof typeof enLocalKeys | TranslationProps;
  locale?: keyof typeof enLocalKeys | TranslationProps;
};

export const AutumnBrandSelector = (props: Omit<AutumnTagSelectorProps, 'options'>) => {
  const { config } = useConfig();

  const options = config?.map((a) => {
    const option: AutumnOptionElement = { id: a.brand, label: { key: a.name } };

    return option;
  });

  return <AutumnTagSelector options={options} {...props} />;
};

export const AutumnTreatmentSelector = (props: Omit<AutumnTagSelectorProps, 'options'>) => {
  const { treatments } = useConfig();

  const options = treatments?.map((treatment) => {
    const option: AutumnOptionElement = { id: treatment.id, label: { key: treatment.name } };

    return option;
  });

  return <AutumnTagSelector options={options} {...props} />;
};

export const AutumnTreatmentOptionSelector = (props: Omit<AutumnTagSelectorProps, 'options'>) => {
  const { treatmentOptionsFlattened, treatmentListLoading } = useTreatment();
  const [showModal, setShowModal] = React.useState(false);

  const options = treatmentOptionsFlattened?.map((treatmentoption: FlattenTreatmentOption) => {
    const option: AutumnOptionElement = {
      id: treatmentoption.id,
      label: { key: treatmentoption.fullName ?? '' },
    };

    return option;
  });

  return (
    <>
      <AutumnTagSelector
        options={options}
        replaceOpenCallback={() => setShowModal(true)}
        {...props}
      />
      <AutumnModal
        show={showModal}
        type={'rightSide'}
        minWidth={600}
        title={'TreatmentOptions'}
        disableScroll={true}
        onClose={() => setShowModal(false)}
        onRequestClose={() => setShowModal(false)}
      >
        <AutumnVStack space={'m'} flex={1}>
          <AutumnGroupedSelectionList<FlattenTreatmentOption>
            loading={treatmentListLoading}
            groupBy={'treatmentName'}
            sortBy={'name'}
            searchBy={'fullName'}
            userFilterBy={'treatmentTagName'}
            isSelectList={true}
            selectedValues={props.selectedValues as string[]}
            groupPressAction={'selectall'}
            onSelectionChange={props.onChange}
            rowKey={'id'}
            rows={treatmentOptionsFlattened
              .filter(
                (treatmentOption) =>
                  treatmentOption.treatmentActive &&
                  (treatmentOption.active || props.selectedValues.includes(treatmentOption.id)),
              )
              .map((row) => {
                return {
                  object: row,
                  title: 'name',
                  labelRight: !row.active ? { key: 'Inactive' } : undefined,
                };
              })}
          />
          <AutumnButton onPress={() => setShowModal(false)} localeKey={'Done'} />
        </AutumnVStack>
      </AutumnModal>
    </>
  );
};

export const AutumnUserSelector = ({
  userTypes,
  includeInactive = false,
  ...props
}: { userTypes?: UserType[] | undefined; includeInactive?: boolean } & Omit<
  AutumnTagSelectorProps,
  'options'
>) => {
  const { users } = useConfig();
  const [showModal, setShowModal] = React.useState(false);

  const userOptions = users?.filter(
    (user) =>
      (includeInactive || user.active) &&
      (userTypes ? user.userType && userTypes.includes(user.userType) : true),
  );

  const options = userOptions.map((user) => {
    const option: AutumnOptionElement = { id: user.id, label: { key: user.name } };

    return option;
  });

  return (
    <>
      <AutumnTagSelector
        options={options}
        replaceOpenCallback={userOptions?.length > 10 ? () => setShowModal(true) : undefined}
        {...props}
      />
      <AutumnModal
        show={showModal}
        type={'rightSide'}
        minWidth={600}
        title={'Users'}
        disableScroll={true}
        onClose={() => setShowModal(false)}
        onRequestClose={() => setShowModal(false)}
      >
        <AutumnVStack space={'m'} flex={1}>
          <AutumnGroupedSelectionList<User>
            loading={false}
            groupBy={'userType'}
            sortBy={'name'}
            searchBy={'name'}
            isSelectList={!props.singleSelectionOnly}
            selectedValues={props.selectedValues as string[]}
            onRowPress={
              props.singleSelectionOnly
                ? (id: string) => {
                    props.onChange(id);
                    setShowModal(false);
                  }
                : undefined
            }
            groupPressAction={'selectall'}
            onSelectionChange={props.onChange}
            rowKey={'id'}
            nonGroupedItemsLabel={{ key: 'UserType' }}
            rows={userOptions.map((row) => {
              return {
                object: row,
                title: 'name',
                labelRight: !row.active ? { key: 'Inactive' } : undefined,
              };
            })}
          />
          <AutumnButton onPress={() => setShowModal(false)} localeKey={'Done'} />
        </AutumnVStack>
      </AutumnModal>
    </>
  );
};

export const AutumnLocationSelector = ({
  includeInactive = false,
  ...props
}: { includeInactive?: boolean } & Omit<AutumnTagSelectorProps, 'options'>) => {
  const { locations } = useConfig();

  const options = locations
    ?.filter((location) => includeInactive || location.active)
    .map((location) => {
      const option: AutumnOptionElement = { id: location.id, label: { key: location.name } };

      return option;
    });

  return <AutumnTagSelector options={options} {...props} />;
};

export type AutumnTagSelectorProps = {
  options: AutumnOptionElement[];
  onChange: (items: string[] | string) => void;
  createNew?: () => void;
  disabled?: boolean;
  selectedValues: string[] | string;
  singleSelectionOnly?: boolean;
  openPopoverButtonLabel?: keyof typeof enLocalKeys | TranslationProps;
  allowRemove?: boolean;
  replaceOpenCallback?: () => void;
} & AutumnFieldWrapperProps;

const TagItem = ({
  option,
  onPress,
  onPressRemove,
  disabled,
}: {
  option: AutumnOptionElement;
  disabled?: boolean;
  onPress?: (item: AutumnOptionElement) => void;
  onPressRemove?: (item: AutumnOptionElement) => void;
}) => {
  return (
    <AutumnPressable
      disabled={!onPress || disabled}
      onPress={() => (onPress ? onPress(option) : null)}
      minHeight={30}
    >
      <AutumnHStack
        alignItems={'center'}
        space={'m'}
        borderRadius={4}
        bg={'buttonPrimary'}
        padding={'s'}
      >
        <AutumnLocaleText localeKey={option.label} color={'white'} />

        {onPressRemove && (
          <AutumnPressable onPress={() => onPressRemove(option)} disabled={disabled}>
            <AutumnIcon color={'white'} icon={faClose} size={15} />
          </AutumnPressable>
        )}
      </AutumnHStack>
    </AutumnPressable>
  );
};

export function AutumnTagSelector({
  options,
  onChange,
  createNew,
  disabled,
  selectedValues,
  singleSelectionOnly,
  openPopoverButtonLabel,
  replaceOpenCallback,
  allowRemove = true,
  ...rest
}: AutumnTagSelectorProps) {
  const [typedValue, setTypedValue] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const { isWeb } = usePlatformInformation();

  const pressedItem = (item: AutumnOptionElement) => {
    if (singleSelectionOnly) {
      onChange(item.id);
      setIsOpen(false);
    } else {
      onChange([...selectedValues, item.id]);
    }
  };

  const pressedRemoveItem = (item: AutumnOptionElement) => {
    if (singleSelectionOnly) {
      onChange('');
      return;
    }
    const selectedItems = selectedValues as string[];
    if (!selectedItems.includes(item.id)) return;

    onChange(selectedItems.filter((a) => a !== item.id));
  };

  const open = () => {
    if (replaceOpenCallback) {
      replaceOpenCallback();
    } else {
      setIsOpen(true);
    }
  };

  const clearAll = () => {
    onChange([]);
  };

  const selectAll = () => {
    onChange([...options.map((a) => a.id)]);
  };

  let selectedItems: string[];

  if (typeof selectedValues === 'string') {
    selectedItems = [selectedValues];
  } else {
    selectedItems = selectedValues;
  }

  const matchingOptions = options
    ?.sort((a, b) => {
      const aLabel = typeof a.label === 'string' ? a.label : a.label.key;
      const bLabel = typeof b.label === 'string' ? b.label : b.label.key;

      return aLabel.localeCompare(bLabel);
    })
    ?.filter(
      (a) =>
        !selectedItems?.includes(a.id) &&
        (typedValue?.length > 0
          ? (typeof a.label === 'string' ? a.label : a.label.key)
              .toLowerCase()
              ?.includes(typedValue.toLowerCase())
          : true),
    );

  return (
    <AutumnPopover
      onOutsidePress={() => setIsOpen(false)}
      popoverComponent={
        <AutumnBox bg="white" p={'m'} borderRadius={5}>
          <AutumnHStack space={2}>
            <AutumnInputElement
              height={40}
              value={typedValue}
              flex={1}
              marginBottom={3}
              padding={3}
              borderBottomColor={'lightgray'}
              borderBottomWidth={1}
              placeholder={'Filter...'}
              onChangeText={(e) => setTypedValue(e)}
              autoFocus={isWeb}
              editable={!disabled}
            />
            {createNew && (
              <AutumnButton
                variant={'primary'}
                localeKey={'Create'}
                onPress={createNew}
                disabled={disabled}
                isLoading={false}
              />
            )}
          </AutumnHStack>

          <AutumnHStack space={2} flexWrap={'wrap'}>
            {matchingOptions?.map((item) => (
              <TagItem
                option={item}
                disabled={disabled}
                onPress={() => {
                  if (!disabled) {
                    pressedItem(item);
                  }
                }}
                key={item.id}
              />
            ))}
          </AutumnHStack>
        </AutumnBox>
      }
      show={isOpen}
    >
      <AutumnFieldWrapper onLabelPressed={open} hasFocus={isOpen} {...rest}>
        <AutumnVStack maxHeight={300} style={{ marginTop: -2 }}>
          <AutumnScrollView>
            <AutumnHStack space={2} alignItems={'center'} flexWrap={'wrap'} width={'100%'}>
              {selectedItems?.map((itemId) => {
                const item = options.find((a) => a.id === itemId);

                if (!item) return null;

                return (
                  <TagItem
                    option={item}
                    key={item.id}
                    disabled={disabled}
                    onPress={open}
                    onPressRemove={allowRemove ? pressedRemoveItem : undefined}
                  />
                );
              })}

              <AutumnPressable disabled={disabled} flex={1} height={24} onPress={open} />

              {!singleSelectionOnly && options.length > 2 && selectedItems?.length > 2 && (
                <AutumnButton
                  disabled={disabled}
                  onPress={() => clearAll()}
                  variant={'link'}
                  localeKey={'Clear'}
                />
              )}

              {!singleSelectionOnly && options.length > 2 && selectedItems?.length === 0 && (
                <AutumnButton
                  disabled={disabled}
                  onPress={() => selectAll()}
                  variant={'link'}
                  localeKey={'All'}
                />
              )}

              <AutumnButton
                onPress={open}
                variant={'link'}
                disabled={disabled}
                localeKey={openPopoverButtonLabel ?? 'Change'}
              />
            </AutumnHStack>
          </AutumnScrollView>
        </AutumnVStack>
      </AutumnFieldWrapper>
    </AutumnPopover>
  );
}
