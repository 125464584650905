import { atom } from 'recoil';
import { getStorage, setStorage } from '../../Storage/utils/storage';

const localStorageEffect =
  (key: string) =>
  // @ts-ignore
  ({ setSelf, onSet }) => {
    const savedValue = getStorage(key);

    if (savedValue != null) {
      setSelf(savedValue);
    }

    onSet((newValue: any, _: any, isReset: any) => {
      setStorage(key, newValue);
    });
  };

export type DevStateType = {
  localAPI: boolean;
  deniedPermissions?: string[];
};

export const devStateLocalStorageKey = 'devEnvState';

export const devState = atom<DevStateType>({
  key: devStateLocalStorageKey,
  default: { localAPI: false, deniedPermissions: [] },
  effects: [localStorageEffect(devStateLocalStorageKey)],
});
