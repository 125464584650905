import { graphql } from '../../../__generatedtypes__';
import { useState } from 'react';
import { useAutumnQuery } from '../../GraphQL/hooks/useAutumnQuery';

const defaultSize = 50;

export const InboxThreadFragment = graphql(`
  fragment InboxThreadFragment on Message_Thread {
    id
    brand
    unreadInternal
    openInternal
    timestamp
    customer {
      ...SimpleCustomerFragment
      name
    }
    assignedTo {
      id
      name
      image
    }
    lastMessage {
      __typename
      id
      content
      sender
      senderId
      senderName
      timestamp
      channel
    }
  }
`);

export const inboxQuery = graphql(`
  query messagesThreads(
    $assigned: [String]
    $limit: Int!
    $offset: Int!
    $open: Boolean
    $brand: String!
  ) {
    messagesThreads(
      assigned: $assigned
      limit: $limit
      offset: $offset
      open: $open
      brand: $brand
    ) {
      id
      ...InboxThreadFragment
      openInternal
      __typename
    }
  }
`);

export const useInbox = () => {
  const [open, setOpen] = useState(true);
  const [brand, setBrand] = useState<string | undefined>(undefined);
  const [assigned, setAssignedUser] = useState<string | null>(null);

  const [pageSize, setPageSize] = useState(defaultSize);

  const [canLoadMore, setCanLoadMore] = useState(true);

  const { data, loading } = useAutumnQuery(inboxQuery, {
    variables: { offset: 0, limit: pageSize, open: open, assigned, brand: brand ?? '' },
    skip: !brand,
    onCompleted: (data) => {
      setCanLoadMore((data?.messagesThreads?.length ?? 0) >= pageSize);
    },
  });

  const loadNextPage = async () => {
    setPageSize(pageSize + defaultSize);
  };

  const threads = (data?.messagesThreads ?? [])?.filter((a) => a && a?.openInternal === open);

  return {
    threads,
    loading,
    loadNextPage,
    canLoadMore: canLoadMore && !loading,
    setOpen,
    open,
    setAssignedUser,
    assigned,
    brand,
    setBrand,
  };
};
