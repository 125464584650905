import { Auth_State_User, Auth_State_User_WToken } from '../types/types';
import { deleteStorageKey, getSecureStorage, setSecureStorage } from '../../Storage/utils/storage';

const makeKey = (orgId: string, user: string) => `token:${orgId}:${user}`;

export const saveUserToken = (token: Auth_State_User_WToken, pin: string): Promise<void> => {
  return setSecureStorage<Auth_State_User_WToken>(
    makeKey(token.user.org_id, token.user.sub),
    token,
    pin,
  );
};

export const removeUserToken = (orgId: string, user: string) => {
  deleteStorageKey(makeKey(orgId, user));
};

export const getUserToken = (
  user: Auth_State_User,
  pin: string,
): Promise<Auth_State_User_WToken | null> => {
  return getSecureStorage<Auth_State_User_WToken>(makeKey(user.user.org_id, user.user.sub), pin);
};
