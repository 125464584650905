import { Config_Portal_Pages_Component } from '../../../WebApp/__generatedtypes__/graphql';
import { Messages } from '../../../WebApp/features/Messages/components/Messages';
import React, { useContext } from 'react';
import { ComponentContext } from '../context/ComponentContext';
import { AutumnText } from '../../../WebApp/Common/components/ui';

export function ComponentMessages({ component }: { component: Config_Portal_Pages_Component }) {
  const {
    renderContext: { brand, customerId },
  } = useContext(ComponentContext);

  if (!brand || !customerId)
    return <AutumnText>Messages not available. No context provided</AutumnText>;

  return (
    <Messages
      threadId={customerId}
      isCustomerFacing={true}
      hideActionsHeader={true}
      brand={brand}
    />
  );
}
