import { atom } from 'recoil';

export type offlineOnlineStateType = {
  online: boolean;
};

export const offlineOnlineState = atom<offlineOnlineStateType>({
  key: 'offlineOnlineState',
  default: { online: true },
});
