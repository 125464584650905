import React from 'react';
import { AutumnBox } from './ui';

export type WebViewProps = {
  url?: string;
  html?: string;
  webViewStyling?: React.CSSProperties;
};

export function WebView({ url, html, webViewStyling }: WebViewProps) {
  return (
    <AutumnBox flex={1}>
      <iframe srcDoc={html} src={url} width={'100%'} height={'100%'} style={webViewStyling} />
    </AutumnBox>
  );
}
