import React from 'react';
import { usePatientAppConfig } from '../hooks/usePatientAppConfig';
import { ActivityIndicator } from 'react-native';

export function PatientAppConfigProviders({ children }: { children: React.ReactNode }) {
  const { loading, error } = usePatientAppConfig();

  if (loading) return <ActivityIndicator />;

  return <>{children}</>;
}
