import {
  Config_Portal_Pages_Component,
  Config_Portal_Pages_Component_TextProps,
} from '../../../WebApp/__generatedtypes__/graphql';
import { AutumnText } from '../../../WebApp/Common/components/ui';
import React from 'react';
import { ComponentUtils } from '../utils/utils';
import { Theme } from '../../../WebApp/Common/components/ui/theme';

export function ComponentText({ component }: { component: Config_Portal_Pages_Component }) {
  const props = component.props as Config_Portal_Pages_Component_TextProps;

  const style = ComponentUtils.convertToStyleObject(component.style);

  return (
    <AutumnText
      {...(props?.bold && {
        fontWeight: 'bold',
      })}
      {...(style?.color && {
        color: style?.color as keyof Theme['colors'],
      })}
    >
      {props.text}
    </AutumnText>
  );
}
