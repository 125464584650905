import React, { FC, PropsWithChildren } from 'react';
import { graphql } from '../../../__generatedtypes__';
import {
  Config,
  CustomField,
  ImageAngle,
  Location,
  Permission,
  Resource,
  Tag,
  Tenant,
  Treatment,
  User,
  UserPermissions,
} from '../../../__generatedtypes__/graphql';
import { ConfigContext } from '../context/ConfigContext';
import { useAutumnQuery } from '../../GraphQL/hooks/useAutumnQuery';

export const ConfigProvider: FC<PropsWithChildren> = ({ children }) => {
  const { data } = useAutumnQuery(
    graphql(`
      query config {
        config {
          brand
          name
          domain
          logo
          __typename
        }
        permissions {
          permission
          name
        }
        whoAmI {
          __typename
          tenant
          user {
            image
            name
            id
            admin
            userType
            __typename
          }
          permissions {
            type
            permission
            user
            tenant
            locationType
            id
          }
        }
        locations {
          __typename
          name
          id
          active
          suburb
          state
          street
          phone
          country
          currency
          timezone
        }
        tags {
          id
          name
          active
          description
          __typename
        }
        treatments {
          id
          name
          tenant
          tag {
            __typename
            id
            name
          }
          __typename
        }
        users {
          id
          brands
          image
          given
          surname
          name
          userType
          admin
          active
          __typename
        }
        userSettings {
          location
          __typename
        }
        customFields {
          id
          active
          name
          grouping
          dataType
          choices
          availableOn
        }
        imageAngles {
          id
          name
          active
          imageKey
          instructions
        }
        resources {
          id
          active
          name
        }
        tenant {
          id
          name
          displayName
          appointmentDuration
        }
      }
    `),
  );

  const config = (data?.config ?? []) as Config[];
  const users = (data?.users ?? []) as User[];
  const treatments = (data?.treatments ?? []) as Treatment[];
  const locations = (data?.locations ?? []) as Location[];

  //TODO - restrict what locations a user has access to
  const availableLocations = locations?.filter((location) => location.active);
  const customFields = (data?.customFields ?? []) as CustomField[];

  const tags = (data?.tags ?? []) as Tag[];
  const imageAngles = (data?.imageAngles ?? []) as ImageAngle[];
  const resources = (data?.resources ?? []) as Resource[];

  const permissions = (data?.permissions ?? []) as Permission[];
  const userPermissions = (data?.whoAmI?.permissions ?? []) as UserPermissions[];

  return (
    <ConfigContext.Provider
      value={{
        config,
        users,
        me: data?.whoAmI?.user as User,
        tenant: data?.tenant as Tenant,
        treatments,
        locations,
        availableLocations,
        settings: data?.userSettings ?? {},
        customFields,
        permissions,
        tags,
        imageAngles,
        resources,
        userPermissions,
        tenantId: data?.whoAmI?.tenant ?? '',
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};
