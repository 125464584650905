import { AutumnHStack } from './AutumnHStack';
import React from 'react';
import { enLocalKeys } from '../../../features/Localisations/locales/en';
import { AutumnLocaleText } from './AutumnText';
import { AutumnPressable } from './AutumnPressable';
import { AutumnBox } from './AutumnBox';
import { AutumnIcon, AutumnIconsProps } from './AutumnIcon';
import { AutumnVStack } from './AutumnVStack';
import { TranslationProps } from '../../../features/Localisations/hooks/useTranslation';
import { AutumnButton } from './AutumnButton';

export type AutumnVerticalSelectorProps = {
  options: AutumnVerticalSelectorOption[];
  selectedOption?: string | undefined | null;
  onChange: (selectedValue: string | undefined, additionalReturnValue: string | undefined) => void;
  /**
   * When enabled, tapping an already selected option will call onChange(undefined)
   */
  allowDeselect?: boolean;
  disabled?: boolean;
  localeKey?: keyof typeof enLocalKeys | TranslationProps;
  hideUnselected?: boolean;
};

export type AutumnVerticalSelectorOption = {
  id: string;
  label?: keyof typeof enLocalKeys | TranslationProps;
  descriptionLines?: TranslationProps[];
  icon?: AutumnIconsProps;
  /**
   * Can be returned along with the ID on selection
   */
  additionalReturnValue?: string;
};

export function AutumnVerticalSelector({
  options,
  selectedOption,
  allowDeselect,
  disabled = false,
  onChange,
  localeKey,
  hideUnselected,
}: AutumnVerticalSelectorProps) {
  const [showUnselected, setShowUnselected] = React.useState(!hideUnselected);
  const hasSelection = options.findIndex((option) => option.id === selectedOption) > -1;

  return (
    <AutumnVStack space={4}>
      {localeKey && <AutumnLocaleText localeKey={localeKey} fontWeight={'bold'} />}
      {options
        .filter((option) => !hasSelection || showUnselected || option.id === selectedOption)
        .map((option, index) => {
          const isSelected = option.id === selectedOption;
          return (
            <AutumnPressable
              key={option.id + index.toString()}
              disabled={disabled}
              onPress={() => {
                onChange(
                  isSelected && allowDeselect ? undefined : option.id,
                  option.additionalReturnValue,
                );
                setShowUnselected(!hideUnselected);
              }}
            >
              <AutumnBox
                opacity={!hasSelection || isSelected ? 1 : 0.7}
                borderWidth={isSelected ? 1.5 : 1}
                borderRadius={5}
                borderColor={isSelected ? 'buttonPrimary' : !hasSelection ? 'gray.100' : 'gray.50'}
                backgroundColor={'white'}
                padding={'m'}
                flex={1}
              >
                <AutumnHStack alignItems={'center'} space={4}>
                  {option.icon && (
                    <AutumnBox alignItems={'center'} width={30}>
                      <AutumnIcon {...option.icon} />
                    </AutumnBox>
                  )}

                  <AutumnVStack space={1} flex={1}>
                    <AutumnLocaleText
                      localeKey={option.label ?? { key: '' }}
                      fontWeight={isSelected ? 'bold' : '400'}
                    />
                    {option.descriptionLines && (
                      <AutumnVStack space={1}>
                        {option.descriptionLines &&
                          option.descriptionLines.map((line, index) => (
                            <AutumnLocaleText key={'line' + index.toString()} localeKey={line} />
                          ))}
                      </AutumnVStack>
                    )}
                  </AutumnVStack>
                </AutumnHStack>
              </AutumnBox>
            </AutumnPressable>
          );
        })}
      {!showUnselected && hasSelection && (
        <AutumnButton
          onPress={() => setShowUnselected(true)}
          localeKey={'ShowAll'}
          variant={'link'}
        />
      )}
    </AutumnVStack>
  );
}
