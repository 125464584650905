import { AutumnVStack } from './AutumnVStack';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { enLocalKeys } from '../../../features/Localisations/locales/en';
import { AutumnInput } from './AutumnInput';
import { AutumnDatePicker } from './AutumnDatePicker';
import { AutumnCheckbox } from './AutumnCheckbox';
import { AutumnOptionElement, AutumnTagSelector } from './AutumnTagSelector';
import { AutumnAlert, AutumnHStack, AutumnTitle, Button } from './index';
import { AutumnTimePicker } from './AutumnTimePicker';
import { AutumnImageSelector } from './AutumnImageSelector';
import { AutumnDropdown } from './AutumnDropdown';
import { AutumnPhoneNumberInput } from './AutumnPhoneNumberInput';
import { AutumnIconsProps } from './AutumnIcon';
import { AutumnBox } from './AutumnBox';
import { Platform } from 'react-native';
import { AutumnTurnstile } from './AutumnTurnstile';
import { AnyObject, ObjectSchema, ValidationError } from 'yup';
import {
  AutumnFormValidationIcon,
  AutumnFormValidationIconState,
} from './AutumnFormValidationIcon';
import { AutumnVerticalSelector } from './AutumnVerticalSelector';
import { AutumnColorPicker } from './AutumnColorPicker';

export type AutumnFormElement<T> = {
  type:
    | 'input'
    | 'phone'
    | 'captcha'
    | 'element'
    | 'date'
    | 'time'
    | 'checkbox'
    | 'picker'
    | 'singlePicker'
    | 'verticalSelector'
    | 'image'
    | 'publicImage'
    | 'custom'
    | 'dropdown'
    | 'color';

  label?: keyof typeof enLocalKeys;
  key?: keyof T;
  explainer?: keyof typeof enLocalKeys;
  phoneCountry?: string;
  element?: React.ReactNode;
  custom?: (onChange: (key: string, value: string | string[]) => void) => React.ReactNode;
  pickerOptions?: AutumnOptionElement[];
  singleSelectionOnly?: boolean;
  section?: keyof typeof enLocalKeys;
  footer?: React.ReactNode;
  allowKeyboardEntry?: boolean;
};

export type AutumnFormProps<T> = {
  loading: boolean;
  disabled?: boolean;
  elements: AutumnFormElement<T>[];
  sections?: AutumnFormSection[];
  columns?: number;
  initialValues?: T | undefined | null;
  onValues?: (values: T) => void;
  onSubmit?: (values: T) => void;
  buttonTitle?: keyof typeof enLocalKeys;
  footer?: React.ReactNode;
  validation?: ObjectSchema<AnyObject>;
  error?: string | null | undefined; // Show a generic error
};

/**
 * Group fields into an optional section.
 *
 * @param section the name of this section, can be hidden
 * @param parentSection the name of the section that this one should be rendered immediately after
 * @param columns how many columns of form elements should be rendered in this section
 */
export type AutumnFormSection = {
  section: keyof typeof enLocalKeys;
  parentSection?: keyof typeof enLocalKeys;
  hideTitle?: boolean;
  icon?: AutumnIconsProps;
  explainer?: keyof typeof enLocalKeys;
  columns?: number;
};

type AutumnFormInternalValidation = {
  valid: boolean;
  message?: string;
};

/* Anything that the ultimate elements need */
type AutumnFormInternal = {
  validationStatus: boolean;
  validationSetUp: boolean;
  validationMap: Record<string, AutumnFormInternalValidation>;
  loading: boolean;
  clickedSubmit: boolean;
  state: Record<string, never>;
  onChange: (key: string, value: string | boolean | string[]) => void;
};

const InternalFormContext = createContext<AutumnFormInternal>({
  validationMap: {},
  validationStatus: false,
  validationSetUp: false,
  loading: false,
  state: {},
  clickedSubmit: false,
  onChange: () => null,
});

export function AutumnForm<T>({
  disabled,
  elements,
  sections,
  loading,
  initialValues,
  onValues,
  columns = 1,
  onSubmit,
  error,
  buttonTitle,
  validation,
}: AutumnFormProps<T>) {
  const [width, setWidth] = useState(600);
  const [state, setState] = useState<T | null>(initialValues ?? null);

  const [validationStatus, setValidationStatus] = useState(false);
  const [validationMap, setValidationMap] = useState<Record<string, AutumnFormInternalValidation>>(
    {},
  );

  const [clickedSubmit, setClickedSubmit] = useState(false);

  const onChange = (key: string, value: string | boolean | string[], valid: boolean = true) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    setState((prevState) => {
      const newState = {
        ...prevState,
        [key]: value,
      };

      if (newState && !valid && key in newState) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        delete newState[key];
      }

      if (onValues && newState) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        onValues(newState);
      }

      if (validation) {
        try {
          (validation as ObjectSchema<AnyObject>).validateSync(newState, {
            abortEarly: false,
          });

          setValidationMap(
            Object.keys(newState).reduce(
              (obj: { [key: string]: AutumnFormInternalValidation }, key: string) => {
                obj[key] = { valid: true } as AutumnFormInternalValidation;
                return obj;
              },
              {},
            ),
          );

          setValidationStatus(true);
        } catch (e) {
          setValidationMap(
            Object.keys(newState).reduce(
              (obj: { [key: string]: AutumnFormInternalValidation }, key: string) => {
                const isInError = (e.inner as ValidationError[]).find((a) => a.path === key);
                obj[key] = {
                  valid: !isInError,
                  message: isInError?.message,
                } as AutumnFormInternalValidation;
                return obj;
              },
              {},
            ),
          );

          setValidationStatus(false);
        }
      }

      return newState;
    });
  };

  const pressedOnSubmit = () => {
    if (loading) return;

    setClickedSubmit(true);

    if (onSubmit && state) {
      if (validation) {
        if (validationStatus) {
          onSubmit(state);
        }
      } else {
        onSubmit(state);
      }
    }
  };

  useEffect(() => {
    if (initialValues !== undefined && (state === null || state === undefined)) {
      setState(initialValues);
    }
  }, [loading, initialValues, state]);

  const uniqueSections = [...new Set(elements.map((el) => el.section))];

  //there is no section config, but more than 1 column. So pass that column down to the only section
  const passColumnsToSection = (sections == undefined || sections.length === 0) && columns > 1;

  const sectionWidth = passColumnsToSection ? width : width / columns - (columns - 1) * 16;

  const formSections = uniqueSections.map((section) => {
    const sectionConfig: AutumnFormSection =
      sections?.find((s) => s?.section === section) ??
      ({ section: section, columns: passColumnsToSection ? columns : 1 } as AutumnFormSection);

    if (sectionConfig.parentSection) {
      return null; //this section will be rendered as a child of its parent
    }

    return (
      <AutumnVStack key={section} width={sectionWidth}>
        <AutumnFormSection<T>
          section={section}
          sectionConfig={sectionConfig}
          sections={sections}
          sectionWidth={sectionWidth}
          elements={elements}
        />
      </AutumnVStack>
    );
  });

  return (
    <InternalFormContext.Provider
      value={{
        validationStatus,
        validationMap,
        validationSetUp: !validation && !initialValues,
        loading,
        onChange,
        state: state as Record<string, never>,
        clickedSubmit,
      }}
    >
      <AutumnBox
        onLayout={(event) => {
          if (Platform.OS === 'web') {
            // @ts-expect-error this actually works fine
            event.nativeEvent.target.measure((_x, _y, width) => {
              setWidth(width);
            });
          } else {
            event.target.measure((_x, _y, width) => {
              if (width > 0) {
                setWidth(width);
              }
            });
          }
        }}
      >
        <AutumnVStack space={'m'}>
          <AutumnHStack space={'m'} flexWrap={'wrap'}>
            {formSections}
          </AutumnHStack>

          {error && <AutumnAlert message={error} type={'error'} />}

          {onSubmit && (
            <Button
              onPress={pressedOnSubmit}
              localeKey={buttonTitle ?? 'Save'}
              isLoading={loading}
              disabled={disabled}
            />
          )}
        </AutumnVStack>
      </AutumnBox>
    </InternalFormContext.Provider>
  );
}

function AutumnFormSectionElement<T>({ elem }: { elem: AutumnFormElement<T> }) {
  const { loading, onChange, state, validationMap, clickedSubmit } =
    useContext(InternalFormContext);

  const validationState = (): AutumnFormValidationIconState => {
    if (validationMap?.[elem.key as string]?.valid) return 'valid';

    if (clickedSubmit && validationMap?.[elem.key as string]?.valid === false) return 'invalid';

    return 'none';
  };

  const renderElement = () => {
    if (elem.type === 'element') return elem.element;

    if (elem.type === 'custom' && typeof elem.custom === 'function') {
      return elem.custom(onChange);
    }

    if (elem.type === 'date') {
      return (
        <AutumnDatePicker
          key={elem.key as string}
          disabled={loading}
          value={state?.[elem.key as string] as string}
          label={elem.label}
          startWithYear={elem.key === 'dob'}
          enableKeyboardEntry={elem?.allowKeyboardEntry}
          onChange={(e) => onChange(elem.key as string, e)}
        />
      );
    }

    if (elem.type === 'time') {
      return (
        <AutumnTimePicker
          key={elem.key as string}
          disabled={loading}
          value={state?.[elem.key as string] as string}
          label={elem.label}
          onChange={(e) => onChange(elem.key as string, e)}
        />
      );
    }

    if (elem.type === 'checkbox') {
      return (
        <AutumnCheckbox
          key={elem.key as string}
          localeKey={elem.label}
          isChecked={state?.[elem.key as string] as boolean}
          explainer={elem.explainer}
          onChange={(e) => onChange(elem.key as string, e)}
        />
      );
    }

    if (elem.type === 'verticalSelector') {
      return (
        <AutumnVerticalSelector
          localeKey={elem.label}
          options={elem?.pickerOptions ?? []}
          hideUnselected={(elem?.pickerOptions ?? []).length > 4}
          onChange={(e: string) => onChange(elem.key as string, e)}
          selectedOption={state?.[elem.key as string] as string}
          disabled={loading}
        />
      );
    }

    if (elem.type === 'image') {
      return (
        <AutumnImageSelector
          onUpload={(e) => onChange(elem.key as string, e)}
          src={state?.[elem.key as string] as string}
          disabled={loading}
          label={elem.label}
          explainer={elem.explainer}
        />
      );
    }

    if (elem.type === 'picker' || elem.type === 'singlePicker') {
      return (
        <AutumnTagSelector
          label={elem.label}
          options={elem?.pickerOptions ?? []}
          explainer={elem.explainer}
          singleSelectionOnly={elem.type === 'singlePicker'}
          allowRemove={elem.type === 'picker'}
          onChange={(e) => onChange(elem.key as string, e)}
          selectedValues={state?.[elem.key as string] as string[]}
          disabled={loading}
        />
      );
    }

    if (elem.type === 'phone') {
      return (
        <AutumnPhoneNumberInput
          label={elem.label}
          value={state?.[elem.key as string] as string}
          initialCountry={elem.phoneCountry}
          onChange={(newPhone: string) => onChange(elem.key as string, newPhone)}
          rightElement={validationMap && <AutumnFormValidationIcon state={validationState()} />}
        />
      );
    }

    if (elem.type === 'captcha') {
      return <AutumnTurnstile callback={(token) => onChange(elem.key as string, token)} />;
    }

    if (elem.type === 'dropdown') {
      return (
        <AutumnDropdown
          label={elem.label}
          options={elem?.pickerOptions ?? []}
          onChange={(e) => onChange(elem.key as string, e as string)}
          selectedOption={state?.[elem.key as string] as string}
          disabled={loading}
        />
      );
    }
    if (elem.type === 'color') {
      return (
        <AutumnColorPicker
          label={elem.label}
          onChange={(e) => onChange(elem.key as string, e as string)}
          selectedColor={state?.[elem.key as string] as string}
          options={elem.pickerOptions ?? []}
          disabled={loading}
        />
      );
    }

    return (
      <AutumnInput
        key={elem.key as string}
        disabled={loading}
        value={state?.[elem.key as string] as string}
        label={elem.label}
        explainer={elem.explainer}
        onChange={(e: string) => onChange(elem.key as string, e)}
        rightElement={validationMap && <AutumnFormValidationIcon state={validationState()} />}
      />
    );
  };

  return (
    <>
      {renderElement()}
      {elem.footer}
    </>
  );
}

function AutumnFormSection<T>({
  section,
  sectionConfig,
  sectionWidth,
  elements,
  sections,
}: {
  section: keyof typeof enLocalKeys | undefined;
  sectionConfig: AutumnFormSection;
  sectionWidth: number;
} & Pick<AutumnFormProps<T>, 'columns' | 'elements' | 'sections'>) {
  const formElements = elements
    ?.filter((elem) => elem.section === section)
    .map((elem) => {
      return <AutumnFormSectionElement key={elem.key as string} elem={elem} />;
    });

  const columns = sectionConfig.columns ?? 1;
  const elementWidth =
    sectionWidth / columns - //how many columns the section
    (8 * columns) / columns;

  const childSections = sections
    ?.filter((s) => s.parentSection && s.parentSection === section)
    .map((sectionConfig) => {
      return (
        <AutumnVStack key={sectionConfig.section} width={sectionWidth}>
          <AutumnFormSection<T>
            section={sectionConfig.section}
            sectionConfig={sectionConfig}
            columns={columns}
            sectionWidth={sectionWidth}
            elements={elements}
          />
        </AutumnVStack>
      );
    });

  return (
    <AutumnVStack space={'s'}>
      {section && !sectionConfig.hideTitle && (
        <AutumnTitle
          icon={sectionConfig.icon}
          localeKey={section}
          subheaderKey={sectionConfig.explainer}
          fontSize={18}
          fontWeight={'bold'}
        />
      )}

      <AutumnHStack space={'s'} flexWrap={'wrap'}>
        {formElements.map((el, index) => (
          <AutumnVStack key={el + index.toString()} width={elementWidth}>
            {el}
          </AutumnVStack>
        ))}
      </AutumnHStack>

      {childSections}
    </AutumnVStack>
  );
}
