import { graphql, useFragment } from '../../../__generatedtypes__';
import { useAutumnMutation } from '../../GraphQL/hooks/useAutumnMutation';
import { useState } from 'react';
import {
  SimpleCustomerFragment,
  SimpleCustomerFragmentType,
} from '../../GraphQL/fragments/customer';
import { unreadMessageCountQuery } from './useInboxMessagesCount';
import { inboxQuery } from './useInbox';

export const useThreadLinking = () => {
  const [selectedCustomer, setSelectedCustomer] = useState<SimpleCustomerFragmentType>(undefined);

  const customer = useFragment(SimpleCustomerFragment, selectedCustomer);

  const [doLinkThread, { loading }] = useAutumnMutation(
    graphql(`
      mutation messagesThreadLinkCustomer($threadId: String!, $customerId: String!) {
        messagesThreadLinkCustomer(threadID: $threadId, customerID: $customerId) {
          id
        }
      }
    `),
  );

  const linkThreadToCustomer = async (threadId: string) => {
    if (customer) {
      const threadLinkResponse = await doLinkThread({
        variables: {
          threadId,
          customerId: customer.id,
        },
        refetchQueries: [
          { query: unreadMessageCountQuery, fetchPolicy: 'network-only' },
          {
            query: inboxQuery,
            fetchPolicy: 'network-only',
            variables: { offset: 0, limit: 20, open: true },
          },
        ],
        update(cache) {
          cache.modify({
            fields: {
              messagesThreads(existingThreads, { readField }) {
                //@ts-expect-error thread of type any
                return existingThreads.filter((thread) => threadId !== readField('id', thread));
              },
            },
          });
        },
      });

      return threadLinkResponse?.data?.messagesThreadLinkCustomer?.id ?? undefined;
    }

    return undefined;
  };

  return {
    linkThreadToCustomer,
    selectedCustomer,
    setSelectedCustomer,
    linking: loading,
    canLink: selectedCustomer !== undefined,
  };
};
