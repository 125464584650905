import React from 'react';
import Markdown, { MarkdownIt } from 'react-native-markdown-display';
import { StyleSheet } from 'react-native';

export function MessageMarkdown({ content }: { content: string }) {
  return (
    <Markdown
      style={styles}
      markdownit={MarkdownIt({ typographer: true }).disable(['link', 'image'])}
    >
      {content}
    </Markdown>
  );
}

const styles = StyleSheet.create({
  heading: {
    color: 'white',
  },
  heading1: {
    color: 'white',
  },
  heading2: {
    color: 'white',
  },
  heading3: {
    color: 'white',
  },
  heading4: {
    color: 'white',
  },
  heading5: {
    color: 'white',
  },
  heading6: {
    color: 'white',
  },
  text: {
    color: 'white',
    padding: 0,
    margin: 0,
    fontSize: 12,
  },
  paragraph: {
    marginBottom: 0,
    marginTop: 0,
  },
});
