import React, { FC, PropsWithChildren, useEffect } from 'react';
import NetInfo from '@react-native-community/netinfo';
import { useRecoilState } from 'recoil';
import { offlineOnlineState } from '../state/offlineonlinestate';
import { AutumnHStack, AutumnPressable } from '../../../Common/components/ui';
import { AutumnLocaleText } from '../../../Common/components/ui/AutumnText';
import { AutumnIcon } from '../../../Common/components/ui/AutumnIcon';
import { faWifiSlash } from '@fortawesome/pro-regular-svg-icons/faWifiSlash';
import { useIsDev } from '../../../Common/hooks/useIsDev';

export const OfflineOnlineProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, setState] = useRecoilState(offlineOnlineState);

  const isDev = useIsDev();

  useEffect(() => {
    const unsubscribe = NetInfo.addEventListener((state) => {
      if (state) {
        setState({ online: state.isConnected ?? false });
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <>
      {!state?.online && (
        <AutumnPressable disabled={!isDev} onPress={() => setState({ online: true })}>
          <AutumnHStack
            space={'s'}
            alignItems={'center'}
            justifyContent={'center'}
            py={2}
            bg={'yellow'}
          >
            <AutumnIcon icon={faWifiSlash} size={20} />
            <AutumnLocaleText localeKey={'OfflineBanner'} />
          </AutumnHStack>
        </AutumnPressable>
      )}

      {children}
    </>
  );
};
