import React, { useState } from 'react';
import { Messaging_Channel } from '../../../__generatedtypes__/graphql';

import {
  AutumnAlert,
  AutumnBox,
  AutumnButton,
  AutumnHStack,
  AutumnModal,
  AutumnVStack,
} from '../../../Common/components/ui';
import { useRouting } from '../../Tabs/hooks/useRouting';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { usePlatformInformation } from '../../../Common/utils/usePlatformInformation';
import { LinkThreadToCustomerModal } from './LinkThreadToCustomerModal';

export function MessagesHeader({
  threadId,
  customerId,
  channelsUsed,
  threadRead,
  markAsRead,
  markAsUnread,
  toggleOpen,
  isOpen,
  brand,
}: {
  threadId: string;
  customerId: string | undefined;
  channelsUsed: Messaging_Channel[];
  threadRead: boolean;
  markAsRead?: () => void;
  markAsUnread?: () => void;
  toggleOpen?: (open: boolean) => void;
  isOpen?: boolean;
  brand: string;
}) {
  const { navigate } = useRouting();
  const { isSmallScreen } = usePlatformInformation();

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showLinkingModal, setShowLinkModal] = useState(false);

  const needsLinking = customerId == undefined;

  const pressedToggleOpen = () => {
    if (isOpen) {
      setShowConfirmationModal(true);
    } else if (toggleOpen) {
      doToggleOpen(true);
    }
  };
  const doToggleOpen = (open: boolean) => {
    if (toggleOpen) {
      toggleOpen(open);
      setShowConfirmationModal(false);
    }
  };

  return (
    <AutumnBox
      style={{
        position: 'absolute',
        width: '100%',
        zIndex: 10,
        backgroundColor: 'rgba(250, 250, 250, 0.8)',
        //@ts-expect-error the below isn't support on ios
        backdropFilter: 'blur(5px)',
      }}
    >
      <AutumnHStack justifyContent={'space-between'} padding={'s'} space={'m'}>
        {isSmallScreen ? (
          <AutumnButton
            onPress={() => navigate('/inbox')}
            localeKey={'Inbox'}
            leftIcon={faChevronLeft}
            variant="link"
          />
        ) : (
          <AutumnBox />
        )}
        <AutumnHStack space={'m'}>
          {!threadRead && markAsRead && !isSmallScreen && (
            <AutumnButton onPress={markAsRead} localeKey={'MarkAsRead'} variant={'outline'} />
          )}
          {threadRead && markAsUnread && !isSmallScreen && (
            <AutumnButton onPress={markAsUnread} localeKey={'MarkAsUnread'} variant={'outline'} />
          )}
          {toggleOpen && (
            <AutumnButton
              onPress={pressedToggleOpen}
              localeKey={isOpen ? 'MarkCompleted' : 'ReOpen'}
              variant={'outline'}
            />
          )}
          {!needsLinking && (
            <AutumnButton
              onPress={() => navigate(`/customers/${customerId}`)}
              localeKey={'OpenPatient'}
            />
          )}
          {needsLinking && (
            <AutumnButton onPress={() => setShowLinkModal(true)} localeKey={'LinkToCustomer'} />
          )}
        </AutumnHStack>
      </AutumnHStack>

      {showConfirmationModal && (
        <ConfirmCompleted
          onClose={() => setShowConfirmationModal(false)}
          onConfirmed={() => doToggleOpen(false)}
        />
      )}

      {showLinkingModal && (
        <LinkThreadToCustomerModal
          threadId={threadId}
          onClose={() => setShowLinkModal(false)}
          onLinked={(newThreadId) => {
            setShowLinkModal(false);
            navigate(`/inbox/${brand}/` + newThreadId);
          }}
        />
      )}
    </AutumnBox>
  );
}

function ConfirmCompleted({
  onClose,
  onConfirmed,
}: {
  onClose: () => void;
  onConfirmed: () => void;
}) {
  return (
    <AutumnModal show={true} title={'Confirm'} type={'centered'} onClose={onClose}>
      <AutumnVStack space={'s'}>
        <AutumnAlert message={'MarkCompletedAlert'} type={'info'} />
        <AutumnButton onPress={onConfirmed} localeKey={'MarkCompleted'} />
      </AutumnVStack>
    </AutumnModal>
  );
}
