import React, { ReactNode } from 'react';
import { PatientAppThemeProvider } from './PatientAppThemeProvider';
import { usePatientAppConfig } from '../../AppConfig/hooks/usePatientAppConfig';
import { useFonts } from 'expo-font';
import { ActivityIndicator } from 'react-native';

export function PatientAppTheme({ children }: { children: ReactNode }) {
  const { theme, getThemeValue } = usePatientAppConfig();

  const [loaded] = useFonts({
    ...(getThemeValue('font') && {
      customFont: getThemeValue('font') ?? '',
    }),
    ...(getThemeValue('fontTitle') && {
      customFontTitle: getThemeValue('fontTitle') ?? '',
    }),
  });

  if (!loaded) return <ActivityIndicator />;

  return <PatientAppThemeProvider theme={theme}>{children}</PatientAppThemeProvider>;
}
