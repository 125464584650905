import { useRecoilState } from 'recoil';
import { offlineOnlineState } from '../state/offlineonlinestate';

export const useOfflineOnline = () => {
  const [state, setState] = useRecoilState(offlineOnlineState);

  const setOnlineStatus = (online: boolean) =>
    setState((prevState) => {
      return { ...prevState, online };
    });

  return {
    online: state.online,
    setOnlineStatus,
  };
};
