import React from 'react';
import { string } from 'yup';
import { usePatientAppLogin } from '../hooks/useLogin';
import { PatientAppCompleteLoginForm, PatientAppLoginForm } from '../types/Form';
import { yupTypedSchema } from '../../../../WebApp/Common/utils/yup';
import { AutumnVStack } from '../../../../WebApp/Common/components/ui';
import { AutumnForm } from '../../../../WebApp/Common/components/ui/AutumnForm';

export function PatientAppSignin() {
  const { onLogin, session, onCompleteLogin, loading } = usePatientAppLogin();

  return (
    <AutumnVStack>
      {!session && (
        <AutumnForm<PatientAppLoginForm>
          initialValues={{
            mobile: '',
            captcha: '',
            dob: '',
          }}
          validation={yupTypedSchema<PatientAppLoginForm>({
            mobile: string().required(),
            captcha: string().required(),
            dob: string().required(),
          })}
          onSubmit={onLogin}
          loading={loading}
          elements={[
            {
              type: 'phone',
              label: 'Mobile',
              key: 'mobile',
            },
            {
              type: 'date',
              key: 'dob',
              allowKeyboardEntry: true,
              label: 'DOB',
            },
            { type: 'captcha', key: 'captcha' },
          ]}
        />
      )}

      {session && (
        <AutumnForm<PatientAppCompleteLoginForm>
          initialValues={{
            code: '',
            captcha: '',
          }}
          validation={yupTypedSchema<PatientAppCompleteLoginForm>({
            captcha: string().required(),
            code: string()
              .length(6)
              .test((e) => /^[0-9]{6}$/gm.test(e ?? '')),
          })}
          buttonTitle={'Signin'}
          onSubmit={onCompleteLogin}
          loading={loading}
          elements={[
            {
              type: 'input',
              label: 'SignupCode',
              key: 'code',
            },

            { type: 'captcha', key: 'captcha' },
          ]}
        />
      )}
    </AutumnVStack>
  );
}
