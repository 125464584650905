import { usePatientAppDomain } from './usePatientAppDomain';
import { graphql } from '../../../../WebApp/__generatedtypes__';
import { ConfigTheme } from '../../../../WebApp/__generatedtypes__/graphql';
import { useAutumnQuery } from '../../../../WebApp/features/GraphQL/hooks/useAutumnQuery';

export const usePatientAppConfig = () => {
  const { domain } = usePatientAppDomain();

  const { data, loading, error } = useAutumnQuery(
    graphql(`
      query configForDomain($domain: String!) {
        configForDomainName(domain: $domain) {
          name
          brand
          tenant
          portalEnabled
          portalMessage
          portalLayout {
            type
            style {
              key
              value
              type
            }
            props {
              __typename
              ... on Config_Portal_Pages_Component_ButtonProps {
                action
                actionLink
                label
              }
              ... on Config_Portal_Pages_Component_HorizontalPickerProps {
                options
                explainer
                title
                id
              }
              ... on Config_Portal_Pages_Component_HStackVStackProps {
                spacing
                alignItems
              }
              ... on Config_Portal_Pages_Component_ImageProps {
                source
                maxHeight
                maxWidth
                width
                height
              }
              ... on Config_Portal_Pages_Component_TextInputProps {
                explainer
                title
                id
              }
              ... on Config_Portal_Pages_Component_TitleProps {
                title
              }
              ... on Config_Portal_Pages_Component_TextProps {
                text
              }
            }
            children {
              type
              style {
                key
                value
                type
              }
              props {
                __typename
                ... on Config_Portal_Pages_Component_ButtonProps {
                  action
                  actionLink
                  label
                }
                ... on Config_Portal_Pages_Component_HorizontalPickerProps {
                  options
                  explainer
                  title
                  id
                }
                ... on Config_Portal_Pages_Component_HStackVStackProps {
                  spacing
                  alignItems
                }
                ... on Config_Portal_Pages_Component_ImageProps {
                  source
                  maxHeight
                  maxWidth
                  width
                  height
                }
                ... on Config_Portal_Pages_Component_TextInputProps {
                  explainer
                  title
                  id
                }
                ... on Config_Portal_Pages_Component_TextProps {
                  text
                }
              }
            }
          }
          theme {
            key
            value
            type
          }
        }
      }
    `),
    {
      variables: {
        domain,
      },
      fetchPolicy: 'cache-first',
    },
  );

  const getThemeValue = (key: string) =>
    data?.configForDomainName?.theme?.find((a) => a.key === key)?.value;

  return {
    error,
    config: data?.configForDomainName,
    theme: (data?.configForDomainName?.theme ?? []) as ConfigTheme[],
    getThemeValue,
    loading,
    brand: data?.configForDomainName?.brand ?? '',
    tenant: data?.configForDomainName?.tenant ?? '',
  };
};
