import { enLocalKeys } from '../locales/en';
import { localeMap } from '../locales/map';
import { useRecoilValue } from 'recoil';
import { currentLanguageState } from '../state/localeState';
import Mustache from 'mustache';
import pluralize from 'pluralize';

const languages = {
  en: {
    code: 'en',
    name: 'English',
    icon: 'https://flagsapi.com/AU/flat/64.png',
  },
  fr: {
    code: 'fr',
    name: 'Français',
    icon: 'https://flagsapi.com/FR/flat/64.png',
  },
};

/**
 * Set plural = true if you always want the key to render as a plural.
 * Alternatively pass a number to conditionally determine if a plural is needed.
 */
export type TranslationProps = {
  key: keyof typeof enLocalKeys | string;
  renderParameters?: Record<string, string | number | undefined | null>;
  plural?: number | boolean | undefined;
};

export const useTranslation = () => {
  const currentLanguageCode = useRecoilValue(currentLanguageState);

  const t = (input: TranslationProps | keyof typeof enLocalKeys | string) => {
    if (typeof input === 'string') {
      return doTranslate({ key: input });
    } else {
      return doTranslate(input);
    }
  };

  const doTranslate = ({ key, renderParameters, plural }: TranslationProps): string => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const template = (localeMap[currentLanguageCode][key] as string) ?? key;

    const containsMustache = template.indexOf('{{') > -1;

    if (containsMustache) {
      try {
        // Escape is important so we dont actually escape it!
        return Mustache.render(template, renderParameters ?? {}, {}, { escape: (e: string) => e });
      } catch (e) {
        throw new Error(`Mustache render error in doTranslate ${e.toString()}`);
      }
    }

    if (plural !== undefined) {
      return makePlural(template, typeof plural === 'boolean' ? 2 : plural);
    }

    return template;
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const currentLanguage = languages[currentLanguageCode];

  const makePlural = (word: string, qty: number): string => {
    return pluralize(word, qty);
  };

  return { t, languages, currentLanguage, makePlural };
};
