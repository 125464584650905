import { AutumnText, AutumnVStack } from '../../../../WebApp/Common/components/ui';
import React from 'react';

export function PortalDisabled({ message }: { message: string }) {
  return (
    <AutumnVStack>
      <AutumnText>{message}</AutumnText>
    </AutumnVStack>
  );
}
