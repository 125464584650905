import { graphql } from '../../../__generatedtypes__';
import { useAutumnQuery } from '../../GraphQL/hooks/useAutumnQuery';

export const useOnboard = () => {
  const { loading } = useAutumnQuery(
    graphql(`
      query onboardQuery {
        completeUserOnboarding {
          user {
            id
          }
          message
        }
      }
    `),
    { fetchPolicy: 'cache-first' },
  );

  return { onboarded: true, loading };
};
