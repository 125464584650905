import { graphql } from '../../../__generatedtypes__';
import { UserSettings_Input } from '../../../__generatedtypes__/graphql';
import { useConfig } from '../../Config/hooks/useConfig';
import { useAutumnMutation } from '../../GraphQL/hooks/useAutumnMutation';

export const useUserSettings = () => {
  const { settings, locations } = useConfig();

  const [mutation, { loading }] = useAutumnMutation(
    graphql(`
      mutation userSettings($input: UserSettings_Input!) {
        upsertUserSettings(input: $input) {
          location
        }
      }
    `),
  );

  const updateSettings = async (e: UserSettings_Input) => {
    const input = { ...e };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    delete input['__typename'];

    await mutation({
      variables: { input },
    });
  };

  const userLocation = locations?.find((loc) => loc.id === settings?.location) ?? locations?.[0];

  return { loading, updateSettings, settings, userLocation };
};
