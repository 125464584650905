import React from 'react';
import LottieView from 'lottie-react-native';

export function AutumnLoader({ size = 100 }: { size?: number }) {
  return (
    <LottieView
      autoPlay
      style={{
        width: size,
        height: size,
      }}
      webStyle={{ width: size, height: size }}
      resizeMode={'contain'}
      loop={true}
      speed={2.5}
      source={require('./AutumnLoader.json')}
    />
  );
}
