import React, { ReactNode } from 'react';
import { AutumnBox } from './AutumnBox';
import { Theme } from './theme';
import { BoxProps } from '@shopify/restyle';
import { StyleProp, ViewStyle } from 'react-native';

export type AutumnVStackProps = {
  space?: keyof Theme['spacing'];
  children: ReactNode;
  style?: StyleProp<ViewStyle>;
} & BoxProps<Theme>;

export function AutumnVStack({ children, space, ...rest }: AutumnVStackProps) {
  return (
    <AutumnBox rowGap={space} flexDirection={'column'} {...rest}>
      {children}
    </AutumnBox>
  );
}
