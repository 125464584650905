import React, { useEffect } from 'react';
import { PatientAppProviders } from './providers/Providers';
import { Route, Routes, useNavigate } from 'react-router';
import { PathRouter } from '../WebApp/Common/components/Navigation';
import { usePatientAppPages } from './components/Pages/hooks/usePatientAppPages';
import { usePatientAppConfig } from './components/AppConfig/hooks/usePatientAppConfig';
import { PatientAppRootLayout } from './components/Pages/component/PatientAppRootLayout';
import { PatientAppPage } from './components/Pages/component/PatientAppPage';
import {
  Config_Portal_Pages,
  Config_Portal_Pages_Component,
} from '../WebApp/__generatedtypes__/graphql';
import { usePatientAppAuth } from './components/Auth/hooks/usePatientAppAuth';
import { AutumnBox } from '../WebApp/Common/components/ui';
import { NotFound } from './components/NotFound/component/NotFound';
import { PortalDisabled } from './components/PortalDisabled/components/PortalDisabled';
import { PatientAppFlow } from './components/Flow/views/PatientAppFlow';
import { ComponentProvider } from '../Common/Components/provider/ComponentProvider';
import { GoogleTagManagerProvider } from '../Common/GoogleTagManager/providers/GoogleTagManagerProvider';
import { GoogleTagManagerPageView } from '../Common/GoogleTagManager/components/GoogleTagManagerPageView';

const PageRouteProtector = ({ page }: { page: Config_Portal_Pages }) => {
  const { signedIn } = usePatientAppAuth();

  const navigate = useNavigate();

  useEffect(() => {
    if (page.protected && !signedIn) {
      navigate(`/signin?redirect=${encodeURI(page.id)}`);
    }
  }, [page.protected, signedIn]);

  if (page.protected && !signedIn) {
    return <AutumnBox />;
  }

  return <PatientAppPage page={page} />;
};

const InnerApp = () => {
  const { pages } = usePatientAppPages();
  const { config, brand, tenant } = usePatientAppConfig();
  const { sub } = usePatientAppAuth();
  if (!config?.portalEnabled) {
    return <PortalDisabled message={config?.portalMessage ?? ''} />;
  }

  return (
    <GoogleTagManagerProvider gtmID={'GTM-PZTSDGLP'}>
      <ComponentProvider
        context={{
          renderContext: {
            completingAs: 'customer',
            customerId: sub,
            tenant,
            brand,
          },
        }}
      >
        <PatientAppRootLayout components={config?.portalLayout as Config_Portal_Pages_Component[]}>
          <Routes>
            <Route path={'start/:id'} element={<PatientAppFlow />} />

            {pages.map((page) => (
              <Route path={page.id} element={<PageRouteProtector page={page} />} />
            ))}

            {/* Not Found Route */}
            <Route path={'*'} element={<NotFound />} />
          </Routes>

          <GoogleTagManagerPageView />
        </PatientAppRootLayout>
      </ComponentProvider>
    </GoogleTagManagerProvider>
  );
};

export default function () {
  return (
    <PatientAppProviders>
      <PathRouter>
        <InnerApp />
      </PathRouter>
    </PatientAppProviders>
  );
}
