import { decryptData, encryptData } from './browserEncryption';

export const setStorage = <T>(key: string, object: T) => {
  window.localStorage.setItem(key, JSON.stringify(object));
};

export const getStorage = <T>(key: string): T | null => {
  const object = window.localStorage.getItem(key);

  if (!object) return null;

  return JSON.parse(object) as T;
};

export const deleteStorageKey = (key: string) => {
  window.localStorage.removeItem(key);
};

export const setSecureStorage = async <T>(
  key: string,
  object: T,
  encryptionKey: string,
): Promise<void> => {
  const encryptedData = await encryptData(JSON.stringify(object), encryptionKey);

  window.localStorage.setItem(key, encryptedData);
};

export const getSecureStorage = async <T>(
  key: string,
  encryptionKey: string,
): Promise<T | null> => {
  const object = window.localStorage.getItem(key);

  if (!object) return null;

  const decryptedData = await decryptData(object, encryptionKey);

  if (!decryptedData) return null;

  return JSON.parse(decryptedData) as T;
};
