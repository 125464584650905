import { enLocalKeys } from '../../../../features/Localisations/locales/en';
import { AutumnFormProps, AutumnFormSection } from './types';
import { AutumnVStack } from '../AutumnVStack';
import { AutumnTitle } from '../AutumnTitle';
import { AutumnHStack } from '../AutumnHStack';
import React from 'react';
import { AutumnFormSectionElementInput } from './AutumnFormSectionElementInput';

export function AutumnFormSectionElement<T>({
  sectionName,
  sectionConfig,
  sectionWidth,
  elements,
  allSections,
}: {
  sectionName: keyof typeof enLocalKeys | undefined;
  sectionConfig: AutumnFormSection;
  sectionWidth: number;
  allSections: AutumnFormSection[];
} & Pick<AutumnFormProps<T>, 'columns' | 'elements' | 'sections'>) {
  const formElements = elements?.filter((elem) => elem.section === sectionName);

  const columns = sectionConfig.columns ?? 1;
  const elementWidth =
    sectionWidth / columns - //how many columns the section
    (8 * columns) / columns;

  const childSections = allSections
    ?.filter((s) => s.parentSection && s.parentSection === sectionName)
    .map((sectionConfig) => {
      return (
        <AutumnVStack key={sectionConfig.name} width={sectionWidth}>
          <AutumnFormSectionElement<T>
            sectionName={sectionConfig.name}
            sectionConfig={sectionConfig}
            columns={sectionConfig.columns ?? 1}
            sectionWidth={sectionWidth}
            elements={elements}
            allSections={allSections}
          />
        </AutumnVStack>
      );
    });

  return (
    <AutumnVStack space={'m'}>
      {sectionName && !sectionConfig.hideTitle && (
        <AutumnTitle
          icon={sectionConfig.icon}
          localeKey={sectionName}
          subheaderKey={sectionConfig.explainer}
          variant={'subheader'}
        />
      )}

      <AutumnHStack space={'s'} flexWrap={'wrap'}>
        {formElements.map((el, index) => {
          let width = elementWidth;
          if (el.size === 'half') {
            width = width * 0.5 - 8 / 2;
          } else if (el.size === 'third') {
            width = width * 0.33 - 8 / 3;
          } else if (el.size === 'quarter') {
            width = width * 0.25 - 8 / 4;
          }

          return (
            <AutumnVStack key={el + index.toString()} width={width}>
              <AutumnFormSectionElementInput elem={el} />
            </AutumnVStack>
          );
        })}
      </AutumnHStack>

      {childSections}
    </AutumnVStack>
  );
}
