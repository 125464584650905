export const frLocalKeys = {
  Suppliers_Description: 'Configurer les fournisseurs auprès desquels vous pouvez commander',
  DefaultLocation: 'Emplacement par défaut',
  Id: 'Id',
  SearchBar: 'Rechercher ou demander à Autumn AI',
  Admin: 'Admin',
  Marketing: 'Marketing',
  Schedule: 'Horaire',
  Stock: 'Stock',
  Settings: 'Paramètres',
  Reporting: 'Rapports',
  Close: 'Fermer',
  Signin: 'Connexion',
  Timesheet: 'Feuille de temps',
  DOB: 'Date de naissance',
  Products: 'Produits',
  Opportunities: 'Opportunités',
  AVV: 'VVA',
  Mobile: 'Mobile',
  TeamMember: "Membre de l'équipe",
  Logout: 'Déconnexion',
  Date: 'Date',
  Billing: 'Facturation',
  Notes: 'Notes',
  EnterYourPin: 'Entrez votre code PIN',
  SetAPIN: 'Définir un code PIN',
  Photos: 'Photos',
  Planning: 'Planification',
  StartTime: 'Heure de début',
  EndTime: 'Heure de fin',
  Break: 'Pause',
  Total: 'Total',
  Title: 'Titre',
  Save: 'Enregistrer',
  Subtitle: 'Sous-titre',
  NewPatient: 'Nouveau patient',
  NewPatientCreate: 'Créer un nouveau patient',
  Day: 'Jour',
  Say: 'Dire',
  RingForSeconds: 'Sonner pendant (secondes)',
  Error_Occurred: "Une erreur s'est produite. Veuillez réessayer",
  Appointments: 'Rendez-vous',
  Results: 'Résultats',
  eCommerce: 'eCommerce',
  Inbox: 'Boîte de réception',
  ToDos: 'Tâches',
  Automation: 'Automatisation',
  Today: "Aujourd'hui",
  Week: 'Semaine',
  WelcomeTitle: 'Bienvenue chez Autumn',
  GivenName: 'Prénom',
  Tenants: 'Locataires',
  Tags: 'Étiquettes',
  Suppliers: 'Fournisseurs',
  ContactName: 'Nom du contact',
  ContactEmail: 'Email du contact',
  ContactPhone: 'Téléphone du contact',
  ContactWebsite: 'Site web du contact',
  ShowInactive: 'Afficher les inactifs',
  PermissionUpdated: 'Permission mise à jour',
  Create: 'Créer',
  Update: 'Mettre à jour',
  Back: 'Retour',
  Name: 'Nom',
  DisplayName: "Nom d'affichage",
  Surname: 'Nom de famille',
  Send: 'Envoyer',
  Email: 'Email',
  Business: 'Entreprise',
  Users: 'Utilisateurs',
  Invite: 'Inviter',
  InvitedUser: 'Utilisateurs invités',
  Created: 'Créé',
  Updated: 'Mise à jour',
  Expires: 'Expire',
  Actions: 'Actions',
  Cancel: 'Annuler',
  CancelInvite: "Annuler l'invitation",
  Edit: 'Modifier',
  EditUser: "Modifier l'utilisateur",
  Error: 'Erreur',
  start: 'Commencer',
  BreakDuration: 'Durée de la pause',
  StartDate: 'Date de début',
  EndDate: 'Date de fin',
  Approve: 'Approuver',
  UnApprove: 'Désapprouver',
  LoadMore: 'Charger plus',
  Description: 'Description',
  Active: 'Actif',
  Success: 'Succès',

  InviteCancelText: 'Invitation annulée',
  TenantCreated: 'Locataire créé avec succès',
  TenantUserInvitedText: 'Utilisateur locataire invité avec succès',
  DeleteUserInviteText: "Êtes-vous sûr de vouloir annuler l'invitation?",
  TimesheetEntryAdded: 'Entrée de feuille de temps ajoutée',
  ApproveTimesheetText: "Êtes-vous sûr de vouloir approuver l'entrée de temps?",
  UnApproveTimesheetText: "Êtes-vous sûr de vouloir désapprouver l'entrée de temps?",
};
