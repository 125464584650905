import {
  Config_Portal_Pages_Component,
  Config_Portal_Pages_Component_ImageProps,
} from '../../../WebApp/__generatedtypes__/graphql';
import { AutumnImage } from '../../../WebApp/Common/components/ui';
import React from 'react';

export function ComponentImage({ component }: { component: Config_Portal_Pages_Component }) {
  const props = component.props as Config_Portal_Pages_Component_ImageProps;

  const propsSPread = {
    ...(props.maxWidth && {
      maxWidth: props.maxWidth,
    }),
    ...(props.maxHeight && {
      maxHeight: props.maxHeight,
    }),
    ...(props.width && {
      width: props.width,
    }),
    ...(props.height && {
      height: props.height,
    }),
  };

  return <AutumnImage contentFit={'contain'} source={props.source} {...propsSPread} />;
}
