import { AutumnHStack } from './AutumnHStack';
import React from 'react';
import { enLocalKeys } from '../../../features/Localisations/locales/en';
import { AutumnLocaleText } from './AutumnText';
import { AutumnPressable } from './AutumnPressable';
import { AutumnBox } from './AutumnBox';
import { AutumnIcon } from './AutumnIcon';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { TranslationProps } from '../../../features/Localisations/hooks/useTranslation';
import { TextProps } from '@shopify/restyle';
import { Theme } from './theme';
import { faDash } from '@fortawesome/pro-solid-svg-icons/faDash';

export type AutumnCheckboxProps = {
  isChecked: boolean | 'partially';
  onChange: (checked: boolean) => void;
  disabled?: boolean;
  explainer?: keyof typeof enLocalKeys | TranslationProps;
  localeKey?: keyof typeof enLocalKeys | TranslationProps;
  height?: number;
  width?: number;
};

export function AutumnCheckbox({
  isChecked,
  disabled = false,
  onChange,
  explainer,
  height = 30,
  width = 30,
  localeKey,
  ...rest
}: AutumnCheckboxProps & TextProps<Theme>) {
  return (
    <AutumnPressable disabled={disabled} onPress={() => onChange(!isChecked)}>
      <AutumnHStack alignItems={'center'} space={2}>
        <AutumnBox
          width={width}
          borderRadius={5}
          height={height}
          bg={isChecked ? 'forestGreen' : 'white'}
          opacity={disabled ? 0.4 : 1}
          borderWidth={1}
          alignItems={'center'}
          justifyContent={'center'}
          borderColor={isChecked ? 'forestGreen' : 'coolGray.300'}
        >
          {isChecked && (
            <AutumnIcon
              color={'white'}
              icon={isChecked === 'partially' ? faDash : faCheck}
              size={height - 6}
            />
          )}
        </AutumnBox>

        {localeKey && <AutumnLocaleText localeKey={localeKey} {...rest} />}

        {explainer && <AutumnLocaleText localeKey={explainer} opacity={0.5} />}
      </AutumnHStack>
    </AutumnPressable>
  );
}
