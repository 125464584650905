import { ComponentContext, ComponentContextType } from '../context/ComponentContext';
import React from 'react';

export function ComponentProvider({
  children,
  context,
}: {
  children: React.ReactNode;
  context: ComponentContextType;
}) {
  return <ComponentContext.Provider value={context}>{children}</ComponentContext.Provider>;
}
