import { useAutumnQuery } from '../../../WebApp/features/GraphQL/hooks/useAutumnQuery';
import { graphql } from '../../../WebApp/__generatedtypes__';
import { useState } from 'react';
import { PaymentGatewayState } from '../context/context';
import { brandFromCustomerId } from '../../../WebApp/Common/utils/brand';

export const usePaymentGateway = (customerId: string) => {
  const brand = brandFromCustomerId(customerId);

  const { data, loading } = useAutumnQuery(
    graphql(`
      query paymentGateways($tenant: String!, $customerId: CustomerID!) {
        paymentGateways(tenant: $tenant) {
          gatewayPublicKey
          type
          scheme
          active
          brand
          id
        }

        paymentCards(customer: $customerId) {
          id
          gateway
          expYear
          expMonth
          type
          scheme
        }
      }
    `),
    {
      variables: {
        tenant: 'org_Sj788lY92NO2MgHh',
        customerId,
      },
    },
  );

  const availableGateways = data?.paymentGateways?.filter((gateway) => {
    if (!gateway?.active) return false;

    return gateway?.brand === brand;
  });

  const cardGateway = availableGateways?.find((gateway) => gateway?.scheme === 'Cards');

  const storedCards = data?.paymentCards?.filter(
    (card) => card?.scheme === 'Cards' && card?.gateway === cardGateway?.id,
  );

  const [state, setState] = useState<PaymentGatewayState>({
    selectedPaymentOption: 'new',
  });

  return { state, setState, cardGateway, storedCards };
};
