import { AutumnVStack } from '../../../Common/components/ui/AutumnVStack';
import { AutumnTitle } from '../../../Common/components/ui/AutumnTitle';
import React from 'react';

export function Onboarding() {
  return (
    <AutumnVStack>
      <AutumnTitle localeKey={'WelcomeTitle'} />
    </AutumnVStack>
  );
}
