import { graphql } from '../../../../__generatedtypes__';
import { TaxRate } from '../../../../__generatedtypes__/graphql';
import { uuid } from '../../../../Common/utils/uuid';
import { TAXRATES_QUERY } from './useTaxRates';
import { useAutumnMutation } from '../../../GraphQL/hooks/useAutumnMutation';

export const useTaxRatesMutation = () => {
  const [doUpsertTaxRate, { loading: updateTaxRateLoading }] = useAutumnMutation(
    graphql(`
      mutation UpsertTaxRate(
        $upsertTaxRateId: String!
        $name: String!
        $active: Boolean!
        $taxPercentage: Float!
        $currency: String!
      ) {
        upsertTaxRate(
          id: $upsertTaxRateId
          name: $name
          active: $active
          taxPercentage: $taxPercentage
          currency: $currency
        ) {
          id
          name
          active
          taxPercentage
          currencyCode
        }
      }
    `),
  );

  const upsertTaxRate = async (id?: string, taxRate?: TaxRate) => {
    const { data, errors } = await doUpsertTaxRate({
      variables: {
        upsertTaxRateId: id ?? uuid(),
        name: taxRate?.name as string,
        active: taxRate?.active as boolean,
        taxPercentage: taxRate?.taxPercentage ?? 0,
        currency: taxRate?.currencyCode as string,
      },
      refetchQueries: [TAXRATES_QUERY],
    });

    if (!data?.upsertTaxRate?.id && errors?.length) {
      return false;
    } else {
      return true;
    }
  };

  return {
    updateTaxRateLoading,
    upsertTaxRate,
  };
};
