import React, { useEffect, useRef } from 'react';

export const AutumnTurnstile = ({ callback }: { callback: (value: string) => void }) => {
  function generateRandomSixDigitNumber() {
    return Math.floor(100000 + Math.random() * 900000);
  }

  const elementName = useRef(`turnstile${generateRandomSixDigitNumber()}`);

  useEffect(() => {
    // @ts-expect-error CF Code
    turnstile.render(`#${elementName.current}`, {
      sitekey: '0x4AAAAAAAx9wABFaRp_nclz',
      theme: 'light',
      callback: (token: string) => {
        callback(token);
      },
    });
  }, []);

  return <div id={elementName.current} />;
};
