import { AutumnText } from './AutumnText';
import React from 'react';
import { useConfig } from '../../../features/Config/hooks/useConfig';
import { AutumnHStack } from './AutumnHStack';
import { AutumnImage } from './AutumnImage';
import { TextProps } from '@shopify/restyle';
import { Theme } from './theme';
import { ImageProps } from 'expo-image';
import { AutumnBox } from './AutumnBox';

export function AutumnUserIcon({
  userId,
  iconSize = 20,
  includeName,
  ...rest
}: {
  userId: string;
  iconSize?: number;
  includeName?: boolean;
} & TextProps<Theme> &
  ImageProps) {
  const { users } = useConfig();

  const thisUser = users.find((user) => user.id === userId);

  if (!thisUser) {
    return null;
  }

  const hasImage = thisUser.image && thisUser.image.length > 5;

  const initials = thisUser.given.slice(0, 1) + thisUser.surname.slice(0, 1);
  const initialsColour = 'white';
  const bgColour = 'green';

  return (
    <AutumnHStack space={iconSize > 24 ? 2 : 1} alignItems={'center'}>
      {hasImage ? (
        <AutumnImage
          width={iconSize}
          height={iconSize}
          borderRadius={iconSize}
          {...rest}
          source={thisUser.image}
        />
      ) : (
        <AutumnBox
          width={iconSize}
          height={iconSize}
          borderRadius={iconSize}
          backgroundColor={bgColour}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <AutumnText fontSize={iconSize * 0.5} fontWeight={'bold'} color={initialsColour}>
            {initials}
          </AutumnText>
        </AutumnBox>
      )}
      {includeName && <AutumnText {...rest}>{thisUser.name}</AutumnText>}
    </AutumnHStack>
  );
}
