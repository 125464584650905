import { graphql } from '../../../__generatedtypes__';
import { useAutumnQuery } from '../../GraphQL/hooks/useAutumnQuery';
//import { usePortalPermissions } from '../../Common/hooks/usePortalPermissions';

//import { pusherSubscribeObject } from '../../Common/utils/pusher';
//import { titleForMessageThread } from '../../features/Messages/utils/messageUtils';

//import { getLocalNotificationsPermission, showLocalNotification} from '../../Common/utils/localNotifications';

export const unreadMessageCountQuery = graphql(`
  query unreadMessagesCount {
    messagesCountUnopened
  }
`);

export const useInboxMessagesCount = () => {
  const { data } = useAutumnQuery(unreadMessageCountQuery, {
    skip: false,
  });

  /*

  const { navigate } = useRouting();

  const [getThreadQuery] = useLazyQuery(
    graphql(`
      query thread($ID: ID!) {
          messagesThread(id: $ID) {
          id
          __typename
          unreadInternal
          unreadCustomer
          customer {
            id
            given
            mobile
            surname
            brand
            name
            __typename
          }
          lastMessage {
            __typename
            id
            sent
            html
            title
            files
            senderID
            message
            final
            photo
            type
            options
          }
        }
      }
    `) as DocumentNode,
  );

    const newMessage = async (threadId: string) => {
      const { data } = await getThreadQuery({ variables: { ID: threadId } });

      const messageThread = data?.getMessages as ConsultMessages;

         const title = titleForMessageThread(messageThread);
         const subtitle = messageThread?.lastMessage?.message ?? '';

        showLocalNotification(title, subtitle, () => {
           navigate(`/inbox/${threadId}`);
         });
  };

  useEffect(() => {
    getLocalNotificationsPermission();

    const subscribedBrands: (() => void)[] = [];

    if (hasMessagingPermissions && brands?.length > 0) {
      brands.forEach((brand) => {
        const { unsubscribe } = pusherSubscribeObject(`inbox-${brand}`, 'thread', (data) => {
          refetch().catch(null);

          if (data?.threadId) {
            newMessage(data?.threadId).catch(null);
          }
        });

        subscribedBrands.push(unsubscribe);
      });
    }

    return () => {
      if (subscribedBrands) {
        subscribedBrands.forEach((unsubscribe) => unsubscribe());
      }
    };
  }, [brands, hasMessagingPermissions]);

*/

  return {
    count: data?.messagesCountUnopened ?? 0,
  };
};
