import { FlowContextStateType } from '../types/types';
import React, { useEffect } from 'react';
import {
  Config_Portal_Pages_Component_DateInputProps,
  Config_Portal_Pages_Component_PhoneProps,
} from '../../../WebApp/__generatedtypes__/graphql';
import { useAutumnMutation } from '../../../WebApp/features/GraphQL/hooks/useAutumnMutation';
import { graphql } from '../../../WebApp/__generatedtypes__';

export const useFlowCustomerCheck = (
  state: FlowContextStateType | undefined,
  setState: React.Dispatch<React.SetStateAction<FlowContextStateType>>,
  triggerRecaptcha: () => Promise<string | null>,
) => {
  const matchingFields =
    state?.flow.steps[state?.currentStep].components.filter((component) => {
      if (component.type === 'FormField_Phone') {
        const props = component.props as Config_Portal_Pages_Component_PhoneProps;

        return props.id === 'patient.mobile';
      }

      if (component.type === 'FormField_Date') {
        const props = component.props as Config_Portal_Pages_Component_DateInputProps;

        return props.id === 'patient.dob';
      }

      return false;
    }) ?? [];

  const currentStepsHasFields = matchingFields.length >= 2;

  const [mutation] = useAutumnMutation(
    graphql(`
      mutation customerCheck($args: Flow_Customer_Check_Args!) {
        customersFlowCustomerCheck(args: $args) {
          existing
          session
        }
      }
    `),
  );

  const checkCustomer = async () => {
    if (!state) return;

    const captcha = await triggerRecaptcha();

    if (!captcha) return;

    const { data } = await mutation({
      variables: {
        args: {
          brand: state.brand,
          tenant: state.tenant,
          dob: `${state.formFields['patient.dob'] as string} Z`,
          mobile: state.formFields['patient.mobile'] as string,
          captcha: captcha,
        },
      },
    });

    if (data?.customersFlowCustomerCheck.existing) {
      setState((existingState: FlowContextStateType) => {
        return {
          ...existingState,
          login: {
            session: data?.customersFlowCustomerCheck?.session,
            dob: state.formFields['patient.dob'] as string,
            mobile: state.formFields['patient.mobile'] as string,
          },
        } as FlowContextStateType;
      });

      return;
    }
  };

  useEffect(() => {
    if (
      currentStepsHasFields &&
      state?.formFields['patient.mobile'] &&
      state?.formFields['patient.dob'] &&
      !state?.customerId &&
      !state.login?.session
    ) {
      checkCustomer().catch(null);
    }
  }, [
    currentStepsHasFields,
    state?.formFields['patient.mobile'],
    state?.formFields['patient.dob'],
  ]);
};
