import React, { useEffect, useRef } from 'react';
import { AutumnVStack } from './AutumnVStack';
import { AutumnHStack } from './AutumnHStack';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { Calendar, CalendarTheme, toDateId } from '@marceloterreiro/flash-calendar';

import theme from './theme';
import { AutumnPressable } from './AutumnPressable';
import { AutumnPopover } from './AutumnPopover';
import { faCalendarAlt } from '@fortawesome/pro-solid-svg-icons/faCalendarAlt';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight';
import { AutumnButton } from './AutumnButton';
import { AutumnScrollView } from './AutumnScrollView';
import { AutumnFieldWrapper, AutumnFieldWrapperProps } from './AutumnFieldWrapper';
import { AutumnNumberInputElement } from './AutumnMoneyInput';

import {
  NativeSyntheticEvent,
  TextInputFocusEventData,
  TextInputKeyPressEventData,
} from 'react-native';
import { AutumnText } from './AutumnText';

dayjs.extend(customParseFormat);

const getDateValue = (value: string) => {
  if (dayjs(value).isValid()) {
    return dayjs(value).toDate();
  }
  return dayjs().toDate();
};

export type AutumnDatePickerProps = {
  value: string;
  formatValue?: string;
  formatDisplay?: string;
  endValue?: string;
  disabled?: boolean;
  closeOnSelection?: boolean;
  startWithYear?: boolean;
  enableKeyboardEntry?: boolean;
  onChange: (value: string) => void;
};

export function AutumnDatePicker({
  value,
  endValue,
  formatValue = 'YYYY-MM-DD',
  formatDisplay = 'DD MMM YYYY',
  onChange,
  closeOnSelection = true,
  enableKeyboardEntry,
  startWithYear = false,
  disabled = false,
  ...rest
}: AutumnDatePickerProps & AutumnFieldWrapperProps) {
  const [show, setShow] = React.useState(false);
  const [showMonthPicker, setShowMonthPicker] = React.useState(false);
  const [showYearPicker, setShowYearPicker] = React.useState(startWithYear);
  const [calendarDate, setCalendarDate] = React.useState<Date>(getDateValue(value));

  const onChangeDate = (dateValue: string) => {
    const newDate = dayjs(dateValue, formatValue);
    onChange(newDate.format(formatValue));

    if (closeOnSelection) {
      setShow(false);
    }
  };

  const changeMonth = (changeBy: number) => {
    setCalendarDate(dayjs(calendarDate).add(changeBy, 'month').toDate());
    setShowMonthPicker(false);
  };

  const changeYear = (changeBy: number) => {
    setCalendarDate(dayjs(calendarDate).add(changeBy, 'year').toDate());
    setShowYearPicker(false);
    setShowMonthPicker(startWithYear);
  };

  const setToToday = () => {
    onChange(dayjs().format(formatValue));
    if (closeOnSelection) {
      setShow(false);
    }
  };

  const getDateValueStr = (value: string, format: string = formatDisplay) => {
    if (value) {
      return dayjs(value).format(format);
    }
    return 'Not set';
  };

  const onPress = () => {
    if (!disabled) {
      setShow(true);
    }
  };

  return (
    <AutumnPopover
      popoverWidth={400}
      show={show}
      onOutsidePress={() => setShow(false)}
      popoverComponent={
        <AutumnVStack padding={'s'} width={400}>
          <AutumnHStack justifyContent={'space-between'} alignItems={'center'}>
            <AutumnButton
              variant={'link'}
              leftIcon={faChevronLeft}
              onPress={() => setCalendarDate(dayjs(calendarDate).add(-1, 'month').toDate())}
            />
            <AutumnHStack>
              <AutumnButton
                variant={'link'}
                localeKey={{ key: getDateValueStr(calendarDate.toString(), 'MMMM') }}
                onPress={() => setShowMonthPicker(!showMonthPicker)}
              />
              <AutumnButton
                variant={'link'}
                localeKey={{ key: getDateValueStr(calendarDate.toString(), 'YYYY') }}
                onPress={() => setShowYearPicker(!showYearPicker)}
              />
            </AutumnHStack>
            <AutumnButton
              variant={'link'}
              leftIcon={faChevronRight}
              onPress={() => setCalendarDate(dayjs(calendarDate).add(1, 'month').toDate())}
            />
          </AutumnHStack>
          {/*getMonth() is zero index, hence the weird +1*/}
          {showMonthPicker && (
            <MonthPicker currentMonth={calendarDate.getMonth() + 1} changeMonth={changeMonth} />
          )}
          {showYearPicker && (
            <YearPicker currentYear={calendarDate.getFullYear()} changeYear={changeYear} />
          )}
          {!showYearPicker && !showMonthPicker && (
            <Calendar
              onCalendarDayPress={(e) => onChangeDate(e)}
              calendarMonthId={toDateId(calendarDate)}
              calendarActiveDateRanges={[
                {
                  startId: toDateId(getDateValue(value)),
                  endId: toDateId(getDateValue(endValue ?? value)),
                },
              ]}
              theme={calendarTheme}
            />
          )}
          {(!value || value.length === 0) && (
            <AutumnButton localeKey={'SetToToday'} variant={'link'} onPress={setToToday} />
          )}
        </AutumnVStack>
      }
    >
      <AutumnPressable disabled={show || disabled} onPress={onPress}>
        <AutumnFieldWrapper hasFocus={show} {...rest}>
          <AutumnHStack alignItems={'center'} height={30}>
            <AutumnButton
              padding={0}
              disabled={disabled}
              onPress={onPress}
              leftIcon={faCalendarAlt}
              variant={'inLineLink'}
              localeKey={{
                key: enableKeyboardEntry
                  ? ''
                  : endValue
                  ? 'DatePickerRange'
                  : 'DatePickerSingleDate',
                renderParameters: {
                  startDate: getDateValueStr(value),
                  endDate: getDateValueStr(endValue ?? value),
                },
              }}
            />
            {enableKeyboardEntry && (
              <DateEntryField
                year={dayjs(value).year()}
                month={dayjs(value).month() + 1}
                day={dayjs(value).date()}
                onChange={(date) => onChange(date)}
              />
            )}
          </AutumnHStack>
        </AutumnFieldWrapper>
      </AutumnPressable>
    </AutumnPopover>
  );
}

function DateEntryField({
  onChange,
  year,
  day,
  month,
}: {
  year: number;
  month: number;
  day: number;
  onChange: (newValue: string) => void;
}) {
  // const dateValue = dayjs(`${year}-${month}-${day}`);

  const [yearTemp, setYearTemp] = React.useState<number>(year);
  const [monthTemp, setMonthTemp] = React.useState<number>(month);
  const [dayTemp, setDayTemp] = React.useState<number>(day);

  const dayInputRef = useRef<HTMLInputElement>();
  const monthInputRef = useRef<HTMLInputElement>();
  const yearInputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    const dateParsed = dayjs()
      .set('year', yearTemp)
      .set('month', monthTemp - 1)
      .set('date', dayTemp);

    if (yearTemp > 1900 && monthTemp >= 1 && dayTemp >= 1 && dateParsed.isValid()) {
      onChange(dateParsed.utc().format('YYYY-MM-DD'));
    }
  }, [dayTemp, monthTemp, yearTemp]);

  const yearChange = (newYear: number) => {
    setYearTemp(newYear);
  };

  const yearKeypress = (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
    // if (e.nativeEvent.key == 'Enter' || e.nativeEvent.key == 'Tab') {
    //   yearInputRef?.current?.blur();
    //   e.stopPropagation();
    //   e.preventDefault();
    // }
    // if (e.nativeEvent.key == 'ArrowUp') {
    //   const newDateValue = dateValue.add(1, 'year');
    //   onChange(newDateValue.format('YYYY-MM-DD'));
    //   setYearTemp(newDateValue.year());
    //   e.stopPropagation();
    //   e.preventDefault();
    // }
    // if (e.nativeEvent.key == 'ArrowDown') {
    //   const newDateValue = dateValue.add(-1, 'year');
    //   onChange(newDateValue.format('YYYY-MM-DD'));
    //   setYearTemp(newDateValue.year());
    //   e.stopPropagation();
    //   e.preventDefault();
    // }
  };

  const monthKeypress = (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
    // if (e.nativeEvent.key == 'Enter' || e.nativeEvent.key == 'Tab') {
    //   yearInputRef?.current?.focus();
    //   e.stopPropagation();
    //   e.preventDefault();
    // }
    // if (e.nativeEvent.key == 'ArrowUp') {
    //   const newDateValue = dateValue.add(1, 'month');
    //   onChange(newDateValue.format('YYYY-MM-DD'));
    //   setMonthTemp(newDateValue.month() + 1);
    //   e.stopPropagation();
    //   e.preventDefault();
    // }
    // if (e.nativeEvent.key == 'ArrowDown') {
    //   const newDateValue = dateValue.add(-1, 'month');
    //   onChange(newDateValue.format('YYYY-MM-DD'));
    //   setMonthTemp(newDateValue.month() + 1);
    //   e.stopPropagation();
    //   e.preventDefault();
    // }
  };

  const yearBlurred = (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
    const inputValue = e.nativeEvent.text;
    setYearTemp(Number(inputValue));
  };

  const monthChange = (newMonth: number) => {
    setMonthTemp(newMonth);
    if (newMonth > 1) {
      yearInputRef?.current?.focus();
    }
  };

  const monthBlurred = (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
    const inputValue = e.nativeEvent.text;
    setMonthTemp(Number(inputValue));
  };

  const dayChange = (newDay: number) => {
    setDayTemp(newDay);
    if (newDay > 3) {
      monthInputRef?.current?.focus();
    }
  };

  const dayBlurred = (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
    const inputValue = e.nativeEvent.text;
    setDayTemp(Number(inputValue));
  };

  const dayKeypress = (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
    // if (e.nativeEvent.key == 'Enter' || e.nativeEvent.key == 'Tab') {
    //   monthInputRef?.current?.focus();
    //   e.stopPropagation();
    //   e.preventDefault();
    // }
    // if (e.nativeEvent.key == 'ArrowUp') {
    //   const newDateValue = dateValue.add(1, 'day');
    //   onChange(newDateValue.format('YYYY-MM-DD'));
    //   setDayTemp(newDateValue.date());
    //   e.stopPropagation();
    //   e.preventDefault();
    // }
    // if (e.nativeEvent.key == 'ArrowDown') {
    //   const newDateValue = dateValue.add(-1, 'day');
    //   onChange(newDateValue.format('YYYY-MM-DD'));
    //   setDayTemp(newDateValue.date());
    //   e.stopPropagation();
    //   e.preventDefault();
    // }
  };

  return (
    <AutumnPressable>
      <AutumnHStack alignItems={'center'}>
        <AutumnNumberInputElement
          value={dayTemp}
          ref={dayInputRef}
          onKeyPress={dayKeypress}
          maxValue={31}
          minValue={1}
          precision={0}
          prefix={day && day < 10 ? '0' : ''}
          fontSize={13}
          fontWeight={'bold'}
          color={'buttonPrimary'}
          width={30}
          textAlign={'center'}
          placeholder={'DD'}
          placeholderTextColor={'gray'}
          selectTextOnFocus={true}
          style={{
            backgroundColor:
              document.activeElement === dayInputRef.current ? 'rgb(240,240,245)' : undefined,
            paddingVertical: 5,
          }}
          onBlur={(e) => dayBlurred(e)}
          onChangeValue={dayChange}
        />
        <AutumnText>/</AutumnText>
        <AutumnNumberInputElement
          value={monthTemp}
          ref={monthInputRef}
          onKeyPress={monthKeypress}
          maxValue={12}
          minValue={1}
          placeholder={'MM'}
          precision={0}
          prefix={month && month < 10 ? '0' : ''}
          fontSize={13}
          placeholderTextColor={'gray'}
          fontWeight={'bold'}
          color={'buttonPrimary'}
          width={30}
          textAlign={'center'}
          selectTextOnFocus={true}
          style={{
            backgroundColor:
              document.activeElement === monthInputRef.current ? 'rgb(240,240,245)' : undefined,
            paddingVertical: 5,
          }}
          onBlur={monthBlurred}
          onChangeValue={monthChange}
        />
        <AutumnText>/</AutumnText>
        <AutumnNumberInputElement
          value={yearTemp}
          ref={yearInputRef}
          onKeyPress={yearKeypress}
          maxValue={2100}
          minValue={1}
          precision={0}
          placeholder={'YYYY'}
          delimiter={''}
          prefix={''}
          fontSize={13}
          placeholderTextColor={'gray'}
          fontWeight={'bold'}
          color={'buttonPrimary'}
          width={50}
          textAlign={'center'}
          selectTextOnFocus={true}
          style={{
            backgroundColor:
              document.activeElement === yearInputRef.current ? 'rgb(240,240,245)' : undefined,
            paddingVertical: 5,
          }}
          onBlur={yearBlurred}
          onChangeValue={yearChange}
        />
      </AutumnHStack>
    </AutumnPressable>
  );
}

function MonthPicker({
  currentMonth,
  changeMonth,
}: {
  currentMonth: number;
  changeMonth: (changeMonthOffset: number) => void;
}) {
  const pressedMonth = (month: number) => {
    changeMonth(month - currentMonth);
  };

  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  return (
    <AutumnVStack>
      <AutumnHStack space={'m'} flexWrap={'wrap'} justifyContent={'center'}>
        {months.map((month) => (
          <AutumnButton
            key={month}
            width={100}
            variant={month === currentMonth ? 'primary' : 'link'}
            onPress={() => pressedMonth(month)}
            localeKey={{ key: dayjs(`2000-${month}-1`).format('MMMM') }}
          />
        ))}
      </AutumnHStack>
    </AutumnVStack>
  );
}

function YearPicker({
  currentYear,
  changeYear,
}: {
  currentYear: number;
  changeYear: (changeYearOffset: number) => void;
}) {
  const pressedYear = (year: number) => {
    changeYear(year - currentYear);
  };

  const years: number[] = [];

  for (let i = 1900; i < 2030; i++) {
    years.push(i);
  }

  return (
    <AutumnVStack height={300}>
      <AutumnScrollView>
        <AutumnHStack space={'m'} flexWrap={'wrap'} justifyContent={'center'}>
          {years.map((year) => (
            <AutumnButton
              key={year}
              width={60}
              variant={year === currentYear ? 'primary' : 'link'}
              onPress={() => pressedYear(year)}
              localeKey={{ key: year.toString() }}
            />
          ))}
        </AutumnHStack>
      </AutumnScrollView>
    </AutumnVStack>
  );
}
const calendarAccent = theme.colors.buttonPrimary;
const calendarTheme: CalendarTheme = {
  rowMonth: {
    container: {
      display: 'none',
    },
  },
  rowWeek: {
    container: {
      borderBottomWidth: 1,
      borderBottomColor: 'rgba(52, 52, 52, 0.4)',
      borderStyle: 'solid',
    },
  },
  itemWeekName: { content: { color: 'rgba(52, 52, 52, 0.8)' } },
  itemDayContainer: {
    activeDayFiller: {
      backgroundColor: calendarAccent,
    },
  },
  itemDay: {
    idle: ({ isPressed, isWeekend }) => ({
      container: {
        backgroundColor: isPressed ? calendarAccent : 'transparent',
        borderRadius: 100,
      },
      content: {
        color: isPressed ? 'white' : isWeekend ? 'rgba(52, 52, 52, 0.8)' : '#000000',
      },
    }),
    today: ({ isPressed }) => ({
      container: {
        borderColor: 'rgba(52, 52, 52, 0.5)',
        borderRadius: isPressed ? 100 : 30,
        backgroundColor: isPressed ? calendarAccent : 'transparent',
      },
      content: {
        color: isPressed ? 'white' : calendarAccent,
      },
    }),
    active: ({ isEndOfRange, isStartOfRange }) => ({
      container: {
        backgroundColor: calendarAccent,
        borderTopLeftRadius: isStartOfRange ? 100 : 0,
        borderBottomLeftRadius: isStartOfRange ? 100 : 0,
        borderTopRightRadius: isEndOfRange ? 100 : 0,
        borderBottomRightRadius: isEndOfRange ? 100 : 0,
      },
      content: {
        color: 'white',
      },
    }),
  },
};
